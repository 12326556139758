import React from 'react';
// import axios from 'axios';
import axios from './../../../helpers/axios';

import CreatableSelect  from 'react-select/creatable'
import ContentLoader from "react-content-loader"
import { NavDropdown, Navbar, Nav, Container } from "react-bootstrap";
import { Link,NavLink} from 'react-router-dom';


class RawMaterialsCrudComponent extends React.Component {
  state = {
    Categories: [],
    CasNumbers: [],
    loading: false,
    open: false,
    userRole:''
  }

  componentDidMount() {
    let userRole = localStorage.getItem('user');
    console.log(userRole)
    // console.log(CasNumbers, 'usamaaaas');

    this.setState({userRole})

    axios.get(`${window.API_URL}Fragrances`)
      .then(res => {
        this.setState({
          Categories: res.data.getCategories,
          CasNumbers: res.data.getCasNumbers,
          loading: true,

        });
    //   console.log(res.data.getCasNumbers,'usamas here')        
    //   console.log(CasNumbers,'umair here')        
      console.log(this.state.CasNumbers[0].ID,'sir umair')        
      })
   
  }

  constructor(props) {
    super(props);
    this.state = {
      Categories: null,
      CasNumbers: null
    };   

    this.handleChange = this.handleChange.bind(this);
  }


    

  css = `
  .navbar-nav .active{
  background:#000!important;
  color:white!important;
  }
  `

  handleChange(e) { 
    e.preventDefault();
    console.log(e.target.value , 'hahahahhah');
        // this.setState({ CasNumbers: e.target.value });
        let val = e.target.value
	

        
    axios.get(`${window.API_URL}getRawData/${val}`)
    .then(res => {
      this.setState({
        
        RawMaterials: res.data.getRawMaterial,
        

      });
    console.log(this.state.RawMaterials,'bilal here')        
  //   console.log(CasNumbers,'umair here')        
    // console.log(this.state.CasNumbers[0].ID,'sir umair')           
    })


  }

  render() {

    const {userRole} = this.state 


    let catdropdown = "";
    if (!this.state.loading) {
      catdropdown = 'lodaing...!';
    } else {
      catdropdown = this.state.Categories.map((item) => {
        // return(<Dropdown.Item eventKey={item.id}>{item.TabName}</Dropdown.Item>);
        return ( 
            <NavDropdown.Item key={item.id}>
              <Link 
                  to={{pathname: `/Categories/${item.TabName.replace(/\s/g, '_')}/${item.id}`}}
                  state={{CatName: `${item.TabName}`, ID: `${item.id}`}}
                  data={{CatName: `${item.TabName}`, ID: `${item.id}`}}
                  ref={this.Head}>
                {item.TabName}
              </Link>
            </NavDropdown.Item>);
        // return (<a className="catLinkDropDown" href={window.Full_url+'/Categories/'+`${item.TabName.replace(/\s/g, '_')}/${item.id}`}>{item.TabName}</a>)
      });
    }

    
    let casdropdown = "";
    // if (!this.state.loading) {
    //     casdropdown = 'lodaing...!';
    // } else {
    //     casdropdown = this.state.CasNumbers.map((item) => {
    //     // return(<Dropdown.Item eventKey={item.id}>{item.TabName}</Dropdown.Item>);
    //     return ( 
    //         <h1>{item.cas}</h1>
    //         );
    //     // return (<a className="catLinkDropDown" href={window.Full_url+'/Categories/'+`${item.TabName.replace(/\s/g, '_')}/${item.id}`}>{item.TabName}</a>)
    //   });
    // }

    
    
    // console.log(this.state.CasNumbers)


    return (
      <section>
        <header>
          <div className="top-header">
            <div className="container">
              <div className="row align-center">
                <style>{this.css}</style>
                <div className="col-md-2 col-sm-3 col-lg-2">
                  <div className="logo-wrap">
                    <Link to='/'>
                      {/* <img src={Logo} alt="logo" className="img-responsive" /> */}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-9">
                  <div className="row header-head">
                    <div className="col-sm-12 col-md-12 col-lg-8 pl-0 text-wrap">
                      <div className="header-info">
                        {/* <h2>fragrance university</h2> */}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7 col-lg-4 head-searchbar">
                      <div className="search-wrap">
                        <form action="">
                          {/* <input type="text" placeholder="Search..." name="search2" /> */}
                        
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="menu-wrap">
                    <div className='for-mobile'>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="casNo" >Categories</label>                               
                                <select  name="categories"  className="form-control">                              
                                    <option value=""  defaultValue="">All</option>
                                    {this.state.Categories && this.state.Categories.map(cat=> 
                                    <option value={cat.id} key={cat.id}>{cat.TabName}</option>                                    
                                    )}                            
                                </select>
                                
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="casNo" >Cas#</label>                               
                                <select  name="casNumbers"  className="form-control" onChange={this.handleChange}>                              
                                    {/* <option value=""  defaultValue="">All</option> */}
                                    {this.state.CasNumbers && this.state.CasNumbers.map(cat=> 
                                    <option value={cat.ID} key={cat.ID}>{cat.cas}</option>                                    
                                    )}                            
                                </select>
                                
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label htmlFor="odorCategory" >Raw Material</label>
                                <select  name="sdgs"  className="form-control">                              
                                    <option value=""  defaultValue="">All</option>
                                    {this.state.CasNumbers && this.state.CasNumbers.map(cat=> 
                                    <option value={cat.ID} key={cat.ID}>{cat.inner_link_heading}</option>                                    
                                    )}                            
                                </select>
                                
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <h1 htmlFor="raw_material" >Raw Material</h1>
                                {/* <p>{ this.state.Categories  }</p> */}
                                {/* {this.state.Categories.map(item => <p key={item.id} name={item.TabName} />)}  */}
                                {/* { this.state.RawMaterials  && this.state.RawMaterials.map(item, index =>  <tr data-index={index}>{item.value} </tr>)} */}

                                {console.log(this.state.RawMaterials, 'usamamamamammamamama')}
                                 {/* <td>{value.map((item, i) => <p key={i}>{item.salesOrderId}</p>)}</td> */}
                                {this.state.RawMaterials && this.state.RawMaterials.map(d => (<li key={d.physical_propID}>{d}</li>))} 
                                
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </section>
    );
  }
}
export default RawMaterialsCrudComponent;
