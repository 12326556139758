import React from 'react';
import "./Fragrance.css";
import {Row, Col, Form, Modal, Button, Tabs,Tab} from "react-bootstrap";
import fragrnaceInfo1mg from '../../../../assets/img/fragrnace-inner-img.png';

const FragranceComposition = () => {
  return (
    <div>
        <section className='fragrance-composition-things'>
            <div className='container'>
                <div className="composition-things-title">
                    <h1>FRAGRANCE COMPOSITION</h1>
                </div>
                <div className='hr-line'></div>
                <Row className='composition-row'>
                    <Col lg={7} md={7} sm={12}>
                        <div className="composition-things-details">
                            <p>
                                Fragrance accords are described in musical terms with top, middle,
                            and bottom notes. The top notes are the initial aromas that the nose 
                            picks up on when evaluating the fragrance. They are usually bright 
                            and crisp and only last for a short while because they are provided 
                            by the smallest, most volatile molecules in the accord. Middle, 
                            or “heart” notes, follow up and make up the body of the fragrance and 
                            last slightly longer than the top notes. Finally, bottom notes are the 
                            base of the fragrance, lasting the longest and usually consisting of 
                            strong musk, wood, and gourmand notes. 
                            </p>
                            <p>
                            These molecules are the largest in the accord, and stick around long after 
                            the top and middle notes have evaporated. The combination of a fragrance’s 
                            top, middle, and bottom notes give the accord complexity and depth that tells 
                            a story the customer can connect with. An apple fragrance is rarely ever 
                            just apple scented; a skilled perfumer can pick up floral, green, and fresh 
                            notes all within the overarching theme of the fragrance. Without these 
                            individual notes, your shampoos, soaps, candles, cleaners, and anything else 
                            with a pleasant odor would smell flat. It can be difficult for beginners to 
                            pick up on every note at first, but with practice and plenty of blotting 
                            papers, you can learn to identify these notes to better pick fragrances for
                            your products!
                            </p>
                        </div>
                    </Col>
                    <Col lg={5} md={5} sm={12}>
                        <div className="composition-rightside">
                            <div className='composition-img'>
                                <img src={fragrnaceInfo1mg} />
                            </div>
                            <div className='composition-btn'>
                                <Button type='button' className='resetbht my-composit-btn'>
                                    <a target="_blank" className="text-decoration-none text-white" href={window.API_URL+'download-pdf/fragrance-composition'}>DOWNLOAD</a>
                                </Button>
                            </div>
                            <div className='composition-txt composition-txt-position'>
                                <h4>“The combination of a<br/> fragrance’s top, middle,<br/> and bottom 
                                    notes give the<br/> acord complexity and depth<br/> that tells a story 
                                    the<br/> customer can connect with.”</h4>
                            </div>
                        </div>
                    </Col>
                        
                </Row>

                <div className='composition-notes'>
                    <div className='top-note'>
                        <p className='note'>
                            <span>Top Notes:</span> Initial Scent Throw - Typically Last 5 - 15 Minutes
                        </p>
                        <p className='example'>
                            <span>Examples: </span> Lemon, Grapefruit, Yuzu, Peppermint, and Eucalyptus
                        </p>
                    </div>
                    <div className='middle-note'>
                        <p className='note'>
                            <span>Middle Notes:</span> These notes make there appearance as the top notes  
                            begin to fade away, typically last 20 - 60 minutes
                        </p>
                        <p className='example'>
                            <span>Examples: </span> Lavender, Sage, Neroli, Rose, Fragonia, Geranium, and Fennel
                        </p>
                    </div>
                    <div className='bottom-note'>
                        <p className='note'>
                            <span>Bottom Notes:</span> As the Middle notes fade away bottom notes begin to be 
                            more dominant, typically last upwards of 6 hours
                        </p>
                        <p className='example'>
                            <span>Examples: </span> Patchouli, Vetiver, Sandalwood, Vanilla, and Amyris
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default FragranceComposition;