import {Button, Col, ProgressBar, Row} from "react-bootstrap";
import profileicon from "../../../../../../assets/img/profile-logo.png";
import profilevideoimg1 from "../../../../../../assets/img/profile-video-01.jpg";
import React, {useEffect, useState} from "react";
import Loader from "react-js-loader";
import {Link} from "react-router-dom";


const ContinueLearning = ({videos,lessonsLeft})=>{

    const [continueLearning,setContinueLearning] = useState('')
    const [show,setShow] = useState(true)
    const [allVideos,setAllVideos] = useState('')


    useEffect(()=>{



        if(videos?.length > 0) {
            let watchedVideos = videos.filter(vid=>{

                if(parseInt(vid?.current_time) !== 0){
                    return vid;
                }
            })
            let tempVideos = [...watchedVideos]
            setAllVideos(watchedVideos)
            setContinueLearning(tempVideos.splice(0, 3))
        }else{
            setContinueLearning(videos)
        }

    },[videos])

    console.log(continueLearning)

    const minsToSec= (input) => {
        var parts = input.split(':'),
            minutes = +parts[0],
            seconds = +parts[1];
        return parseInt((minutes * 60 + seconds));
    }

    const handleShowAll = ()=>{

        let tempVideos = [...allVideos]
        if(show){
            setContinueLearning(tempVideos)
        }else {
            setContinueLearning(tempVideos.splice(0,3))
        }
        setShow(!show)
    }


    return (<div className="profile-course">
        <div className="container">
            <div className="profile-course-heading">
                <h2>Continue Learning</h2>
                <p>Pick up from where you left off</p>
            </div>
            <div className="profile-course-list">



                      <Row className="justify-content-center">

                        {continueLearning && continueLearning.map(video => <Col lg={4} md={4} sm={6} key={video.video_id} className="profile-course-column mb-2">
                            <div className="profile-course-detail">
                                <div className="profile-course-title">
                                    <div className="profile-course-icon">
                                        <img src={profileicon} alt="image"/>

                                    </div>
                                    <div className="profile-course-discription">
                                        <h5>Course: <span>{video.course.course_title}</span></h5>
                                        <p>Lessons Left: <span>{lessonsLeft(video.course.CourseID)}</span></p>
                                    </div>
                                </div>
                                <div className="profile-course-body">
                                    {video.video.embedlink ? (
                                        <img loading="lazy"
                                            src={"https://img.youtube.com/vi/" + video.video.embedlink + "/maxresdefault.jpg"}/>
                                        ) : (
                                            <img loading="lazy" src={window.ASSET_URL + video.video.thumbnail} className="mb-2"/>
                                    )}
                                    <Link to={"/Courses/CoursesDetails/" + video.video.CourseID + '?videoId=' + video.video_id}
                                          className="mt-3"> <Button className="resume-btn"> <span><i
                                        className="fa fa-play"
                                        aria-hidden="true"></i></span>
                                        <span>Resume</span></Button></Link>
                                        <ProgressBar variant="danger" now={video?.current_time} max={minsToSec(video.video.video_length)}  />
                                    {/*<Button className="resume-btn"> <span><i className="fa fa-play" aria-hidden="true"></i></span> <span>Resume</span></Button>*/}
                                </div>
                                <div className="profile-course-foot">
                                    <p>Continue Watching: {video.video.video_title}</p>
                                </div>
                            </div>
                        </Col>)}
                          {!continueLearning && <Loader type="spinner-cub" bgColor={"#c42625"} title={"Loading Progress..."}
                                   color={'#c42625'} size={100}/>}
                        <div className="profile-cours-showall">
                            {Array.isArray(continueLearning) && continueLearning.length === 0  ? (
                                <div className="text-center">
                                    <p>No Progress Available</p>
                                    <Link to="/Courses" className="btn  btn-outline-danger" >Start a Course</Link>
                                </div>
                            ):(
                                Array.isArray(continueLearning) &&   <Button className="showall-btn resetbht" onClick={handleShowAll}>{( show ? 'Show All' :"Show Less")}</Button>
                            )}

                        </div>
                    </Row>



            </div>
        </div>
    </div>)
}
export default ContinueLearning