import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Toast} from "../../../../../Widgets/alert";
import {updateUserProfile} from "../../../../../../redux/actions/user/UserProfileAction";
import Joi from "joi-browser";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import profileusericon from "../../../../../../assets/img/profile-user-icon-01.png";
import EditImageModal from "./EditImageModal";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";

const EditProfileModal = ({user,show,handleClose,changePw,setChangePw,avatar})=>{

    const [checkPw,setCheckPw] = useState('')
    const [correctPw,setCorrectPw] = useState(false)
    const [oldPw,setOldPw] = useState(false)
    const [changePassword, setChangePassword] = useState('')
    const dispatch = useDispatch();
    let [editUser,setEditUser] = useState({})
    let [errors,setErrors] = useState({})
    const [edit,setEdit] = useState(true)
    const [showModal,setShowModal] = useState(false)
    const [userImg,setUserImg] = useState('')
    const [imgCropArea,setImgCropArea] = useState('')
    const [imgCropPixel,setImgCropPixel] = useState('')


    useEffect(()=>{
        setEditUser({...user,newPassword: '', conNewPassword: '',avatar:{}})
        setChangePassword(changePw)
    },[user,changePw])

    const confirmOldPw = (e)=>{
        setCorrectPw(false)
        if(e.target.value) {
            setCheckPw(33)

            axios
                .post(window.API_URL + 'confirm-old-password', {oldPw})
                .then(({data}) => {
                    setCorrectPw(data);
                    (data ? setCheckPw(11) : setCheckPw(22));
                })
        }else {
            setCheckPw('')
        }
    }

    //validations
    const schema = {
        newPassword:Joi.string().required().min(6).label('Password'),
        conNewPassword: Joi.any().valid(Joi.ref('newPassword')).required().options({ language: { any: { allowOnly: 'must match password' } } }).required().label('Confirm Password')
    }

    //image crop
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setImgCropArea(JSON.stringify(croppedArea))
        setImgCropPixel(JSON.stringify(croppedAreaPixels))
    }, [])
    const saveCropedImg =()=>{
        // setAvatar(userImg)
        handleModal()
    }
    const handleImageUpload = (e)=>{
        if (e.target.files[0]) {
            setUserImg(URL.createObjectURL(e.target.files[0]))
            handleModal()
        }
    }

    // image modal
    const handleModal = ()=>{
        setShowModal(!showModal)
    }


    //location name change
    const handlePlaceChange = (place) => {

        let tempUser = {...editUser};
        tempUser.location = place.formatted_address
        setEditUser(tempUser)
    }

    const handleInput = (e)=>{
        let tempUser = {...editUser};
        tempUser[e.target.name] = e.target.value;
        //
        if (e.target.name === 'avatar'){
            if (e.target.files[0]){
                tempUser[e.target.name] =  e.target.files[0]
                handleImageUpload(e)
            }
        }

        if (e.target.name === 'newPassword' || e.target.name === 'conNewPassword'){
            setErrors(validate({newPassword:tempUser.newPassword,conNewPassword:tempUser.conNewPassword}) || {})
        }

        setEditUser(tempUser)
    }

    const resetImg = e=>{
        let tempUser = {...editUser};
        tempUser[e.target.name] = '';
        setEditUser(tempUser)
        document.getElementById("avatar").value = "";
    }


    const validate = (form) => {

        let result = Joi.validate(form, schema, { abortEarly: false })

        if (!result.error) return null;
        const errors = {};
        for (let item of result.error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    }

    const handleFormSubmit = e=>{
        e.preventDefault()
        if (editUser.newPassword){
            if (editUser.newPassword.length < '6'){
                Toast('error','6 character password')
                return false;
            }
            if (editUser.newPassword !== editUser.conNewPassword){
                Toast('error','Password must Match')
                return false;
            }

        }

        const data = new FormData();
        data.append('id',editUser.id)
        data.append('name',editUser.name)
        data.append('newPassword',editUser.newPassword)
        data.append('img',editUser.avatar)
        data.append('location',editUser.location)
        data.append('imgArea',imgCropArea)
        data.append('imgPixel',imgCropPixel)
        data.append('_method','PUT')

        dispatch(updateUserProfile(data,editUser.id)).then(response=>{
            if(response){
                handleClose()
            }
        })

    }



    return (
        <Modal className="edit-profile-modal" show={show} onHide={handleClose}>
            <style>{css}</style>
            <EditImageModal
                handleModal={handleModal}
                showModal={showModal}
                img={userImg}
                onCropComplete={onCropComplete}
                saveCropedImg={saveCropedImg}/>

            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Edit Profile</h3>
                    <p>Only You Can  See This Page</p>
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <div className="edit-profile-picture">
                        <Row className="profile-details">
                            <Col lg={8} md={8} sm={8}>
                                <div className="profile-user">
                                    <div className="profile-icon">
                                        <img src={avatar} className="rounded-circle-front" alt="icon"/>
                                    </div>
                                    <div className="profile-discription">
                                        <h2>{user.name}</h2>
                                        <p><span><i className="fa fa-map-marker" aria-hidden="true"></i></span> <span>{user.location}</span></p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={4}>
                                <div className="edit-profile">
                                    <div className="upload-btn-wrapper" >
                                        <button className="btn-img editprofile-btn resetbht" >
                                            <span>
                                            <i className="fa fa-pencil"
                                                                         aria-hidden="true"></i>
                                        </span><span>Edit Picture</span>
                                        </button>
                                        <input type="file" name="avatar" id="avatar" accept="image/png, image/gif, image/jpeg" onClick={resetImg} onChange={handleInput} className="imageFile"/>
                                    </div>
                                    {/*<Button className="editprofile-btn"  onClick={handleModal}>*/}
                                    {/*    <span><i class="fa fa-pencil" aria-hidden="true"></i></span><span>Edit Picture</span>*/}
                                    {/*</Button>*/}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="edit-profile-form">
                        {/*<Form>*/}
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" onChange={handleInput} name="name" value={editUser.name} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Location</Form.Label>
                            {/*<Autocomplete*/}
                            {/*    apiKey="AIzaSyBoEdZawzeaH-efGmZLkHJbouorYAm5dDg"*/}
                            {/*    onPlaceSelected={(place) => console.log(place)}*/}
                            {/*/>*/}
                            <Autocomplete
                                className="form-control"
                                apiKey="AIzaSyBoEdZawzeaH-efGmZLkHJbouorYAm5dDg"
                                onPlaceSelected={handlePlaceChange}
                                name="location"
                                defaultValue={editUser.location}
                            />
                            {/*<Form.Control type="text" name="location" value={'Georgia, United States'}  />*/}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" value={user.email} disabled={true} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <div className="form-check">
                                <input className="form-check-input" onClick={()=>setChangePw(!changePw)} type="checkbox" id="flexCheckDefault"/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Change Password
                                    </label>
                            </div>
                        </Form.Group>
                        {changePw && <>
                            <Form.Group className="mb-3">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control onBlur={confirmOldPw} onInput={(e)=>setOldPw(e.target.value)} name="oldPw" type="password"/>
                                {checkPw===33 && <small className="text-black-50"><i className="fa fa-spinner"  style={{transform: "rotate(90deg)"}}></i> Checking Password</small>}
                                {checkPw===22 && <small className="text-danger"><i className="fa fa-close"></i>Incorrect</small>}
                                {checkPw===11 && <small className="text-success"><i className="fa fa-check-circle"></i> Verified</small>}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>New Password
                                    {/*<small style={{fontSize: "17px", color: "gray"}}>(Password will*/}
                                    {/*remain same if you leave empty.)</small>*/}
                                </Form.Label>
                                <Form.Control name="newPassword" disabled={!correctPw} value={editUser.newPassword} onChange={handleInput}
                                              type="password"/>
                                {errors['newPassword'] && <small className="text-danger">{errors['newPassword']}</small>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control name="conNewPassword" disabled={!correctPw} value={editUser.conNewPassword}
                                              onChange={handleInput} type="password"/>
                                {errors['conNewPassword'] && <small className="text-danger">{errors['conNewPassword']}</small>}
                            </Form.Group>
                        </>}

                        {/*</Form>*/}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="cancel-btn" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="save-btn" variant="primary"  onClick={handleFormSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    );
}

export default EditProfileModal


const css= `
    .pac-container {
    z-index: 1100 !important;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-img {
  border: 2px solid gray;
  color: white;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
imageFile{
cursor: pointer;
}
    `
