import './Pagination.css';
import React from 'react';

function Pagination({pagination, offset, paginate}) {
   
    const totalPages = () => {
		let pages = [];
		let from = pagination.current_page - Math.floor(offset / 2);
		if (from < 1) {
			from = 1
		}
		let to = from + offset;
		if (to > pagination.last_page) {
			to = pagination.last_page;
		}
		while (from <= to) {
			pages.push(from)
			from++
        }
        return pages;
    }

    const pages = totalPages();

    const changePage=(pagenum)=>{
        pagination.current_page=pagenum;
        paginate(pagenum);
    }
    const isCurrentPage=(page)=>{
        return pagination.current_page === page
    }
    return (
            <nav aria-label="..." className="col-md-12 custom-pagination userlist-pagination">
                <p>
                    <strong>Displaying {pagination.from} - {pagination.to} of {pagination.total} Records</strong>
                </p>
                {
                    pagination.last_page > 1 &&
                    <span className="font-weight-bold">
                        {
                            pagination.current_page > 1 &&
                            <button onClick={() => changePage(1)}> First page</button>}
                        {
                            pagination.current_page > 1 &&
                            <button onClick={() => changePage(pagination.current_page - 1)}>Prev</button>
                        }
                        <span>
                            {
                                pages.map((item) => {
                                    // return(<Dropdown.Item eventKey={item.id}>{item.TabName}</Dropdown.Item>);
                                    return (<button className={isCurrentPage(item) ? 'active' :''}  disabled={isCurrentPage(item) ? 'disabled' :''} key={item} onClick={() => changePage(item)}>{item}</button>);
                                })
                            }
                        </span>
                        {
                            pagination.current_page < pagination.last_page &&
                            <button onClick={() => changePage(pagination.current_page + 1)}>Next</button>
                        }

                        {
                            pagination.current_page < pagination.last_page &&
                            <button onClick={() => changePage(pagination.last_page)}>Last page</button>
                        }
                    </span>
                }
            </nav>
       
    )
}

export default Pagination;