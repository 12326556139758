import {Col, Row} from "react-bootstrap";
import infoImgThree from "../../../../../assets/img/info-afi-img.png";
import infoAficoniImg1 from "../../../../../assets/img/info-afi-img1.png";
import infoAficoniImg2 from "../../../../../assets/img/info-afi-img2.png";
import infoAficoniImg3 from "../../../../../assets/img/info-afi-img3.png";
import infoimageOne from "../../../../../assets/img/info-img1.png";
import React, {useEffect, useState} from "react";

function AfiDiy({diyVideos,handleVideoModal,handleLoop,handleShuffle,loop,shuffle,currentVideo}){

    const [videos,setVideos] = useState('')

    useEffect(()=>{
        setVideos(diyVideos)
    },[diyVideos])



    return(
        <section className="afi-diy-section">
            <style>{css}</style>
            <div className="container">
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <div className="afi-diy-detail">
                            <h2>AFI’s DIY’s</h2>
                            <div className="afi-diy-content">
                                <p>LET US HELP YOU <br/> MAKE PRODUCTS <br/> YOUR CUSTOMERS <br/> WILL LOVE!</p>
                                <div className="afi-diy-user">
                                    <img src={infoImgThree} alt="image"/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <div className="afi-diy-video">
                            <div className="afi-diy-title">
                                <h3>AFI’S DIY’S</h3>
                                <p>Aromatic Fragrance international</p>
                            </div>
                            <div className="afi-diy-icon">
                                <div className="loading-icons">
                                    <span role="button" title="Loop Playlist" className={(loop? 'active':'')} onClick={handleLoop}><a><img src={infoAficoniImg1} alt="icon"/></a></span>
                                    <span title="Shuffle Playlist" className={(shuffle? 'active':'')} onClick={handleShuffle} role="button"><a><img src={infoAficoniImg2} alt="icon"/></a></span>
                                </div>
                                <div className="add-icons">
                                    {/*<span><a href="#"><img src={infoAficoniImg3} alt="icon"/></a></span>*/}
                                </div>
                            </div>
                            <div className="course-video-list" style={{height:"100%"}}>

                                {videos && videos.map(video=>(
                                    <div role="button"
                                         onClick={ (e)=>handleVideoModal(video,e)}
                                         className={"video-list-deials "+(currentVideo.videoID === video.videoID ? 'active':'' )}
                                         key={video.VideoId}
                                    >

                                        <div className="video-section">
                                            <img
                                                onClick={() => handleVideoModal(video)}
                                                role="button"
                                                src={(video?.thumbnail?.includes('image/') ? window.ASSET_URL+video.thumbnail:`https://img.youtube.com/vi/${video.embedlink}/${(video.thumbnail ?video.thumbnail:'maxresdefault.jpg')}`)}
                                                alt="image"/>
                                            {/*<img role="button" src={`https://img.youtube.com/vi/${video.embedlink}/maxresdefault.jpg`} alt="image"/>*/}
                                            {/*<img src={infoimageOne} alt="image"/>*/}
                                        </div>
                                        <div className="video-discription">
                                            <h3 >{(video.video_title.length > 29? video.video_title.substr(0,30)+'...':video.video_title)}</h3>
                                            <p>{(video.description.length > 50? video.description.substr(0,50)+'...':video.description)}</p>
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}
export default AfiDiy

const css = `
.active{
border: 3px solid black;
}
`