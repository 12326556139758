import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import { useLocation, useParams} from 'react-router-dom';
import Swal from 'sweetalert2'
import Table from 'react-bootstrap/Table';
    


export default function OnClickLink() {

    const params = useParams();

    console.log(params.id, 'userId'); // 👉️ {userId: '4200'}

    // const [loading, setLoading] = useState(false);

    const [frag_supp_l, setfragSuppSponOnClickLink] = useState([])
    const [frag_supp_h, setfragSuppSponOnClickLinkHead] = useState([])
    const [frag_supp_f, setfragSuppSponOnClickLinkFooter] = useState([])
	const [loading, setLoading] = useState(false);


    // var stringLength = frag_supp_spon_on_click_l.length;
    // console.log(stringLength, 'lenght')

    useEffect(() => {
        fetchFragSuppSponOnClickLink()
    }, [])

	const location = useLocation();
    // console.log(location, 'again usama here')


    const fetchFragSuppSponOnClickLink = async () => {
        
       setLoading(false);
        await axios
            .get(`${window.API_URL}frag_supp_spon_on_click_link/${params.id}`)
            .then(({data}) => {
                setfragSuppSponOnClickLink(data.frag_supp_link)
                setfragSuppSponOnClickLinkHead(data.frag_sup_head)
                setfragSuppSponOnClickLinkFooter(data.frag_supp_footer)
                // alert(data);

			    setLoading(true);
            })
    }

  


    return (
        
        <div className="container">
            <div className="row">
                <div className='col-8'>

                    <h2 style={{ marginLeft:"400px"}}>Typical G.C. analysis</h2>                  
 
                       
                    <Table striped bordered hover style={{ fontSize:"20px" ,textAlign:"center", "marginRight": "100px", "marginLeft": "200px", "borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>
                        <thead>
                            {
                                loading &&(
             
                            <tr style={{ fontSize:"25px", fontWeight: "bold" }}><a href={frag_supp_h[0].link} target="_blank" className="text-decoration-none">{ frag_supp_h[0].table_heading_text}</a></tr>)
                        }
                            <tr>
                                <th>#</th>
                                <th>%</th>
                                <th>Leftshift</th>
                                <th>Components</th>
                            </tr>
                        </thead>                          
                                    

                        <tbody>   
                         
                        { frag_supp_l.map((row, key) => (
                                                            
                                    <tr>
                                        <td>{row.hash_id}</td>  
                                        <td>{row.percentage}</td>
                                        <td>{row.leftShift}</td>
                                        <td><a href={row.link} target="_blank" className="text-decoration-none">{row.components}</a></td>
                                    </tr>      
                                  
                                    
                                                     

                            ))                        
                        }

                        {
                            loading &&(      
                        <tr>
                            { frag_supp_f[0].table_footer_text }             
                            
                        </tr>)
                         
                        }              
                        </tbody>  
                   </Table>
                  
                </div>
            </div>
        </div>
   
    )
}