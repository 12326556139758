import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {SwalBefore,SwalAfter} from "../../../../Widgets/alert";
import {useDispatch} from "react-redux";
import {LogoutAction} from "../../../../../redux/actions/AuthActions";
import axios from "axios";


const EmailVerify = ()=>{
    let verified = localStorage.verified;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let user = (localStorage.user ? JSON.parse(localStorage.user):'')
    let userToken = (localStorage['user-token'] ? localStorage['user-token']:'')
    let [sendBtn,setSendBtn] = useState(false);

    // dispatch(EmailVerificationAction('this is email verify'));

    const css = `
    h2 {
           color:#9ABC66;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-weight: 900;
          font-size: 40px;
          margin-bottom: 10px;
        }
        p {
          color: #404F5E;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-size:20px;
          margin: 0;
        }
      i {
        color: #000000;
        font-size: 100px;
        line-height: 200px;
        margin-left:-15px;
      }
      .card {
        background: white;
        padding: 60px;
        border-radius: 4px;
        box-shadow: 0 2px 3px #C8D0D8;
  
        margin: 0 auto;
        text-align:center;
       
      }
    `

    const UserLogOut = (e) => {

        Swal.fire({
            title: 'Please Confirm..',
            text: "You are Signing Out",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Logout'
        }).then((result) => {
            console.log(result)
            if (result.isConfirmed) {
                dispatch(LogoutAction(navigate));
            }
        })


    };

   const resendEmail = () => {

       Swal.fire({
           title: 'Confirm Resend Verification Email!',
           showDenyButton: true,
           // showCancelButton: true,
           confirmButtonText: 'Sent Email',
           denyButtonText: 'Cancel',
       }).then((result) => {
           /* Read more about isConfirmed, isDenied below */
           if (result.isConfirmed) {
               Swal.fire('Email has been sent!', 'Please check your inbox', 'success')
               const headers = {
                   'Content-Type': 'application/json',
                   'Authorization': 'JWT '+userToken
               }
               axios.post(window.API_URL+'users/email/verify/resend',{user,_token:userToken},{headers})
                   .then(response=>{
                       SwalAfter('success','Email Sent Successfully')
                       setSendBtn(true)
                   })
               setSendBtn(true)
           } else if (result.isDenied) {
               SwalAfter('info','Canceled','')
           }
       })


    };





    return(
        <React.Fragment>
            {/* <style>
                {css}
            </style> */}
            <div className="container">
                <div className="row ">
                    <div className="col-md-6 offset-3" style={{textAlign:'center'}}>
                        <div className="card">
                            {/*<div style={{borderRadius:"200px", height:"200px", width:"200px", background: "#F8FAF5", margin:"0 auto"}}>*/}
                                {/*<i className="checkmark">✓</i>*/}
                                <i className="fa fa-envelope" style={{fontSize:"100px"}}></i>
                            {/*</div>*/}
                            <h3>Email Verification Required!</h3>
                            <p>We have sent an Email<br/> Please check your email and verify your account!</p>
                            <div className="mt-5">
                                {verified==='notVerified' && <button onClick={resendEmail} disabled={sendBtn} className="btn btn-outline-info ">Resend Verification Email</button> }
                                {verified==='notVerified' && <button onClick={UserLogOut} className="btn btn-outline-dark m-2">Logout</button> }
                                {/*<Link to={'/login'} className="btn btn-outline-danger">Return to Login</Link>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>

    )
}
export default EmailVerify