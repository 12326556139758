import React from 'react';
import "./Fragrance.css";
import {Row, Col, Button, Tabs,Tab} from "react-bootstrap";
import fragrnaceInfo1mg from '../../../../assets/img/info-card-img2.png';


const FragrenceWheel = () => {



  return (
    <div>

        <section className="fragrance-composition-things">
            <div className='container'>
                <div className="composition-things-title">
                    <h1>FRAGRANCE WHEEL</h1>
                </div>
                <div className='hr-line'></div>
                <Row className='composition-row'>
                    <Col lg={7} md={7} sm={12}>
                        <div className="composition-things-details">
                            <p>
                            Here is our in-house fragrance wheel. Within it, fragrance families are arranged in 
                            a circle according to their relation to each other. In the middle of the wheel are 
                            the four most basic families: floral, amber, woody, and fresh. Along the outside, 
                            these families are broken up further based on specific differences that sets them 
                            apart within said family. Amber and wood fragrances are aligned next to each other 
                            on the wheel because they feature many similarities in scent; this is due to the 
                            notes both families share.  
                            </p>
                            <p>
                            Likewise, the aquatic family is placed next to the green family. Some fall between two 
                            of the larger categories, such as fruity fragrances which share characteristics from 
                            both the fresh and floral family. Some fragrance wheels break these families down even 
                            more with each category separated by the most of minute differences in notes. Fragrance 
                            wheels allow us to understand scent relationships and how to utilize different families 
                            to create a whole line of diverse products that tell a story through scent. If you’re 
                            unsure where to start when fragrancing your products, try to atleast one scent<br/> from each
                            of the broadest families. From there you can<br/> explore the intricacies of each family to 
                            cultivate a varied<br/> selection for your customers.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <div className="composition-rightside">
                            <div className='composition-img wheel'>
                                <img src={fragrnaceInfo1mg} />
                            </div>
                            <div className='composition-btn'>
                                    <Button type='button' className='resetbht my-composit-btn'><a target="_blank" className="text-decoration-none text-white" href={window.API_URL+'download-pdf/fragrance-wheel'}>DOWNLOAD</a></Button>
                            </div>
                            <div className="composition-txt">
                                <h4>“Fragrance wheels allow us to understand scent relationships<br/> and
                                    how to utilize different<br/> families to create a whole<br/> line of diverse
                                    products that<br/> tell a story through scent.”</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    </div>
  )
}

export default FragrenceWheel