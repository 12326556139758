import fragrnaceInfo1mg from '../../../assets/img/fragrnace-inner-img.png';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {useState} from "react";
import axios from "axios";
import {toastCss,SwalBefore,SwalAfter} from "../../Widgets/alert";

function ContactUs1 (){
    const { executeRecaptcha, recaptchaToken } = useGoogleReCaptcha();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [disabled,setDisabled] = useState(true);

    const [errors, setErrors] = useState({});

    const [recaptchaBtnTxt, setRecaptchaBtnTxt] = useState('Verify ReCaptcha');



    const validateForm = () => {
        let errors = {};

        if (!name.trim()) {
            errors.name = "Name field is required";
        }

        if (!email.trim()) {
            errors.email = "Email field is required";
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            errors.emailValid = "Email address is invalid";
        }

        if (!message.trim()) {
            errors.message = "Message field is required";
        }
        setErrors(errors);

        return errors;
    };

    const handleInputChange = (e) => {
        // Clear errors object on input change

        let errorsTmep= {...errors}

        // Update state with input values
        if (e.target.name === 'name') {
            setName(e.target.value);
            delete errorsTmep.name;
        }
        if (e.target.name === 'email') {
            setEmail(e.target.value);
            delete errorsTmep.email;

            console.log(e.target.value)
            console.log(/\S+@\S+\.\S+/.test(e.target.value))

            if(/\S+@\S+\.\S+/.test(e.target.value)){
                delete errorsTmep.emailValid;
            }
        }

        console.log(errorsTmep.message)
        if (e.target.name === 'message') {
            console.log('message')
            setMessage(e.target.value);
            delete errorsTmep.message;
        }
        setErrors(errorsTmep);
    };

    const handleSubmit = () =>{
       let errorsObj = validateForm()
        if (Object.keys(errorsObj).length === 0) {
            SwalBefore('sending email','please wait')
            axios.post(window.API_URL+'send-contact-form', {name,email,message})
                .then(response =>   {
                    SwalAfter('success','sent successfully')
                    setDisabled(true);
                })
                .catch(error => {
                    SwalAfter('error','sent sending failed')
                })

        } else {
            console.log(errorsObj)
        }
    }

    const handleReCaptcha = async () => {
        const token = await executeRecaptcha('contact_us');
        setRecaptchaBtnTxt('Verifying reCaptcha...')
        try {
            // Send a POST request to the reCAPTCHA API to verify the user's response
            const result = await axios.post(window.API_URL+'verify-recaptcha', {
                response: token
            });


            const data = result.data;
            console.log(result);
            if (data.score > 0.5) {
                // User's response was valid, proceed with form submission or take appropriate action
                setDisabled(false);
                setRecaptchaBtnTxt('reCaptcha Verified')
                console.log('reCAPTCHA verification success');
            } else {
                // User's response was invalid, show error message or take appropriate action
                console.log('reCAPTCHA verification failed');
                setRecaptchaBtnTxt('Verify reCaptcha')
            }
        } catch (error) {
            // Handle any errors that occur during the verification process
            console.error('Error verifying reCAPTCHA:', error);
        }
    };

    return(
        <div className="container contact-us">
            <style>{css}</style>
            <div className="row">
                <div className="col-md-3"></div>

                <div className="col-md-6 ">

                   <div className="row">

                       <div className="col-md-12">
                           <h1 className="contact-side-h1 text-center">HOW CAN WE HELP YOU?</h1>
                           <hr className="contact-side-hr"/>
                       </div>
                   </div>

                    <br/>
                    <div className="card">
                        <div className="card-body ">
                                <div className="input-col">
                                    <input type="text" placeholder="Name: |" id="input1"
                                           onChange={handleInputChange} name="name"/>
                                    {errors.name && <small className="text-danger">{errors.name}</small>}
                                </div>
                                <div className="input-col">
                                    <input type="email" placeholder="Email: |" id="input2"
                                           onChange={handleInputChange}  name="email"/>
                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                    {!errors.email && errors.emailValid && <small className="text-danger">{errors.emailValid}</small>}
                                </div>
                                <div className="input-col">
                                    <textarea rows={10} placeholder="Message: |" id="input3"
                                              onChange={handleInputChange} name="message">

                                    </textarea>
                                    {errors.message && <small className="text-danger">{errors.message}</small>}
                                </div>
                            <div className="">
                                <button type="submit" disabled={disabled} onClick={handleSubmit}>Submit</button>
                                <button onClick={handleReCaptcha} className="verifyBtn " disabled={!disabled}>{recaptchaBtnTxt}</button>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <p  className="text-center address-p">
                                <span style={{color:"#c42625"}}>AROMATIC FRAGRANCES <br/>INTERNATIONAL</span>
                               <span style={{color:"#8F8B88"}}>
                                    <br/>85 WANSLEY DR <br/>
                                CARTERSVILLE, GA 30121
                               </span>
                            </p>
                            <p  className="text-center address-p">
                                <span style={{color:"#c42625"}}>CONTACT US:</span>
                               <span style={{color:"#8F8B88"}}>
                                  <br/> INFO@AFI-USA.COM <br/>
                                   770-334-3906
                               </span>
                            </p>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <p  className="last-p">
                                "WE DON'T HAVE EMPLOYEES
                                OR CUSTOMERS, WE ARE ONE BIG
                                FAMILY. AS SOON AS YOU START
                                YOUR JOURNEY WITH US - YOU
                                BECOME ONE OF US."
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3"></div>
            </div>


        </div>
    )
}

function ContactUs() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LfVFZ4lAAAAANYU_S2xN0V2KK1siliuijy1wh_J">
            <ContactUs1 />
        </GoogleReCaptchaProvider>
    );
}
export default ContactUs;

const css =  `
input:hover {
  cursor: default;
}

textarea:hover {
  cursor: default;
}
.verifyBtn {
  padding: 12px 24px;
  background-color: #0069d9;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
  float: right;
}

.verifyBtn:hover {
  background-color: #005cbe;
}
.verifyBtn:disabled {
   background-color: #28a745!important;
   color: white;
   cursor: not-allowed;
}

.contact-us .composition-img img {
    height: auto;
    max-width: 90%;
    padding-right: 10px;
} 

.contact-us .address-p {
    margin-left: -70px!important;
    font-weight: bold;
}   
.contact-us button[type="submit"]:disabled {
  background-color: #ce6d6c!important;
  color: #ffffff;
  cursor: not-allowed;
}
.contact-us hr {
    height: 6px!important;
    width: 81px;
    color: #c42625;
    opacity: 1!important;
    margin: 0 auto;
}
.contact-us p{
font-size:28px;
}

.contact-us h1{
color:#c42625!important;
font-weight: bold;
font-size: 46px;
}
.contact-us .card-body {
  display: flex;
  flex-direction: column;
  border:none;
}

.contact-us .card{
border:none;
}

.contact-us .input-col {
  display: flex;
  flex-direction: column;
   margin-bottom: 20px;
   
}
.contact-us input{
  width: 100%;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  border: 3px solid black;
  border-radius: 20px;
  background-color: #7f7f7f;
}

.contact-us textarea{
  width: 100%;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  border: 3px solid black;
  border-radius: 20px;
  background-color: #7f7f7f;
}
.contact-us  input::placeholder {
    font-size: 20px;
    font-weight: bold;
    color: #2d2d2d;
  }
    
  .contact-us  textarea::placeholder {
    font-size: 20px;
    font-weight: bold;
    color: #2d2d2d;
  }
 .contact-us button[type="submit"] {
  background-color: #c42625;
  color: white;
  font-size: 24px;
  padding: 12px 24px;
  border: 3px solid black;
  border-radius: 20px;
  width: 200px;
  margin-top: auto;
}
.contact-us .last-p{
color:#c42625;
 border-bottom: 2px solid  #c42625;
 padding: 0px 0px 44px 50px;
 font-weight: bold;
 text-align: right;
    padding-top: 24px;
}

.contact-us .img-responsive{
width:150px;
}



@media (min-width: 768px) and (max-width: 1024px) {

.contact-us h1{
font-size: 40px;
}
.contact-us .composition-img img {
    height: 125px;
    max-width: 200px;
    padding-right: 10px;
}

.composition-rightside {
    max-width: 45%;
}
 .contact-us p{
font-size:23px;
}

.contact-us input{
  width: 100%;
  padding: 10px;
  font-size: 10px;
  border: 3px solid black;
  border-radius: 15px;
}

.contact-us textarea{
  width: 100%;
  padding: 10px;
  font-size: 10px;
}

 .contact-us button[type="submit"] {
  font-size: 14px;
  padding: 6px 12px;
  width: 100px;
   border-radius: 15px;
}
.contact-us .last-p{
color:#c42625;
 border-bottom: 2px solid  #c42625;
    padding-top: 20px;
}
.contact-us .img-responsive{
width:100px!important;
}

}


@media (max-width: 767px){
    
    .contact-us .address-p {
    margin-left: 0px!important;
    font-weight: bold;
}   
    
.contact-us h1{
font-size: 35px;
}

.contact-us .composition-img img {
    height: 125px;
    max-width: 200px;
    padding-right: 10px;
}

.contact-us .composition-rightside {
    max-width: 45%;
}
.contact-us p{
font-size:20px;
}

 .contact-us button[type="submit"] {
  font-size: 16px;
  padding: 10px 20px;
  border: 3px solid black;
  border-radius: 20px;
  width: 100px;
  margin-top: auto;
  float:left;
}

.contact-us .last-p{
  padding-top: 0px;
}

.contact-us hr {
    height: 4px!important;
    margin-top: 0px;
    margin: 0 auto;
}
br{
display: none;
}

.verifyBtn {
    padding: 7px 6px;
    font-size: 14px;
    }

}

`