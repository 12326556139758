import React, { useState } from 'react';
import Joi from "joi-browser";
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { Form, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
export default function ResetPassword(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail]=useState([""]);
 
    const [password, setPassword]=useState([""]);
    const [confirm_password, Serconfirm_password]=useState([""]);
    const [errors, setErrors]=useState([]);

    const [schema, setSchema] = useState({
        password:Joi.string().required().label('Password'),
        confirm_password:Joi.string().required().label('confirm_password'),
    })

   

    const Resetpass = async (e) => {
        e.preventDefault();
           if(password===confirm_password)
           { 
            let url=`${window.API_URL}reset_password`;
            let lasturl = window
            .location
            .pathname
            .split('/')
            .pop();
            const formData = new FormData()
            formData.append('password', password)
            formData.append('email', email)
            formData.append('confirm_password', confirm_password)
            formData.append('token', lasturl)
            await axios.post(url, formData).then(({data})=>{
                if(data.status==200)
                {
                Swal.fire({
                    icon:"success",
                    text:data.message
                  })
                  navigate(`/Login`)
                }
                else 
                {
                    Swal.fire({
                        text:data.message,
                        icon:"error"
                      })
                }
            })   
        }
        else
           {
            Swal.fire({
                text:"please both fields are same",
                icon:"error"
              })
           }
    }
    
   
  
    return (
        <div className="row">
            <div className="col-md-6 offset-3">
                <div className="container">
                    <div className="card" >
                        <div className="card-header " style={{ background: "#c42625" }} ><h3 style={{ color: "white" }}>Reset Password</h3></div>
                        <div className="card-body">
                            <div className="row">
                                <Form onSubmit={Resetpass}>
                                <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" name="email" margin="normal"
                                            variant="outlined"
                                            label="email"
                                            id="email"
                                            onChange={(event)=>{
                                                setEmail(event.target.value)
                                            }}  required/>
                                            {/* {errors['email']  && <small className="text-danger text-left"> {errors['email']}</small>} */}
                                    </Form.Group>
                                <Form.Group className="mb-2" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" name="password" margin="normal"
                                            variant="outlined"
                                            id="password"
                                            onChange={(event)=>{
                                                setPassword(event.target.value)
                                            }} required/>
                                            {/* {errors['password']  && <small className="text-danger text-left"> {errors['password']}</small>} */}
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="formPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" placeholder="confirm password" name="confirm_password" margin="normal"
                                            variant="outlined"
                                            id="confirm_password"
                                      
                                            onChange={(event)=>{
                                                Serconfirm_password(event.target.value)
                                            }} required/>
                                            {/* {errors['confirm_password']  && <small className="text-danger text-left"> {errors['confirm_password']}</small>} */}
                                    </Form.Group>
                                 
                                    <div>
                                        <Button variant="primary form-control"  block="block" type="submit">
                                            Submit
                                        </Button>
                                    </div>        
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
