import * as ActionTypes from '../ActionTypes';
import App from '../../App';
import Swal from 'sweetalert2'
import {SwalAfter, SwalBefore} from "../../components/Widgets/alert";
import { RegisterUserService, LoginUserService, LogOutUserService } from '../services/AuthService';
export const RegisterAction = (credentials) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        RegisterUserService(credentials).then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                dispatch({ type: ActionTypes.SIGNUP_SUCCESS, res });
            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.SIGNUP_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const LoginAction = (credentials, navigate) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE});
        dispatch({ type: ActionTypes.LOADING });
        LoginUserService(credentials).then((res) => {          
            if(res.success) {
                var current = new Date();

                localStorage.setItem('time', current)
                localStorage.setItem('user', JSON.stringify(res.data))

                if (res.hasOwnProperty('success') && res?.success == true) {

                    dispatch({type: ActionTypes.LOGIN_SUCCESS});
                    if (res.data.email_verified_at === null && res.data.roles[0].name !== 'admin') {

                        localStorage.setItem('verified', 'notVerified')
                        localStorage.setItem('user-token', res.token)

                        navigate('/email/verify');
                        window.location.reload();
                        return false;
                    }
                    if (res.data.roles[0].name.toLowerCase() === 'user') {
                        localStorage.setItem('user-token', res.token)
                        localStorage.setItem('role', 'user')
                        let redirectUrl = localStorage.getItem('redirect-url')
                        if(redirectUrl) {
                            localStorage.removeItem('redirect-url')
                            window.location.href = redirectUrl
                            return false;
                        }
                        navigate('/user/profile')
                        window.location.reload();
                    } else {
                        localStorage.setItem('user-token', res.token)
                        localStorage.setItem('role', res.data.roles[0].name)
                        navigate('/admin');
                        window.location.reload();
                    }

                }

            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.LOGIN_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
export const LogoutAction = (navigate) => {
    if(navigate) {
        SwalBefore('Signing Out')
    }

    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        LogOutUserService().then((res) => {
            if (res.hasOwnProperty('success') && res.success === true) {
                if(navigate){
                    SwalAfter('success','Logout Successfully!')
                    navigate('/login');
                }
                dispatch({ type: ActionTypes.LOGOUT_SUCCESS, res });
                localStorage.clear()
                window.location.reload();

            } else if (res.hasOwnProperty('success') && res.success === false) {
                dispatch({ type: ActionTypes.LOGOUT_SUCCESS, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}