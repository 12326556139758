import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {useNavigate} from "react-router";

const EmailVerified = ()=>{

    let redirect = useNavigate();
    const css = `
    h3 {
          color: #88B04B;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-weight: 900;
          font-size: 40px;
          margin-bottom: 10px;
        }
        p {
          color: #404F5E;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-size:20px;
          margin: 0;
        }
      i {
        color: #9ABC66;
        font-size: 100px;
        line-height: 200px;
        margin-left:-15px;
      }
      .card {
        background: white;
        padding: 60px;
        border-radius: 4px;
        box-shadow: 0 2px 3px #C8D0D8;
        display: inline-block;
        margin: 0 auto;
      }
    `

    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('user-token')
    // console.log(user)
    let id = user.id
    //
    useEffect(() => {
        axios.post(window.API_URL+'users/get-user',{id },{headers: {
                'Authorization': 'Bearer ' + token,
                'Content-type': 'application/json'
            }})
            .then(response=>{
                // localStorage.setItem('user','')
                console.log(response.data.user)
                localStorage.setItem('user',JSON.stringify(response.data.user))
                redirect('/user/profile')
                window.location.reload()
            })
    }, [])


    return(
        <React.Fragment>
            <style>
                {css}
            </style>
            <div className="container">
                <div className="row ">
                    <div className="col-md-6 offset-3" style={{textAlign:'center'}}>
                        <div className="card">
                            <div style={{borderRadius:"200px", height:"200px", width:"200px", background: "#F8FAF5", margin:"0 auto"}}>
                                <i className="checkmark">✓</i>
                            </div>
                            <h3>Your Account has been verified Successfully!</h3>
                            <div className="mt-5">
                                {/*<Link to="/user/profile" className="btn btn-outline-success">Go to Profile</Link>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>

    )
}
export default EmailVerified