import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import infoCardimgOne from "../../../../../assets/img/info-card-img1.png";
import infoCardimgTwo from "../../../../../assets/img/info-card-img2.png";
import infoCardimgThree from "../../../../../assets/img/info-card-img3.png";

function WheelSection(){
    return(
        <section className="fragrance-info-check">
            <div className="container">
                <Row className="info-check-wrap">
                    <Col lg={4} md={4} sm={12}>
                        <div className="info-check-column">
                            <div className="info-check-image">
                                <img src={infoCardimgOne} alt="image"/>
                            </div>
                            <div className="info-check-detail">
                                <h3>Color <br/> Wheel</h3>
                                <Link to="/FragranceColorWheel" className="btn resetbht check-out-btn">
                                    Check it out
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <div className="info-check-column">
                            <div className="info-check-image">
                                <img src={infoCardimgTwo} alt="image"/>
                            </div>
                            <div className="info-check-detail">
                                <h3>Fragrance <br/> Wheel</h3>
                                <Link to="/FragranceWheel" className="btn resetbht check-out-btn">
                                    Check it out
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <div className="info-check-column">
                            <div className="info-check-image">
                                <img src={infoCardimgThree} alt="image"/>
                            </div>
                            <div className="info-check-detail">
                                <h3>Fragrance <br/> Composition</h3>
                                <Link to="/FragranceComposition" className="btn resetbht check-out-btn">
                                    Check it out
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default WheelSection