import React,{Component} from "react";
import MultiStep from 'react-multistep'
import StepOne from "./calculator/lye-calculator/stepOne";
import StepTwo from "./calculator/lye-calculator/stepTwo";
import StepThree from "./calculator/lye-calculator/stepThree";
import axios from "axios";
import Joi from 'joi-browser';
import _ from 'lodash'
import {Toast,toastCss} from "../../Widgets/alert";




class LyeCalculatorComponent extends Component{
	constructor() {
		super();
	}

	state = {
		oils:[],
		stepOneState: {
			inputs: {
				type: '',
				measureUnit: '',
				superfattingLevel: '',
				oilWeight: '',
				percentage: '',
			},
			oilHide:true,
			errors:{}
		},
		stepTwoState:{
		},
		currentStep: 1,
		sumOilValues:0,
		toggle:true
	};

	schema = {
		type: Joi.string().required(),
		measureUnit: Joi.string().required(),
		superfattingLevel: Joi.string().required(),
	}


	componentDidMount() {

		this.getOils();
		this.addBtnStyling();
		window.addEventListener("click", this.HandleNextDisable);
		window.addEventListener("click", this.handlePrevStepChange);
		window.addEventListener("click", this.handleNextStepChange);
	}



	//get element from dom
	btnNext = ()=>{
		let xpath = "//button[text()='Next']";
		return  document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
	}

	//get element from dom
	btnPrev = ()=>{
		let xpath = "//button[text()='Prev']";
		return  document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
	}

	// Next btn events ================


	//disable next on mouseover
	HandleNextDisable = (event)=>{
		if (event.target.textContent === 'Next') {
			if (event.target.className === 'btn btn-outline-danger') {
				let element = document.getElementsByClassName("go2791332690");
				const {currentStep, stepTwoState, stepOneState, sumOilValues} = this.state;
				if (currentStep === 1) {

					let errors = this.validate()
					if (errors !== null) {
						element[0].click()
						Toast('error', 'Please Select All Inputs')
					}else {

						this.setState({currentStep:2})
					}
				} else if (currentStep === 2) {
					let check = 0;
					if (_.isEmpty(stepTwoState, true)) {
						Toast('error', 'One Input Must be Fill')
						check++;

					}else if (parseInt(stepOneState.inputs.oilWeight) > 0 && sumOilValues !== 100) {
						Toast('error', 'Oils Quantity Must 100%')
						check++;
					}

					if (check === 0){
						this.setState({currentStep:3})
					}else {
						if (element[1]){
							element[1].click()
						}
					}
				}
			}
		}
	}

	//increment step
	handlePrevStepChange = (event)=>{
			if (event.target.textContent === 'Prev'){
				let currentStep = this.state.currentStep-1;
				let stepOneState = this.state.stepOneState;
				if (stepOneState.inputs.oilWeight){
					stepOneState.inputs.oilWeight = '';
				}

				this.setState({currentStep,stepOneState})
				this.btnNext().disabled = false;
			}
	}
	componentWillUnmount() {
		window.removeEventListener("click", this.HandleNextDisable);
		window.removeEventListener("click", this.handlePrevStepChange);
		window.removeEventListener("click", this.handleNextStepChange);
	}

	//add style to next prev
	addBtnStyling = ()=>{
		this.btnNext().classList.add('btn','btn-outline-danger')
		this.btnPrev().classList.add('btn','btn-outline-dark')
		this.btnNext().parentNode.style.textAlign = 'center'
	}

	// get oil for 2nd step
	getOils = ()=>{
		axios.get(window.API_URL+'oils')
			.then(response =>{
				let oils = response.data.oils;
				this.setState({oils})
			})
	}

	sumObjectValues = obj => Object.values(obj).reduce((a, b) => a + b);

	handleStepTwo = ({currentTarget:input})=>{
		let stepTwoState = {...this.state.stepTwoState}

		stepTwoState[input.name] = (input.value && input.value > 0  ? parseFloat(input.value):0)
		if (parseInt(this.state.stepOneState.inputs.oilWeight)>0){
			let sumOilValues = this.sumObjectValues(stepTwoState)
			this.setState({sumOilValues})
			if (sumOilValues === 100){
				this.btnNext().disabled = false;
			}
		}


		if (!stepTwoState[input.name] ){
			delete stepTwoState[input.name];
		}
		if (!_.isEmpty(stepTwoState,true)){
			this.btnNext().disabled = false;
		}

		this.setState({stepTwoState})

		}

	// step one state
	handleStepOne = ({currentTarget:input})=>{

		const stepOneState = {...this.state.stepOneState}
		if(input.name === 'measureUnit' ){
			if (input.value === 'percentage'){
				stepOneState.oilHide = false
				stepOneState.inputs.percentage = 'oz';
			}else {
				stepOneState.oilHide = true
			}
			stepOneState.inputs.oilWeight = '';
			this.schema.oilWeight =Joi.string().required();
			this.schema.percentage =Joi.string().required();
		}

			if (stepOneState.oilHide === true) {
				delete stepOneState.inputs.oilWeight;
				delete stepOneState.inputs.percentage;
				delete this.schema.oilWeight;
				delete this.schema.percentage;
				delete stepOneState.errors.oilWeight;
				delete stepOneState.errors.percentage;
			}
			if (input.name === 'oilWeight'){

				stepOneState.inputs[input.name] =( input.value < 0? '':input.value );

			}else {
				stepOneState.inputs[input.name] = input.value;
			}


		delete stepOneState.errors[input.name]

		stepOneState.errors = this.validate() || {};
		this.setState({stepOneState})
	}

	// handle step three oil batch

	handleOilBatch = ({currentTarget:input})=>{
		const stepOneState = {...this.state.stepOneState}

		if (parseFloat(input.value) < 0){
			stepOneState.inputs[input.name] = '';
		}else {
			stepOneState.inputs[input.name] = parseFloat(input.value)
		}

		stepOneState.inputs.percentage = input.placeholder
		stepOneState.inputs.measureUnit = input.placeholder
		this.setState({stepOneState,toggle:true})
	}

		//validate step one inputs
		validate = ()=>{
		let result = Joi.validate(this.state.stepOneState.inputs,this.schema,{abortEarly:false});
		if (!result.error) {
			this.btnNext().disabled = false;
			return null;
		}
		const error={};
		for (let item of result.error.details ){
			error[item.path[0]] = 'This Field is Required';
		}
		return error;
		}

	getStyle = () => {
		return (`
	.calc-section button{
	margin:10px;
	color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
        display: inline-block;
    font-weight: 400;
    line-height: 1.5;
	}
	.calc-section button:hover{
		color: #fff;
    background-color: #212529;
    border-color: #212529;
	}
	.card-body ol{
	text-align:center;
	}
	ol{display:none;}
	`
		);
	}

	handleToggle = (e)=>{

		let toggle = !this.state.toggle
		let  stepOneState = {...this.state.stepOneState};

		if (stepOneState.inputs.percentage){
			stepOneState.inputs.percentage =  (stepOneState.inputs.percentage === 'oz'? 'g':'oz')
		}else {
			stepOneState.inputs.measureUnit =  (stepOneState.inputs.measureUnit === 'g'? 'oz':'g')
		}


		this.setState({toggle,stepOneState})
	}


	render() {


		const {oils,stepOneState,stepTwoState,sumOilValues,toggle} = this.state

		const steps = [
			{name: 'StepOne', component: <StepOne state={stepOneState} handleStepOne={this.handleStepOne}/>},
			{name: 'StepTwo', component: <StepTwo sumOilValues={sumOilValues}  oilWeight={stepOneState.inputs.oilWeight}  state={stepTwoState} oils={oils} handleStepTwo={this.handleStepTwo}/>},
			{name: 'StepThree', component: <StepThree toggle={toggle}  handleToggle={this.handleToggle} handleOilBatch={this.handleOilBatch}  ingredients={stepTwoState} stepOne={stepOneState.inputs} oils={oils} />},
		];

		return (
			<React.Fragment>
				<div>
					<style>
						{this.getStyle()}
						{toastCss()}
					</style>
					<div className="candlecalculator-sec">

						<div className=" col-md-12">
							<div className="card">
								<div className="card-header">
									<h4 >Lye Calculator</h4>
								</div>
								<div className="card-body">
									<MultiStep activeStep={0} showNavigation={true} steps={steps}/>
								</div>
							</div>
						</div>
						<br/>
					</div>
				</div>
			</React.Fragment>
		)
	}

}
export default LyeCalculatorComponent;