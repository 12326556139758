import {Modal} from "react-bootstrap";
import React from "react";
import Plyr from "plyr-react";
import 'plyr-react/plyr.css'

const VideoModal = ({handleModal,showModal,closeBtn=true,data,heading})=>{

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton={closeBtn}  className="text-white " style={{background:"#c42625",height:"8px"}}>
                </Modal.Header>
                <Modal.Footer className="p-0" >
                    <div className="row text-center login-form-holder p-0 m-0" >
                        <div className="col-md-12 p-0">
                            {/*<Plyr autoPlay={true} source={{*/}
                            {/*    type: "video", sources: [{*/}
                            {/*        src: window.ASSET_URL + 'videos/fu-intro.mp4',*/}
                            {/*        type: 'video/mp4',*/}
                            {/*        size: 720,*/}
                            {/*    }]*/}
                            {/*}}/>*/}
                            <iframe width="100%" height="500" src="https://www.youtube.com/embed/fMI5KrK4SQQ?rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen="allowfullscreen">
                            </iframe>
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default VideoModal