import {UPDATE_USER_PROFILE,ADD_USER} from '../ActionTypes';

const UserProfileReducer = (state = {}, action)=>{
    let user = {};
    if (action.user){
        user = action.user;
    }
    switch (action.type){
        case ADD_USER:
            return {
                ...state,
                ...user
            }
        case UPDATE_USER_PROFILE:
            return{
                ...state,
                ...user
            }
        default:
            return state
    }
}

export default UserProfileReducer;