import React, {useEffect, useState} from "react";
import AllThingsFragrance from "./fragrance-info-sections/AllThingsFragrance";
import axios from "axios";
import VideosSlider from "./fragrance-info-sections/VideosSlider";
import VideoModal from "./fragrance-info-sections/VideoModal";
import AfiDiy from "./fragrance-info-sections/AfiDiy";
import SocialSection from "./fragrance-info-sections/SocialSection";
import WheelSection from "./fragrance-info-sections/WheelSection";
import StatisticsSection from "./fragrance-info-sections/StatisticsSection";
import SurveyModal from "./fragrance-info-sections/SurveyModal";




function FragranceInfocomponent() {

	const [topVideos,setTopVideos] = useState('')
	const [diyVideos,setDiyVideos] = useState('')
	const [giveawayVideos,setGiveawayVideos] = useState('')
	let [showVideoModal,setShowVideoModal] = useState(false)
	const [currentVideo,setCurrentVideo] = useState('')
	const [shuffle,setShuffle] = useState(false)
	const [loop,setLoop] = useState(false)
	const [surveyModalShow,setSurveyModalShow] = useState(false)
	const controller = new AbortController();
	const config = {
		signal: controller.signal
	}


	useEffect( () => () => controller.abort(), [] );//unmount

	useEffect(()=>{
		getVideos()
	},[])


	const handleSurveyModal = ()=>{
		setSurveyModalShow(!surveyModalShow)
	}


	function randomIntFromInterval(min, max) { // min and max included
		return Math.floor(Math.random() * (max - min + 1) + min)
	}



	const handleYoutubeVideoStateChange = (e)=>{

		let index = diyVideos.findIndex(video=>video.videoID === currentVideo.videoID)

		if(shuffle){
			index = randomIntFromInterval(0, (diyVideos?.length-1))
		}
		if(e.data === 0 && loop){

			if(index+1 < diyVideos.length) {
				setCurrentVideo(diyVideos[index + 1])
			}else {
				setCurrentVideo(diyVideos[0])
			}

		}
	}

	const handleLoopPlaylist = ()=>{
		setLoop(!loop)
	}

	const handleShufflePlaylist = ()=>{
		setShuffle(!shuffle)
	}


	const handleVideoModal = (video,e='')=>{
		if(video){
			setCurrentVideo(video)
			if (e){
				handleActive(e)

			}else {
				setLoop(false)
				setShuffle(false)
			}
		}else {
			setCurrentVideo('')
		}
		setShowVideoModal(!showVideoModal)
	}

	const handleActive = (e)=>{
		let currentElement = e.target.closest('.video-list-deials');
		currentElement.classList.add('active')
		let allElements = document.getElementsByClassName('video-list-deials');
		for(let index=0;index<allElements.length;++index){
			let element =  allElements[index]
			if(element !== currentElement){
				element.classList.remove('active')
			}
		}
	}

	const getVideos = ()=>{
		axios.get(window.API_URL+'fragrance-info-videos',config)
			.then(({data})=>{
				setTopVideos(data?.videos['topSection'])
				setDiyVideos(data?.videos['diy'])
				const temVideos = [...data?.videos['giveaway']]
				setGiveawayVideos(temVideos?.slice(0,3))
			})
	}

	return (<>
			<style>{css}</style>
			<VideoModal
				handleModal={handleVideoModal}
				currentVideo={currentVideo}
				showModal={showVideoModal}
				handleYoutubeVideoStateChange={handleYoutubeVideoStateChange}
			/>
			<SurveyModal
				handleClose={handleSurveyModal}
				show={surveyModalShow}
			/>
			<AllThingsFragrance
				topVideos={topVideos}
				handleVideoModal={handleVideoModal}
			/>

			<SocialSection/>

			<WheelSection/>

			<VideosSlider
				giveawayVideos={giveawayVideos}
				handleVideoModal={handleVideoModal}
			/>
			<StatisticsSection
				handleSurveyModal={handleSurveyModal}
			/>

			<AfiDiy
				diyVideos={diyVideos}
				handleVideoModal={handleVideoModal}
				handleLoop={handleLoopPlaylist}
				handleShuffle={handleShufflePlaylist}
				loop={loop}
				shuffle={shuffle}
				currentVideo={currentVideo}
			/>
		</>
	)
}

export default FragranceInfocomponent;

const css = `
 .course-video-list .active{
            background:#000!important;
            transition: width 2s;
    }
     .course-video-list  .video-list-deials:hover{
            background:#000!important;
    }
`