import HttpService from './HttpService';
import Swal from "sweetalert2";
import {SwalBefore, Toast, toastCss} from "../../components/Widgets/alert";
export const RegisterUserService = (credentials) => {
    const http = new HttpService();
    let signupUrl = "users/register";
    return http.postData(credentials, signupUrl).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}
export const LoginUserService = (credentials) => {
    const http = new HttpService();
    let loginUrl = "users/login";
    SwalBefore('Signing In ')
    // Swal.fire({
    //     title: "Checking...",
    //     text: "Please wait",
    //     imageUrl: "images/ajaxloader.gif",
    //     showConfirmButton: false,
    //     allowOutsideClick: false
    // });
    return http.postData(credentials, loginUrl).then((data) => {
        let icon = 'error'
        let title = 'Login Failed'
        if(data.success){
             icon = 'success'
             title = 'Login Successful'
        }
        Swal.fire({
            icon: icon,
            title: title,
            text: data['message'],
            showConfirmButton: false,
            timer: 1000
        });
        return data;
    }).catch((error) => {
        Swal.fire({
            title: "Error!",
            text: error.message,
            showConfirmButton: false,
            timer: 1000
        });
        return error;
    })
}
export const LogOutUserService = () => {
    const http = new HttpService();
    let loginUrl = "users/logout";
    const tokenId = "user-token";
    return http.getData(loginUrl, tokenId).then((data) => {
        console.log(data);
        return data;
    }).catch((error) => {
        return error;;
    })
}