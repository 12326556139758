import React, {useState, useEffect} from 'react';
import {useLocation,useNavigate} from "react-router-dom";
import axios from "axios";

import {SwalAfter, SwalBefore} from "../../../Widgets/alert";

function FacebookCallback() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [user, setUser] = useState(null);
    const location = useLocation();
    const navigate = useNavigate()

    // On page load, we take "search" parameters
    // and proxy them to /api/auth/callback on our Laravel API
    useEffect(() => {
        console.log(location.search)
        SwalBefore('Signing In with Facebook','Please Wait...')
        axios.get(`${window.API_URL}auth/callback${location.search}&provider=facebook`, {
            headers : {
                'Content-Type': 'application/json', 'Accept': 'application/json'
            }}).then(({data}) => {
                setLoading(false);

                if(data.success){
                    SwalAfter('success','Login Successfully..')
                    var current = new Date();
                    localStorage.setItem('time', current)
                    localStorage.setItem('user', JSON.stringify(data.data))
                    setUser(data.data)
                    if (data.data.roles[0].name.toLowerCase() === 'user') {
                        localStorage.setItem('user-token', data.token)
                        localStorage.setItem('role', 'user')
                        let redirectUrl = localStorage.getItem('redirect-url')
                        if(redirectUrl) {
                            localStorage.removeItem('redirect-url')
                            window.location.href = redirectUrl
                            return false;
                        }
                        navigate('/user/profile')
                        window.location.reload();
                    }
                }else{
                    navigate('/login')
                    SwalAfter('error','Login Failed..')
                }
            }).catch(error=>{
                navigate('/login')
                 SwalAfter('error','Login Failed..')
        })
    }, []);

 return <div style={{padding:"500px"}}></div>;
}

function DisplayLoading() {
    return <div>Loading....</div>;
}

function DisplayData(data) {
    return (
        <div>
            <samp>{JSON.stringify(data, null, 2)}</samp>
        </div>
    );
}

export default FacebookCallback;