import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Joi from 'joi-browser';
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

function MassConvertor() {

const [to, setTo] = useState("")
const [from, setFrom] = useState("")
const [quantity, setQuantity]=useState("")
const [result, setResult]=useState("")
const [errors,setErrors]=useState("")


const Calculate = (e) => {
    e.preventDefault();

    if(!quantity){
        setErrors({...errors,quantity:'Quantity is required'})
        return;
    }
    if(!to){
        setErrors({...errors,to:'To is required'})
        return;
    }
    if(!from){
        setErrors({...errors,from:'From is required'})
        return
    }


    if (to === '1') { //gram
        if (from === '5') {/*if g*/
            let q = quantity
            setResult(q)
        } else if (from === '6') {/*if lbs*/
            let q = quantity * 0.00220462;
            setResult(q)
        } else if (from === '7') {/*if kg*/
            let q = quantity / 1000;
            setResult(q)
        } else/*if oz*/
        {
            let q = quantity * 0.035274;
            setResult(q)
        }

    }

    if (to === '2') { //lbs
        if (from === '5') {/*if g*/
            let q = quantity /0.00220462
            setResult(q)
        } else if (from === '6') {/*if lbs*/
            let q = quantity;
            setResult(q)
        } else if (from === '7') {/*if kg*/
            let q = quantity / 2.2046;
            setResult(q)
        } else/*if oz*/
        {
            let q = 16*quantity;
            setResult(q)
        }

    }

    if (to === '3') { //kg
        if (from === '5') {/*if g*/
            let q = quantity * 1000
            setResult(q)
        } else if (from === '6') {/*if lbs*/
            let q = quantity * 2.2046;
            setResult(q)
        } else if (from === '7') {/*if kg*/
            let q = quantity;
            setResult(q)
        } else/*if oz*/
        {
            let q = quantity / 0.0283495;
            setResult(q)
        }

    }

    if (to === '4') { //oz
        if (from === '5') {/*if g*/
            let q = quantity * 28.349523
            setResult(q)
        } else if (from === '6') {/*if lbs*/
            let q = quantity / 16;
            setResult(q)
        } else if (from === '7') {/*if kg*/
            let q = quantity / 35.39621;
            setResult(q)
        } else/*if oz*/
        {
            let q = quantity;
            setResult(q)
        }

    }
}


 const handleInput = (e)=>{
     if(e.target.name === 'quantity'){
         if(!e.target.value){
             setErrors({...errors,quantity:'Quantity is required'})
         }else {
             delete errors['quantity']
         }
         setQuantity(e.target.value)
         setResult('')
     }

     if(e.target.name === 'to'){
         setTo(e.target.value)
         if(!e.target.value){
             setErrors({...errors,to:'To is required'})
         }else {
             delete errors['to']
         }
     }

     if(e.target.name === 'from'){
         setFrom(e.target.value)
         if(!e.target.value){
             setErrors({...errors,from:'From is required'})
         }else {
             delete errors['from']
         }
     }
 }


  
	return (
		<div className="container">
			<div className="row candlecalculator-sec">
				<div className="col-md-12 ">
					<div className="card">
						<div className="card-header">
							<h4>Mass Convertor</h4>
						</div>
						<div className="card-body">
							<div className="row calculater-convertor">
								<div className="col-md-8 calculater-column">
									<div className="row">
                                    <Form onSubmit={Calculate} >
											<div className="col-md-12">
                                   <label htmlFor="type">Quantity <span className="text-danger">*</span></label>
								   <input  type="number" min="0" name ="quantity" className="form-control"  id="a1" onChange={handleInput} />
                                {errors['quantity']  && <div className="text-danger text-left"> {errors['quantity']}</div>}
                                      
                           </div>
                                       <div className="row">
                                           <div className="col-md-6">
                                               <label htmlFor="type">From <span className="text-danger">*</span></label>
                                               <select  name ="to" className="form-control form-select"  id="aa" onChange={handleInput} >
                                                   <option value="">-Select Unit-</option>
                                                   <option value="1">gram</option>
                                                   <option value="2">lbs</option>
                                                   <option value="3">kg</option>
                                                   <option value="4">oz</option>
                                               </select>
                                               {errors['to']  && <div className="text-danger text-left"> {errors['to']}</div>}
                                           </div>
                                           <div className="col-md-6">
                                               <label htmlFor="type">To<span className="text-danger">*</span></label>
                                               <select  name ="from"   className="form-control form-select"   id="bb" onChange={handleInput}>
                                                   <option value="">-Select Unit-</option>
                                                   <option value="5">gram</option>
                                                   <option value="6">lbs</option>
                                                   <option value="7">kg</option>
                                                   <option value="8">oz</option>
                                               </select>
                                               {errors['from']  && <div className="text-danger text-left"> {errors['from']}</div>}
                                           </div>


                                       </div>
                                        <hr/>

                                        <label htmlFor="type"> Result:</label>  {result && parseFloat(result).toFixed(2)}



						 
						  {/*{result!='' && (<div className="col-md-12">*/}
                          {/*         <label htmlFor="type"> Result<span className="text-danger"></span></label>*/}
							{/*	   <input  type="text" name="Result" className="form-control"  id="a1" value={*/}

							{/*		   }/>*/}
							{/*	               */}
                          {/* </div>*/}
						  {/*)}*/}
						   <div className="calculate-btn" style={{textAlign:'center',marginTop:10}}>
						   <button type="submit" className="btn btn-outline-danger">Calculate</button>&nbsp;

						   </div>
										{/* <div className="col-md-5">
											<div className="form-group mb-2">
												<input type="email" className="form-control" name="email" id="email" placeholder="Enter email" />
											</div>
										</div> */}
										{/* <div className="col-md-1">
											<div className="form-group mb-2">
												<span><h3>=</h3></span>
											</div>
										</div> */}
										{/* <div className="col-md-5">
											<div className="form-group mb-2">
												<input type="email" className="form-control" name="email" id="email" placeholder="Enter email" />
											</div>
                                        </div> */}
                                         </Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default MassConvertor;