import React, { useState, useEffect } from 'react';
import {Link, useLocation, useParams,useNavigate} from 'react-router-dom';
import {Code} from "react-content-loader";
import axios from 'axios'
import BackArrow from '../../../../assets/img/arrow-back.gif'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import FormulaPopupModal from "../search/includes/FormulaPopupModal";
function RawMaterialsDetails() {
	const params = useParams()
	const location = useLocation();


	const redirect = useNavigate();
	const [show, setShow] = useState(false)
	const [loading, setLoading] = useState(false);
	const [fragrance, setFragrance] = useState('');
	const [rawMaterials, setRawMaterials] = useState('');
	const [organoLatpic, setOrganoLatpic] = useState('');
	const [fragOccurence, setfragOccurence] = useState('');
	const [fragSynonyms, setfragSynonyms] = useState('');
	const [fragSupplier, setFragSupplier] = useState('');
	const [fragCosmetics, setCosmetics] = useState('');
	const [fragArticlesData, setArticles] = useState('');
	const [fragNotesData, setNotes] = useState('');
	const [fragOthersData, setOthers] = useState('');
	const [physicalProperties,setPhysicalProperties] = useState('')
	const [safetyInUse,setSafetyInUse] = useState('')
	const [safetyRefs,setSafetyRefs] = useState('')
	const [references,setReferences] = useState('')
	const [uses,setUses] = useState('')
	const [blenders,setBlenders] = useState('')
	const [safeties,setSafeties] = useState('')
	const [catDescription,setCatDescription] = useState('')
	const [patentInf,setPatentInfo] = useState('')
	const [onClickSuppSponLink,setOnClickSuppSponLink] = useState([])
	const [isActive, setActive] = useState("false");
	const restrictUrl = 'thegoodscentscompany';
	let lastScrollTop = 0;
	const webPrices = [
		{qty: '4ml', name: "size_4ml"},
		{qty: '15ml', name: "size_15ml"},
		{qty: '30ml', name: "size_30ml"},
		{qty: '80ml', name: "size_80ml"},
		{qty: '250g (0.55 lb)', name: "size_250g"},
		{qty: '500g (1.10 lb)', name: "size_500g"},
		{qty: '1kg (2.20 lb)', name: "size_1kg"},
	]
	const getData =(maintabid,ID)=> {
		let url;
		url=`${window.API_URL}Fragrances/getCategoriesDetails/rawMaterialsDetails/${maintabid}/${ID}?physicalProp=true&${location.search.replace('?','')}`;
		setLoading(false);
		axios.get(url).then(response => {
			setLoading(true);
			setRawMaterials(response.data.allTabsData);

			setPhysicalProperties(response.data.physicalProperties)
			setCatDescription(response.data.catDescription)
			setOnClickSuppSponLink(response.data.onClickSuppSponLink)

		}).catch((e) => {
		});

	}
	useEffect(() => {
	getAllData(params.id, params.detail_id)
	}, []);

	const handleToggle = () => {
		setActive(!isActive);
	};

	const getAllData = (mainTabId,ID)=>{
		getData(mainTabId,ID);
		setTimeout(()=>{
			getFragranceOccurrences(mainTabId,ID)
			getCosmeticArticle(mainTabId,ID)
			getOthersNotes(mainTabId,ID)
			getSafetyUseRef(mainTabId,ID)
			getReferenceSynonym(mainTabId,ID)
			getBlenderSafety(mainTabId,ID)
		},10)

	}


	const getBlenderSafety= (maintabid,ID)=>{
		let url;
		url=`${window.API_URL}Fragrances/getCategoriesDetails/rawMaterialsDetails/${maintabid}/${ID}?blenderSafety=true&${location.search.replace('?','')}`;
		axios.get(url).then(response => {
			setBlenders(response.data.blenders)
			setSafeties(response.data.safeties)
		}).catch((e) => {
		});
	}
	const getReferenceSynonym = (maintabid,ID)=>{
		let url;
		url=`${window.API_URL}Fragrances/getCategoriesDetails/rawMaterialsDetails/${maintabid}/${ID}?referenceSynonym=true&${location.search.replace('?','')}`;
		axios.get(url).then(response => {
			setfragSynonyms(response.data.fragSynonymsData);
			setReferences(response.data.references)
			setPatentInfo(response.data.patentInfo)
		}).catch((e) => {
		});
	}

	const getSafetyUseRef = (maintabid,ID)=>{
		let url;
		url=`${window.API_URL}Fragrances/getCategoriesDetails/rawMaterialsDetails/${maintabid}/${ID}?safetyUseRef=true&${location.search.replace('?','')}`;
		axios.get(url).then(response => {
			setSafetyInUse(response.data.safetInUse)
			setSafetyRefs(response.data.safetyRefs)
			setUses(response.data.uses)
		}).catch((e) => {
		});
	}

	const getOthersNotes = (maintabid,ID)=>{
		let url;
		url=`${window.API_URL}Fragrances/getCategoriesDetails/rawMaterialsDetails/${maintabid}/${ID}?othersNotes=true&${location.search.replace('?','')}`;
		axios.get(url).then(response => {
			setNotes(response.data.fragNotesData);
			setOthers(response.data.fragOthersData);
		}).catch((e) => {
		});
	}
	const getCosmeticArticle = (maintabid,ID)=>{
		let url;
		url=`${window.API_URL}Fragrances/getCategoriesDetails/rawMaterialsDetails/${maintabid}/${ID}?cosmeticArticle=true&${location.search.replace('?','')}`;
		axios.get(url).then(response => {
			setCosmetics(response.data.fragCosmeticsData);
			setArticles(response.data.fragArticlesData);
		}).catch((e) => {
		});
	}
	const getFragranceOccurrences = (maintabid,ID)=>{
		let url;
		url=`${window.API_URL}Fragrances/getCategoriesDetails/rawMaterialsDetails/${maintabid}/${ID}?fragranceOccurrences=true&${location.search.replace('?','')}`;
		axios.get(url).then(response => {
			setFragrance(response.data.fragrance);
			setfragOccurence(response.data.fragOccurenceData);
			setFragSupplier(response.data.fragSupplierData);
			setOrganoLatpic(response.data.organoLatpicData);
		}).catch((e) => {
		});

	}


	const handleRestrictUrl = (urlLink)=>{
		axios.post(window.API_URL+'find-duplicate-link',{urlLink})
			.then(({data})=>{
				setRawMaterials('')
				setOrganoLatpic('')
				setfragOccurence('')
				setFragrance('')
				setfragSynonyms('')
				setFragSupplier('')
				setCosmetics('')
				setArticles('')
				setNotes('')
				setOthers('')
				setPhysicalProperties('')
				setSafetyInUse('')
				setSafetyRefs('')
				setReferences('')
				setUses('')
				setBlenders('')
				setSafeties('')
				setCatDescription('')
					const path = `/Categories/${data.mainTabeName.replace(/\s/g, '_')}/${data.maintabid}/RawMaterialsDetails/${data.ID}`
					redirect(path,
						{
							state:{
								maintabid:data.maintabid,
								rawMaterialName:data.mainTabeName.replace(/\s/g, '_'),
								ID:data.ID
							}
						})
						// getData(data.maintabid,data.ID)
				getAllData(data.maintabid,data.ID)
			})
	}
	
	let organoLatpicDetails = organoLatpic && organoLatpic.map((item) => {
		return (
			<div className="row">
				{item.links.length > 0 ? (<ul className="list-group list-group-horizontal">
						{item.links.map(link=><li style={{marginLeft:11}} >
							<Link
								to={'/Categories/'+link?.links_id?.mainTabeName.replace(' ','_')+'/'+link?.links_id?.maintabid+'/details/'+link?.links_id?.ID}>
								{link?.links_id?.inner_link_heading}
							</Link></li>)}
				</ul>
				):(
					<>
					{item.keys=="" &&
					<span>
						<strong>
							{item.value}
						</strong>
					</span>
					}
					{item.keys!=="" &&
					<span>
						<strong>
							{item.keys}:
						</strong> {item.value}
					</span>
					}
				</>)}

				
			</div>
		);
	});

	let fragOccurenceDetails = fragOccurence && fragOccurence.map((item) => {
		return (
			<div className="row">
				{item.keys=="" &&
					<span>
						<strong>
							{item.value}
						</strong>
					</span>
				}
				{item.keys!=="" &&
					<span>
						<strong>
							{item.keys}:
						</strong> {item.value}
					</span>
				}
				
			</div>
		);
	});

	let fragSynonymsDetails = fragSynonyms&& fragSynonyms.map((item) => {
		return (
			<div className="row">
				{item.keys=="" &&
					<span>
						<strong>
							{item.value}
						</strong>
					</span>
				}
				{item.keys!=="" &&
					<span>
						<strong>
							{item.keys}: 
						</strong> {item.value}
					</span>
				}
				
			</div>
		);
	});

	let fragSupplierDetails = fragSupplier && fragSupplier.map((item) => {
		return (
			<div className="row">
				{item.type &&
				<label htmlFor="">
					{item.link ?
					<a href={item.link} target="_blank">{item.text}</a> : item.text}
				</label>}
				{!item.type &&
				<label htmlFor="" style={{paddingLeft:"30px"}} > {item.link ?
					<a href={item.link} target="_blank">{item.text}</a> : item.text} </label>}

				{/*{item.type &&*/}
				{/*<label htmlFor="">*/}
				{/*	{ item.text}*/}
				{/*</label>}*/}
				{/*{!item.type &&*/}
				{/*<label htmlFor="" style={{paddingLeft:"30px"}} >  {item.text} </label>}*/}
			</div>
		);
	});

	let fragCosmeticsDetails = fragCosmetics && fragCosmetics.map((item) => {
		return (
			<div className="row">
				{item.keys=="" &&
					<span>
						<strong>
							{item.value}
						</strong>
					</span>
				}
				{item.keys!=="" &&
					<span>
						<strong>
							{item.keys}: 
						</strong> {item.value}
					</span>
				}
				
			</div>
		);
	});

	let fragArticlesDetails = fragArticlesData && fragArticlesData.map((item) => {
		return (
			<div className="row">
				{item.keys=="" &&
					<span>
						<strong>
							{item.value}
						</strong>
					</span>
				}
				{item.keys!=="" &&
					<span>
						<strong>
							{item.keys}:
						</strong> {item.link &&
					(item?.link?.includes(restrictUrl) ?
						<span onClick={()=>handleRestrictUrl(item.link)} role="button" className="text-primary">{item.value}</span>
						: <a href={item.link} target="_blank" className="text-decoration-none">{item.value}</a>
					) }

						{/* {item.value} */}
					</span>
				}
				
			</div>
		);
	});

	let fragNotesDetails = fragNotesData && fragNotesData.map((item) => {
		return (
			<div className="row">
				{item.keys=="" &&
					<span>
						<strong>
							{item.value}
						</strong>
					</span>
				}
				{item.keys!=="" &&
					<span>
						<strong>
							{item.keys}:
						</strong> 
						{item.value}
					</span>
				}
				
			</div>
		);
	});

	let fragOthersDetails = fragOthersData && fragOthersData.map((item) => {
		return (
			<div className="row">
				{item.keys=="" &&	
								
					<span>
						<div className="col-md-6">

								{/* /Categories/:name/:id/RawMaterialsDetails/:id/OnClickImage/:id */}
								{ item.link!=''	&&  <Link to={`/Categories/${item.alltabs_inner_pages_ID}/${item.alltabs_inner_pages_ID}/RawMaterialsDetails/${item.alltabs_inner_pages_ID}/Typical_GC_Analysis/${item.alltabs_inner_pages_ID}`}
								// {/* { desc.link!=''	&&  <Link to={`/OnClickImage/${desc.alltabs_inner_pages_ID}`}  */}
									state={{ ID: `${item.alltabs_inner_pages_ID}`}}>									
								{item.value}									
								</Link>}
						</div>
							<strong>
							{item.value}:

						</strong>
						{item.link && <a href={item.link} target="_blank" className="text-decoration-none">{item.value}</a>}
					</span>
				}
				{item.keys!=="" &&
					<span>
						<strong>
							{item.keys}:
						</strong>

						{(item?.link?.includes(restrictUrl) ?
						<span onClick={()=>handleRestrictUrl(item.link)} role="button" className="text-primary">{item.value}</span>
						: <a href={item.link} target="_blank" className="text-decoration-none">{item.value}</a>
					) }
						{/*<a href={item.link} target="_blank" className="text-decoration-none">{item.value}</a>*/}
						{/* {item.value} */}
					</span>
				}
				
			</div>
		);
	});

	const rawProps = [
		{name:"Physical Properties",id:"#physical_prop"},
		{name:"US / EU",id:"#patent_info"},
		{name:"Organoleptic Properties",id:"#organoleptic"},
		{name:"Fragrance occurrences",id:"#occurrences"},
		{name:"Suppliers",id:"#suppliers"},
		{name:"Cosmetics",id:"#cosmetics"},
		{name:"Articles",id:"#articles"},
		{name:"Others",id:"#others"},
		{name:"Notes",id:"#notes"},
		{name:"Safety In Use",id:"#use"},
		{name:"Safety References",id:"#s_references"},
		{name:"References",id:"#references"},
		{name:"Synonyms",id:"#synonyms"},
		{name:"Potential Uses",id:"#potential"},
		{name:"Blenders",id:"#blenders"},
		{name:"Safety Information",id:"#information"},
	]

	const handleModal =()=>{
		setShow(!show)
	}

	return (
		<div className="container" style={{fontFamily:"",fontWeight:"400",fontSize:"17px"}}>
			<style>{css}</style>

			{fragrance?.fragrance_id && <FormulaPopupModal
				show={show}
				handleModal={handleModal}
				fragId={fragrance.fragrance_id}
			/>}
			<div className="row justify-content-center container11" style={{background:"#ab4d50", position: "relative"}}>
				<div className="col-md-12 text-center" style={{background:"rgb(161 161 161)",borderBottom: "3px solid rgb(52 52 52)"}}>
					<h3 className="text-black" >
						{rawMaterials && rawMaterials?.inner_link_heading}
					</h3>

				</div>
				<div className="col-lg-2 col-md-3 col-sm-4">
					<button className='rawtoggle btn btn-dark' onClick={handleToggle}><i className='fa fa-bars'></i></button>
					<div className={`card mt-3 ${isActive ? "rawpoints" : " "}`} id="sticky" >
						<div className="card-body p-0" >
							<div className="list-group">
								{rawProps && rawProps.map(raw=>(
									<a key={raw.name} className="list-group-item list-group-item-action "
																  data-toggle="list" href={raw.id} role="tab" aria-controls={raw.name}>
										{raw.name}</a>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-10 col-md-9 col-sm-8 category-details">
					<div className="row">
						<div className="col-md-10 col-sm-10 col-10 backbuttontop-cards">

							<div className=" card mt-3">

								<div className="card-body">
									<div className="card-title text-center">
										<h3>
											<span>
												{(rawMaterials?.frag?.fw_raw?.displayOnFu == '1'?
													<a href="#samples">
														<button className="btn btn-outline-danger bnt-sm pull-left">Sample</button>
													</a> :'')}
											</span>
											<label htmlFor="">{rawMaterials && rawMaterials?.inner_link_heading}</label>
										</h3>
									</div>
									<div className="row">
										{catDescription && catDescription.map(desc => {
											if (desc.img_link) {
												return (
													<>
														<div className="col-md-6 ">
															<strong>{desc.keys}:</strong> {desc.value.replace('3D/inchi','')}
														</div>

														<div className="col-md-6">
															{/*{<Link to={`/Categories/${desc.alltabs_inner_pages_ID}/${desc.alltabs_inner_pages_ID}/RawMaterialsDetails/${desc.alltabs_inner_pages_ID}/OnClickImage/${desc.alltabs_inner_pages_ID}`}*/}
															{/*							 state={{ ...location.state,back:location.pathname}}>3D/inchi</Link>}*/}
															{<Link
																to={`/Categories/${params.name}/${params.id}/RawMaterialsDetails/${params.detail_id}/${rawMaterials?.inner_link_heading}`}
															>
																3D/inchi
															</Link>}
															<br/>
															<img src={window.ASSET_URL+'img/formula-img/'+desc.img_link.split("/").pop()} alt=""/>
														</div>
													</>
												)
											}else {
												return (
													<div className="col-md-6">
														<strong>
															{desc?.keys?.includes('FDA UNII') ?
															<a href="https://www.fda.gov/industry/fda-data-standards-advisory-board/fdas-global-substance-registration-system" target="_blank">{desc.keys}</a>
															: desc?.keys?.includes('ECHA') ?
															<a href="https://echa.europa.eu/web/guest/information-on-chemicals/ec-inventory" target="_blank">{desc.keys}</a>
															: desc.keys}:</strong>
														{desc?.value?.includes('Search') ?
															<a href={"https://precision.fda.gov/uniisearch/srs?search="+catDescription.find(des=>des.keys === 'CAS Number')?.value} target="_blank">{desc.value}</a>
															: desc.value
														}
													</div>)
											}
										})
										}
										{!Array.isArray(catDescription) || !catDescription.length && <label>Not Found</label>}
										{!catDescription && <Code/>}

									</div>
								</div>
							</div>
						</div>
						<div className="col-md-2 col-sm-2 col-2 backbutton-space backbuttontop">
							<Link
								to={`/Categories/${params.name}/${params.id}`}
								className="btn backbutton btn-dark pull-right bg-white form-control  mt-3"
								state={{...location.state}}
								style={{backgroundImage:`url(${BackArrow})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'left',color:"black"}}>
								<span style={{paddingLeft:"20px"}}>Back</span>
							</Link>
						</div>
						<div id="physical_prop" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Physical Properties:</h3>
								</div>


								<div className="card-body ">
									{physicalProperties && <table className="table table-borderless">
										<tbody>
										{physicalProperties.map(property =><tr key={property.physical_propID}>
											<td style={{textAlign:"right",fontWeight:"bold",width:"40%"}}>{property.keys && property.keys+':' }</td>
											<td style={{textAlign:"left"}}>{property.value}</td>
										</tr>)}

										</tbody>
									</table>}
									{!Array.isArray(physicalProperties) || !physicalProperties.length && <label>Not Found</label>}
									{!physicalProperties && <Code/>}
								</div>
							</div>
						</div>
						<div id="patent_info" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>US / EU / FDA / JECFA / FEMA / FLAVIS / Scholar / Patent Information:</h3>
								</div>


								<div className="card-body ">
									{patentInf && <table className="table table-borderless">
										<tbody>
										{patentInf.map(info =><tr key={info.id}>
											<td style={{textAlign:"right",fontWeight:"bold",width:"40%"}}>
												{info.key_link ? <a target="_blank" href={info.key_link}>{info.key}</a> : info.key }
											</td>
											<td style={{textAlign:"left"}}>
												{info.value_link1 ? <a target="_blank" href={info.value_link1}>{info.link1_txt}</a> : info.link1_txt }
											</td>
											{/*<td style={{textAlign:"left"}}>{info.value}</td>*/}
										</tr>)}

										</tbody>
									</table>}
									{!Array.isArray(patentInf) || !patentInf.length && <label>Not Found</label>}
									{!patentInf && <Code/>}
								</div>
							</div>
						</div>
						<div id="organoleptic" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Organoleptic Properties:</h3>
								</div>
								<div className="card-body ">
									{organoLatpicDetails}
									{!organoLatpicDetails && <Code/>}
									{!Array.isArray(organoLatpicDetails) || !organoLatpicDetails.length && <label>Not Found</label>}
								</div>
							</div>
						</div>
						<div id="occurrences" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3 id="fragrance_occurrences">Fragrance occurrences:
										<OverlayTrigger placement="right"
														overlay={
															<Tooltip id="tooltip-disabled"  >
																The list of natural occurrences has links to the web data sheets "data page".
																Links to PubMed articles "PbMd".
																You can click the name (where available) to see a GC analysis.
																If there is an "S" on the right side of the name you can click this to see botanical species pictures from Google.
																This list is not complete and like this web data sheet, is a work in progress.
															</Tooltip>}>
  									<span className="d-inline-block">
									<small role="button" style={{color:"#1115ad"}}>
									  Note
									</small>
  									</span>
										</OverlayTrigger>
									</h3>
								</div>
								<div className="card-body ">
									{fragOccurenceDetails}
									{!fragOccurenceDetails && <Code/>}
									{!Array.isArray(fragOccurenceDetails) || !fragOccurenceDetails.length && <label>Not Found</label>}
								</div>
							</div>
						</div>
						<div id="suppliers" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Suppliers:</h3>
								</div>
								<div className="card-body ">
									{rawMaterials?.mainTabeName === 'All Ingredients' || rawMaterials?.mainTabeName === 'Fragrances' ? (
										<>
											{(fragrance === null && <a href="http://www.afi-usa.com/shop/" target="_blank">afi-usa</a>)}
											{(fragrance && typeof fragrance === 'object' && <a onClick={handleModal} role="button" className="  ">Fragrances</a>)}
										</>
									):(
										<>
											{fragSupplierDetails}
											{!fragSupplierDetails && <Code/>}
											{!Array.isArray(fragSupplierDetails) || !fragSupplierDetails.length && <label>Not Found</label>}
										</>
									)

									}


								</div>
							</div>
						</div>
						<div id="cosmetics" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Cosmetics:</h3>
								</div>
								<div className="card-body ">

									{fragCosmeticsDetails}

									{!fragCosmeticsDetails && <Code/>}
									{!Array.isArray(fragCosmeticsDetails) || !fragCosmeticsDetails.length && <label>Not Found</label>}
								</div>
							</div>
						</div>
						<div id="articles" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3 id="articles">Articles:</h3>
								</div>
								<div className="card-body ">
									{fragArticlesDetails}
									{!fragArticlesDetails && <Code/>}
									{!Array.isArray(fragArticlesDetails) || !fragArticlesDetails.length && <label>Not Found</label>}
								</div>
							</div>
						</div>
						<div id="others" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Others:</h3>
								</div>
								<div className="card-body ">
									{fragOthersDetails}
									{!fragOthersDetails && <Code/>}
									{!Array.isArray(fragOthersDetails) || !fragOthersDetails.length && <label>Not Found</label>}
								</div>
							</div>
						</div>
						<div id="notes" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Notes:</h3>
								</div>
								<div className="card-body">
									{fragNotesDetails}

									{!fragNotesDetails && <Code/>}
									{!Array.isArray(fragNotesDetails) || !fragNotesDetails.length && <label>Not Found</label>}
								</div>
							</div>
						</div>

						<div id="use" className="col-md-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Safety In Use:</h3>
								</div>
								<div className="card-body">
									<table className="table table-borderless">
										<tbody>
										{safetyInUse && safetyInUse.map(safety =>  <tr key={safety.frag_safety_ref_ID}>
											<td style={{fontWeight:"bold"}}>
												{safety.keys && safety.keys+':' }
												{/*{safety.value && safety.value}*/}
												{/*{safety.value1 && safety.value1}*/}
											</td>
											<td style={{textAlign:"left"}}>{safety.value}</td>
											<td style={{textAlign:"left"}}>{safety.value1}</td>
										</tr>)}

										</tbody>
									</table>
									{!safetyInUse && <Code/>}
									{!Array.isArray(safetyInUse) || !safetyInUse.length && <label>Not Found</label>}
								</div>
							</div>
						</div>

						<div id="s_references" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Safety References:</h3>
								</div>
								<div className="card-body">
									<table className="table table-borderless text-left">
										<tbody>
										{safetyRefs && safetyRefs.map(safety => <tr key={safety.frag_safety_ref_ID}>
											<td style={{fontWeight:"bold"}}>
												{safety.keys && safety.keys+':' }&nbsp;
												{/*{safety.link && <a href={safety.link}>{safety.value}</a> }*/}


												{safety.link && (safety?.link?.includes(restrictUrl) ?
														<span onClick={()=>handleRestrictUrl(safety.link)} role="button" className="text-primary">{safety.value}</span>
														: <a href={safety.link} target="_blank" className="text-decoration-none">{safety.value}</a>
												) }
												{!safety.link && safety.value }
											</td>
										</tr>)}

										</tbody>
									</table>
									{!safetyRefs && <Code/>}
									{!Array.isArray(safetyRefs) || !safetyRefs.length && <label>Not Found</label>}
								</div>
							</div>
						</div>

						<div id="references" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>References:</h3>
								</div>
								<div className="card-body">
									<table className="table table-borderless text-left">
										<tbody>
										{references && references.map(reference => <tr key={reference.frag_safety_ref_ID}>
											<td style={{fontWeight:"bold"}}>
												{reference.keys && reference.keys+':' }&nbsp;
												{/*{reference.link && <a href={reference.link}>{reference.value}</a> }*/}

												{reference.link && (reference?.link?.includes(restrictUrl) ?
														<span onClick={()=>handleRestrictUrl(reference.link)} role="button" className="text-primary">{reference.value}</span>
														: <a href={reference.link} target="_blank" className="text-decoration-none">{reference.value}</a>
												) }


												{!reference.link && reference.value }
											</td>
										</tr>)}

										</tbody>
									</table>
									{!references && <Code/>}
									{!Array.isArray(references) || !references.length && <label>Not Found</label>}
								</div>
							</div>
						</div>

						<div id="synonyms" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Synonyms:</h3>
								</div>
								<div className="card-body">
									{fragSynonymsDetails}
									{!fragSynonymsDetails && <Code/>}
									{!Array.isArray(fragSynonymsDetails) || !fragSynonymsDetails.length && <label>Not Found</label>}
								</div>
							</div>
						</div>

						<div id="potential" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3>Potential Uses:</h3>
								</div>
								<div className="card-body">
									{uses && uses.map(use =>
											<p>
												<span>{use.key}</span>
												&nbsp;&nbsp;
												<span>
									{use.link && (use?.link?.includes(restrictUrl) ?
										<span onClick={()=>handleRestrictUrl(use.link)} role="button" className="text-primary">{use.value}</span>
										: <a href={use.link}>{use.value}</a>) }
													{!use.link && use.value}
								</span>
											</p>
									)}
									{!uses && <Code/>}
									{!Array.isArray(uses) || !uses.length && <label>Not Found</label>}
								</div>
							</div>
						</div>

						<div id="blenders" className="col-md-10 col-sm-10 mt-3">
							<div className="card">
								<div className="card-header">
									<h3> Potential Blenders and core components &nbsp;
										<span>
								<OverlayTrigger placement="right"
												overlay={
													<Tooltip id="tooltip-disabled"  >
														The list of potential blenders has links on the name (where available) to see web data sheets. 	<br/>
														The "FL" - used in flavors, "FR"- used in fragrance, <br/>
														"CS" - used in cosmetics and "FL/FR" - used in flavor and fragrance - is an attempt to provide a visual aide of potential blenders for the various disciplines. <br/>
														You may find some of these to be a real stretch or not even considerable, <br/>
														but hopefully this can provide the flavorist or perfumer a new doorway to her or his creativity. <br/>
														This list is not complete and like this web data sheet, is a work in progress.
													</Tooltip>}>
  									<span className="d-inline-block">
									<small role="button" style={{color:"#1115ad"}}>
									  Note
									</small>
  									</span>
								</OverlayTrigger>
							</span>
									</h3>
								</div>
								<div className="card-body">
									<table className="table table-borderless blender">
										<tbody>
										{blenders && blenders.map(blender => {
												if (blender?.key?.includes('groupHeading')){
													return(
														<tr style={{background:"#e7ffff",textAlign:"center"}}>
															<td><h3 >{blender.value}</h3></td>
														</tr>
													)
												}else if(blender?.key?.includes('parentHeading')){
													return(
														<tr style={{background:"#ffffd6",textAlign:"center"}}>
															<td><h3>{blender.value}</h3></td>
														</tr>
													)
												}else if(blender?.key?.includes('material')){
													return(
														<tr >
															<td>

																{blender.fl_fr}&nbsp;<span className="material"><label htmlFor="" >{blender.pre_text}</label>

																{blender.link && (blender?.link?.includes(restrictUrl) ?
																	<span onClick={()=>handleRestrictUrl(blender.link)} role="button" className="text-primary">{blender.value}</span>
																	: <a href={blender.link}>{blender.value}</a>) }

																{/*<a href={blender.link} >{blender.value}</a>*/}

													</span>

															</td>
														</tr>
													)
												}else {
													return(
														<tr >
															<td>

														<p>
															{blender.fl_fr}&nbsp;<span className="material">
																<br/>

															{blender.key && blender.key+':' }	{blender.link && (blender?.link?.includes(restrictUrl) ?
																<span onClick={()=>handleRestrictUrl(blender.link)} role="button" className="text-primary"> <label htmlFor="" >{blender.pre_text}</label> {blender.value}</span>
																: <a href={blender.link} target="_blank"> <label htmlFor="" >{blender.pre_text}</label> {blender.value}</a>) }

															{/*<a href={blender.link} >{blender.value}</a>*/}

													</span>
														</p>

															</td>
														</tr>
													)
												}
											}
										)}
										</tbody>
									</table>

									{!blenders && <Code/>}
									{!Array.isArray(blenders) || !blenders.length && <label>Not Found</label>}
								</div>
							</div>
						</div>
						<div id="information" className="col-md-10 col-sm-10 mt-3 mb-3">
							<div className="card">
								<div className="card-header">
									<h3>Safety Information:</h3>

								</div>
								<div className="card-body">
									<table className="table table-borderless ">
										<tbody>
										{safeties && safeties.map(safety => {
												if (safety.key === 'heading'){
													return(
														<tr >
															<td ><strong>{safety.value}
																{/*{safety.link_name && <a href={safety.link_name}>{safety.link}</a> }*/}
																{safety.link_name && (safety?.link_name?.includes(restrictUrl) ?
																	<span onClick={()=>handleRestrictUrl(safety.link_name)} role="button" className="text-primary">{safety.link}</span>
																	: <a href={safety.link_name}>{safety.link}</a>)
																}
															</strong></td>
														</tr>
													)
												}else if(safety.key === 'desciption'){
													return(
														<tr >
															<td style={{padding:"0px 10px"}}><div dangerouslySetInnerHTML={{ __html: safety.value }} />
																{/*<div dangerouslySetInnerHTML={{ __html: this.htmlDecode(safety.value) }} />*/}
															</td>
														</tr>
													)
												}
											}
										)}
										</tbody>
									</table>
									{!safeties && <Code/>}
									{!Array.isArray(safeties) || !safeties.length && <label>Not Found</label>}
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
			<div className="row justify-content-center mt-3" id="samples" >
				{(rawMaterials?.frag?.fw_raw?.displayOnFu == '1'? (
					webPrices.map((web,key)=> <div className="col-md-2 mt-2" key={key}>
						<div className="card text-white" style={{background:"#1f3e5a"}}>
							<div className="card-body text-center">
								<lable style={{fontWeight:"bold"}}>{web.qty}</lable>
								<p>$ {rawMaterials?.frag?.fw_raw[web.name]} (USD)</p>
							</div>
						</div>
					</div>)
				):'')}
			</div>
		</div>
	);
}
export default RawMaterialsDetails;

const css = `
	.container11 .card-header{background: #c42625;
    color: white;}
    .container a{
    text-decoration:none;
    }
    .container11 .blender label{
    
   color:#006699;
    }
    .container .blender .material {
    font-size: 21px;
    font-weight:bold;
}
.container11 .category-details .card-header h3 {
    margin: 0;
    font-size: 25px;
}
#sticky{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.backbutton-space .btn.backbutton {
    max-width: 100px;
}
@media (max-width: 991px){
	.container11 .category-details .card-header h3 {
        font-size: 19px;
    }
}
@media (max-width: 767px){
	.backbutton-space {
		padding-left: 0;
	}
	.backbutton-space .btn.backbutton span {
		display: none;
	}
	.backbutton-space .btn.backbutton {
		padding: 15px 0px;
		background-position: center !important;
		max-width: 50px;
	}
	.container11 .category-details .card-header h3 {
		font-size: 17px;
		line-height: 1;
	}
}
	`