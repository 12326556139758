import Loader from "react-js-loader";
import './FragranceDetails.css';
import { Link, Routes, Route } from "react-router-dom";
import OdorIndexList from "../Categories/odor-index/OdorIndexList";
import FlavorIndexList from "../Categories/flavor-index/FlavorIndexList";
import CosmeticFunction from "../Categories/cosmetic-function/CosmeticFunction";
const extractContent= s => {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.innerText;
  }
function FragranceDetailGeneral({catDetails, loading, ID}) {
    let Details = '';
    if(!loading){
        Details = <Loader type="spinner-cub" bgColor={"#c42625"} title={"Loading..."} color={'#c42625'} size={100} />;
    }else{        
        Details = catDetails.map((item) => {
            // return(<Dropdown.Item eventKey={item.id}>{item.TabName}</Dropdown.Item>);
            return (
                    <div className="col-md-6 mb-3" key={item.ID}>
                        <div className="inner-ingredients-info-box" style={{height:"100%"}}>

                            <h5>


                                <span className={item.numerical_value && 'pull-left'}>
                                     <span className="text-black-50">{item.chemical_value} </span>
                                    <Link

                                        to={{ pathname: `/Categories/${item.mainTabeName.replace(/\s/g, '_')}/${item.maintabid}/RawMaterialsDetails/${item.ID}`}}
                                        state={{ maintabid: `${item.maintabid}`, ID: `${item.ID}`, rawMaterialName:`${item.inner_link_heading}` }}>
                                    {item.inner_link_heading}</Link>
                                </span>
                                {item.numerical_value && <span className="pull-right">{item.numerical_value}</span>}
                            </h5>
                            {item.description!='' &&
                            <h5><a href="#!">{extractContent(item.description)}</a></h5>
                            }
                            <span>
                            <p>
                            {item.cas!='' && item.mw > 0 &&
                            <div>
                                M/W: {item.mw}
                            </div>
                            }
                                {item.cas!='' && item.cas &&
                                <div>
                                    CAS:{item.cas}
                                </div>
                                }
                                {item.ec!='' && item.ec &&
                                <div>
                                    EC: {item.ec}
                                </div>
                                }

                                {item.fema!='' && item.fema &&
                                <div>
                                    FEMA: {item.fema}
                                </div>
                                }
                                {item.jecfa!='' && item.jecfa &&
                                <div>
                                    JECFA: {item.jecfa}
                                </div>
                                }
                                {item.flavis!='' && item.flavis &&
                                <div>
                                    FLAVIS: {item.flavis}
                                </div>
                                }
                                {item.use_data!='' && item.use_data &&
                                <div>
                                    Use(s): {item.use_data}
                                </div>
                                }

                            </p>
                        </span>
                        </div>
                    </div>
            );
        });
    }
    let orderIndex="";
		if (!loading) {
			orderIndex = 'loading...';
		} else {
			orderIndex = catDetails.map((item) => {
				// return(<Dropdown.Item eventKey={item.id}>{item.TabName}</Dropdown.Item>);
				return (
					<div className="col-6" key={item.ID}>
						<div className="inner-ingredients-info-box">
							<h5><a href="#">{item.inner_link_heading}</a></h5>
							<span>
								<p>
									Odor Cat: {item.second_heading}  
									<br></br>
									{item.flavor}
									<br></br>
									{item.odor_data}
								</p>
							</span>
						</div>
					</div>
				
				// <tr key={item.ID} scop="row">
				// 	<td>{item.inner_link_heading}</td>
				// 	<td>{item.cas}</td>
				// 	<td>{item.description}</td>
				// 	<td><a href={item.Sub_inner_link_url} target="_blank">Go to site</a></td>
				// </tr>
				);
			});
		}
        // console.log(catDetails, 'in')
        return (
            <section className="fragrance-details">
                <div className="container">
                    <div className="row d-flex">
                    {/*{ID==='26' ? orderIndex:''}*/}
                    {ID==15 && <FlavorIndexList flavorIndexes={catDetails}/>}
                    {ID==26 && <OdorIndexList odorIndexs={catDetails}/>}
                    {ID==7 && <CosmeticFunction cosmeticFunc={catDetails}/>}
                        {ID!=26 && ID!=15 && ID!=7 && Details}
                    {/* {Details} */}
                    </div>
                </div>
            </section>
        )
    
            
}
 export default FragranceDetailGeneral; 