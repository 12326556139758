
 const StepTwo = ({oils,handleStepTwo,state,oilWeight,sumOilValues}) => {
    const css = `
    label{
    font-size:12px;
    font-weight:bold;
    }
    `;
    return (
        <div className="ingredints-from">
            <style>{css}</style>
            <h4>INGREDIENTS  <small className="text-danger">  (One input must be fill)</small>

                {oilWeight && (sumOilValues === 100) && <span style={{color:"green",marginLeft:"20px"}}>
                    { parseFloat(100 ).toFixed(2)} Quantity
                </span> }

                {oilWeight && (sumOilValues <100 || isNaN(sumOilValues))  && <span style={{color:"orange",marginLeft:"20px"}}>
                    { parseFloat(100 - (isNaN(sumOilValues) ? 0:sumOilValues) ).toFixed(2)} More Quantity Required
                </span>}

                {oilWeight && sumOilValues > 100  && <span style={{color:"red",marginLeft:"20px"}}>
                    Quantity Should by less then 100.00
                </span>}
            </h4>
            <hr/>
                    <div className="row">
                        { (oils && oils.length > 0 ? oils:[]).map(oil =>{
                            return(<div className="col-md-2" key={oil.id} >

                                    <label className="font-weight-bold">{oil.name}</label>
                                <input type="number" min={1} value={state[oil.id] && parseFloat(state[oil.id])} name={oil.id} className="form-control" onChange={handleStepTwo} />

                                </div>)
                        })}
                    </div>

            {oilWeight && (sumOilValues === 100) && <h4 style={{color:"green",textAlign:"center"}}>
                { parseFloat(100 ).toFixed(2)} Quantity
            </h4> }

            {oilWeight && (sumOilValues <100 || isNaN(sumOilValues))  && <h4 style={{color:"orange",textAlign:"center"}}>
                { parseFloat(100 - (isNaN(sumOilValues) ? 0:sumOilValues) ).toFixed(2)} More Quantity Required
            </h4>}

            {oilWeight && sumOilValues > 100  && <h4 style={{color:"red",textAlign:"center"}}>
                Quantity Should by less then 100.00
            </h4>}
        </div>
    )
}

export default StepTwo;