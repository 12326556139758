import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './redux/CreateStore';
import { createBrowserHistory } from "history";
import 'bootstrap/dist/css/bootstrap.min.css';

const history = createBrowserHistory();
// history.go(0)
let getUrl = window.location;

let baseUrl = getUrl.protocol + "//" + getUrl.host; // + "/" + getUrl.pathname.split('/')[1];

const path = window.location.pathname.split("/")

window.BASE_URL = baseUrl;
window.Full_url = window.BASE_URL + window.SUB_URL;


if (localStorage.getItem('role') && localStorage.getItem('role') !== 'user' ) {
    window.SUB_URL='/admin';
    window.role = 'admin';
}else {
    window.role = 'user';
    window.SUB_URL='/';
}
if(getUrl.hostname==='localhost'){
//   window.API_URL = "http://bms.afi-usa.com/FUBackEnd/api/";
    window.API_URL = "http://localhost:/FUBackEnd/api/";
    window.ASSET_URL = "http://localhost:/FUBackEnd/public/";
    window.RESET_URL = "http://localhost:3000/FragranceUniversity/";

}else {
    window.API_URL = "https://fragranceu.com/FUBackEnd/api/";
    window.ASSET_URL = "https://fragrance-uni.s3.amazonaws.com/";
    // window.ASSET_URL = "https://fragranceu.com/FUBackEnd/public/";
    window.RESET_URL = "https://fragranceu.com/";
}

if( window.SUB_URL === '/admin'
    // && (getUrl.pathname === '/FragranceUniversity' || getUrl.pathname === '/FragranceUniversity/')
    && (getUrl.pathname === '/' || !window.location.pathname.includes("admin"))
    && (localStorage.getItem('role') && localStorage.getItem('role')  !== 'user')
){
    window.location = '/admin'
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>

     <BrowserRouter basename={window.SUB_URL} history={history} forceRefresh={true}>    
            <App />
    </BrowserRouter> 
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
