import {Button, Col, Row} from "react-bootstrap";
import profilestartedimg1 from "../../../../../../assets/img/started-image1.png";
import React, {useEffect, useState} from "react";
import Loader from "react-js-loader";
import {Link} from "react-router-dom";

const NotStarted = ({courses})=>{
    const [pendingCourses,setPendingCourses] = useState('')
    const [showAll, setShowAll] = useState(true);
    useEffect(()=>{
        const tempCourse= courses
        setPendingCourses(tempCourse.slice(0,4))
    },[courses])

    const handleShowAll = ()=>{
        let allCourse = [...courses]

        if(showAll){
            setPendingCourses(allCourse)
        }else {
            setPendingCourses(allCourse.slice(0,4))
        }
        setShowAll(!showAll)
    }

    return(
        <div className="profile-course profile-course-yetstarted">
            <div className="container">
                <div className="profile-course-heading">
                    <h2>Not Yet Started</h2>
                    <p>It’s Never to Late to Jump Into Another Course</p>
                </div>
                <div className="profile-course-list profile-course-note">
                    <Row className="justify-content-center">
                        {!pendingCourses && <Loader type="spinner-cub" bgColor={"#c42625"} title={"Loading Courses..."} color={'#c42625'}
                                 size={100}/>}
                        {pendingCourses && pendingCourses.map(course=><Col lg={3} md={3} sm={6} key={course.CourseID} className="profile-course-column">
                            <div className="profile-course-started">
                                {/*<a href="#"><img src={profilestartedimg1} alt="image"/></a>*/}
                                <Link to={"/Courses/CoursesDetails/"+course.CourseID}><img src={window.ASSET_URL+'img/course-img/'+course.course_title+'.png'} loading="lazy" alt="image"/></Link>
                                {/* <div className="course-started-content">
                                    <h2>{course.course_title}</h2>
                                </div> */}
                            </div>
                        </Col>) }
                    </Row>
                    <div className="profile-cours-showall">
                        <Button className="showall-btn resetbht" onClick={handleShowAll}>{showAll ? 'Show All' : 'Show Less'}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotStarted