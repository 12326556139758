import React, { Component } from "react";
import Joi from "joi-browser";
import _ from 'lodash';
import { Toast, toastCss } from "../../../Widgets/alert";
import axios from "axios";
import Swal from "sweetalert2";
import { Route, Navigate, Link, useNavigate } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import {Form} from "react-bootstrap";
import ReactRecaptcha3 from 'react-google-recaptcha3';

class SignUp extends Component {

    constructor() {
        super();
        this.password = React.createRef();
    }

    state = {
        form: {
            name: '',
            email: '',
            password: '',
            confirmPassword: ''
        },
        errors: {},
        pwStrength: 'too short',
        loginUrl:"",
        disabled: true,
        recaptchaBtnTxt: 'Verify reCaptcha',
    }
    schema = {
        name: Joi.string().required().label('Name'),
        email: Joi.string().required().email().label('Email'),
        password: Joi.string().required().min(6).label('Password'),
        confirmPassword: Joi.any().valid(Joi.ref('password')).required().options({ language: { any: { allowOnly: 'must match password' } } }).required().label('Confirm Password')
    }

    componentDidMount() {
        this.getGoogleLogin()
        ReactRecaptcha3.init("6LfVFZ4lAAAAANYU_S2xN0V2KK1siliuijy1wh_J").then((status) => {
            console.log('------------------------------------------------');
            console.log(status);
        });
    }
    
    
    

    handleInputChange = ({ currentTarget: input }) => {
        let form = { ...this.state.form };
        form[input.name] = input.value;
        let errors = this.validate(form) || {}
        this.setState({ form, errors })
        // this.pwStrengthStatus()
    }

    pwStrengthStatus = ()=>{
        let element = document.getElementsByClassName('pw-section');
        let pwStrength =  element[0].children[2].lastChild.textContent
            this.setState({pwStrength});
    }

    validate = (form) => {
        let result = Joi.validate(form, this.schema, { abortEarly: false })
        if (!result.error) return null;

        const errors = {};
        for (let item of result.error.details) {
            errors[item.path[0]] = item.message;
        }

        return errors;
    }

    handleSubmit = (e) => {
        e.preventDefault()

        let formData = this.state.form;
        let errors = this.validate(formData);
        if (!_.isEmpty(errors)) {
            Toast('error', 'Please Fill All Inputs');
            this.setState({ errors })
            return false;
        }
        Swal.fire({
            title: "Signing Up",
            text: "Please wait",
            imageUrl: "images/ajaxloader.gif",
            showConfirmButton: false,
            allowOutsideClick: false
        });
        axios.post(window.API_URL + 'users/register', formData).then(response => {

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: "Success!",
                    text: response['data']['message'],
                    showConfirmButton: false,
                    timer: 1000
                });

                localStorage.setItem('user', JSON.stringify(response.data.data))
                localStorage.setItem('verified', 'notVerified')
                localStorage.setItem('user-token', response.data.token)

                // this.props.navigate('/login');
                this.props.navigate('/email/verify');
                window.location.reload()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: "Error!",
                    text: response['data']['message'],
                    showConfirmButton: false,
                    timer: 1000
                });
            }

        }).catch(error => {
            Swal.fire({
                icon: 'error',
                title: "Error!",
                text: error,
                showConfirmButton: false,
                timer: 1000
            });
        })


    }

    handleScore = (e)=>{
    }

    getGoogleLogin = ()=>{
        fetch(window.API_URL+'auth', {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                console.log(response)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => this.setState({loginUrl:data.url}) )
            .catch((error) => console.error(error));
    }

    handleVerify = ()=>{
        this.setState({recaptchaBtnTxt: 'Verifying Recaptcha..'});
        ReactRecaptcha3.getToken().then(
            (token) => {
                axios.post(window.API_URL+'verify-recaptcha', {response: token})
                    .then(result=>{
                        const data = result.data;
                        console.log(result);
                        if (data.score > 0.5) {
                            // User's response was valid, proceed with form submission or take appropriate action
                            this.setState({
                                disabled: false,
                                recaptchaBtnTxt: 'reCaptcha Verified',
                            });
                            console.log('reCAPTCHA verification success');
                        } else {
                            // User's response was invalid, show error message or take appropriate action
                            console.log('reCAPTCHA verification failed');
                            this.setState({ recaptchaBtnTxt: 'Verify reCaptcha' });
                        }
                })
            }
        );
    }
    


    render() {
        const { errors,form,pwStrength,loginUrl} = this.state;
        return (
            <React.Fragment>
                <style>
                    {css}
                    {toastCss()}
                </style>
                <div className="container login-page">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-8">
                            {/*<div className="card" style={{background:"#c42625",color:"white",padding:"100px 20px"}}>*/}
                            <div className="card" >
                                <div className="card-header " style={{ background: "#c42625" }} ><h4 style={{ color: "white" }}>Sign Up </h4></div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/*<h2 style={{textAlign:"center",color:"white"}}>Sign Up</h2>*/}
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="form-group mb-2">
                                                    <label htmlFor="name" className="font-weight-bolder">User Name <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="name" onChange={this.handleInputChange} id="name" placeholder="Enter Full Name" />
                                                    {errors['name'] && <small className="text-danger text-left"> {errors['name']}</small>}
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label htmlFor="email">Email address <span className="text-danger">*</span></label>
                                                    <input type="email" className="form-control" name="email" onChange={this.handleInputChange} id="email" placeholder="Enter email" />
                                                    {errors['email'] && <small className="text-danger text-left"> {errors['email']}</small>}
                                                </div>
                                                <div className="form-group mb-2 pw-section">
                                                    <label htmlFor="exampleInputPassword1">Password <span className="text-danger">*</span></label>
                                                    <input type="password" name="password" ref={this.password} onChange={this.handleInputChange} className="form-control"
                                                        id="exampleInputPassword1" placeholder="Password" />
                                                    <small className="text-danger text-left">
                                                        {errors['password'] && <span>{errors['password']}</span>}
                                                        {/*<span style={{color:"gray",float:'right'}}>{pwStrength}</span>*/}
                                                    </small>
                                                    <PasswordStrengthBar shortScoreWord="" onChangeScore={this.handleScore} barColors={['#e3e3e3', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']} password={form.password} />

                                                </div>

                                                <div className="form-group mb-2">
                                                    <label htmlFor="confirmPassword">Confirm Password <span className="text-danger">*</span></label>
                                                    <input type="password" name="confirmPassword" className="form-control"
                                                        id="confirmPassword" onChange={this.handleInputChange} placeholder="Confirm Password" />
                                                    {errors['confirmPassword'] && <small className="text-danger text-left"> {errors['confirmPassword']}</small>}
                                                </div>
                                                <div className="login-button">
                                                    <button className="verifyBtn" disabled={!this.state.disabled} type="button" onClick={this.handleVerify}>{this.state.recaptchaBtnTxt}</button>
                                                    <button type="submit" disabled={this.state.disabled} className="btn btn-outline-danger form-control">Register</button>
                                                </div>
                                                {/*<div className="or-container">*/}
                                                {/*    <div className="line-separator"></div>*/}
                                                {/*    <div className="or-label">or</div>*/}
                                                {/*    <div className="line-separator"></div>*/}
                                                {/*</div>*/}
                                                {/*<div className="row">*/}
                                                {/*    <div className="col-md-12 text-center">*/}
                                                {/*        <a style={{padding:".5rem 1.8rem"}} className="btn btn-lg btn-google btn-block btn-outline" href={loginUrl}>*/}
                                                {/*            /!*<img src="https://img.icons8.com/color/16/000000/google-logo.png"/>*!/*/}
                                                {/*            <i className="fa fa-google text-danger"></i> Signup using Google*/}
                                                {/*        </a>*/}
                                                
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                        Have an Account? <Link to="/Login" style={{textDecoration:"none"}}>Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <SignUp {...props} navigate={navigate} />
}

export default WithNavigate


const css =`
.btn-google {
        color: #545454;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 1px #ddd;
}

.or-container {
    align-items: center;
    color: #ccc;
    display: flex;
    margin: 8px 0;
}
.or-label {
    flex-grow: 1;
    margin: 0 15px;
    text-align: center;
}

.line-separator {
    background-color: #ccc;
    flex-grow: 5;
    height: 1px;
}

.verifyBtn {
  padding: 12px 24px;
  background-color: #0069d9;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
  float: right;
}

.verifyBtn:hover {
  background-color: #005cbe;
}
.verifyBtn:disabled {
   background-color: #28a745!important;
   color: white;
   cursor: not-allowed;
}

`