import React, {useEffect, useState} from "react";
import {Button, Col, ProgressBar, Row, Tab} from "react-bootstrap";
import profileicon from '../../../../../../assets/img/profile-logo.png';
import Loader from 'react-js-loader'
import {Link} from "react-router-dom";

const Bookmarks = ({bookmarkVideos,lessonsLeft})=>{


    const [bookmarks,setBookmarks] = useState('')
    const [showBookmark, setShowBookmark] = useState(true);

	const handleShowBookmark = ()=>{
	    let allBookmarks = [...bookmarkVideos]

	    if(showBookmark){
            setBookmarks(allBookmarks)
        }else {
            setBookmarks(allBookmarks.slice(0,3))
        }
        setShowBookmark(!showBookmark)

    }

    useEffect(()=>{
        const tempVideos = bookmarkVideos
        setBookmarks(tempVideos.slice(0,3))

    },[bookmarkVideos])


    // const getData =()=> {
    //     axios.get(window.API_URL+'bookmarks/'+user.id+'?&page='+pagination.current_page)
    //         .then(({data})=>{
    //             setPagination(data.data);
    //             console.log(data.data)
    //             let tempVideos = [...data.data]
    //             setCourseVideosFull(tempVideos)
    //             setCourseVideos(tempVideos.slice(0,3))
    //             setLoading(false)
    //         })
    //
    // }

    return(
        <div className="profile-course">
            <div className="container">
                <div className="profile-course-heading">
                    <h2>Bookmarked Videos</h2>
                    <p>All Your Favorite Videos Located in One Place</p>
                </div>
                <div className="profile-course-list">
                    <Row>
                        {!bookmarks && <div style={{padding:"50px"}}><Loader type="spinner-cub" bgColor={"#c42625"} title={"Loading Bookmarks ..."} color={'#c42625'} size={100} /></div>}
                        {bookmarks && bookmarks.map(video=>  <Col key={video.videoID} lg={4} md={4} sm={6}  className="profile-course-column mb-2">
                            <div className="profile-course-detail">
                                <div className="profile-course-title">
                                    <div className="profile-course-icon">

                                        <img src={profileicon} alt="image"/>
                                    </div>
                                    <div className="profile-course-discription">
                                        <h5>Course: <span>{video.course.course_title}</span></h5>
                                        <p>Lessons Left: <span>{lessonsLeft(video.course.CourseID)}</span></p>
                                    </div>
                                </div>
                                <div className="profile-course-body">
                                    {video.embedlink ?(
                                        <img loading="lazy"  src={"https://img.youtube.com/vi/" + video.embedlink + "/maxresdefault.jpg"}/>
                                        ):(
                                        <img loading="lazy" src={window.ASSET_URL+video.thumbnail}/>
                                    )}
                                    <Link to={"/Courses/CoursesDetails/"+video.CourseID+'?videoId='+video.videoID} className="mt-3">   <Button className="resume-btn"> <span><i className="fa fa-play"
                                                                             aria-hidden="true"></i></span>
                                        <span>Resume</span></Button></Link>
                                    {/*<ProgressBar variant="danger" now={70} />*/}
                                        
                                </div>
                                <div className="profile-course-foot">
                                    <p>Continue Watching: {video.video_title}</p>
                                </div>
                            </div>
                        </Col>)}

                    </Row>

                    {bookmarks && <div className="profile-cours-showall">
                        {!bookmarks.length ? (
                            <div className="text-center">
                                <p>No Bookmarks Available</p>
                                <Link to="/Courses" className="btn btn-outline-danger" >Watch Videos</Link>
                            </div>
                        ):(
                            <Button className="showall-btn resetbht"
                                    onClick={handleShowBookmark}> {showBookmark ? 'Show All' : 'Show Less'}   </Button>
                        )}

                    </div>}
                </div>
            </div>
        </div>
    )
}
export default Bookmarks