import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import OdorIndexDetail from "./odor-index/OdorIndexDetail";
import axios from "axios";
import FlavorIndexDetail from "./flavor-index/FlavorIndexDetail";
import CosmeticDetail from "./cosmetic-function/CosmeticDetail";
import Loader from 'react-js-loader'

const CategoryDetailShow = ()=>{

    const params = useParams()

    const [pagination,setPagination] = useState({current_page:1})
    const [offset] = useState(10);
    const [keyword,setKeyword] = useState('')

    let [categoryDetails,setCategoryDetails] = useState('')
    useEffect(() => {
       getData()
    },[]);

    const handleSearch = (e)=>{
        const pagin = {...pagination}
        pagin.current_page = 1
        setPagination(pagin)
        e.preventDefault()
        setKeyword(e.target[0].value)
        getData(1,e.target[0].value)
    }

    const getData = (pageNo=1,searchKeyword='')=>{
        setCategoryDetails('')
        let url = `${window.API_URL}Fragrances/getCategoriesDetails/rawMaterialsDetails/
        ${params.id}/${params.detailId}
        ?page=${pageNo}&keyword=${searchKeyword}`

        axios.get(url).then(response => {
            if(params.id ==7){
                setPagination(response.data.details)
                setCategoryDetails(response.data.details.data)
            }else {
                setCategoryDetails(response.data.details)
            }

        }).catch((e) => {

        });
    }

    return(
        <div className="row">
            <div className="col-md-12 ">

                {params.id == 15 && <FlavorIndexDetail catId={params.id} categoryDetails={categoryDetails}/>}
                {params.id == 26  && <OdorIndexDetail catId={params.id} categoryDetails={categoryDetails}/>}
                {params.id == 7
                && <CosmeticDetail
                    pagination={pagination}
                    offset={offset}
                    catId={params.id}
                    categoryDetails={categoryDetails}
                    getData={getData}
                    keyword={keyword}
                    handleSearch={handleSearch}
                />}
            </div>
        </div>
    )
}
export default CategoryDetailShow