import {UPDATE_USER_PROFILE,ADD_USER} from '../../ActionTypes';
import {updateUserProfileService} from "../../services/UserProfile/UserProfileService";
import {SwalBefore,SwalAfter} from "../../../components/Widgets/alert";

export const updateUserProfile = (user,id)=>{
    return dispatch => {
        SwalBefore('Updating Profile!')

        return new Promise((resolve,reject)=>{
            updateUserProfileService(user,id).then(response=>{

                if (response.data.success){
                    let user = response.data.user
                    localStorage.setItem('user',JSON.stringify(user))
                    dispatch({type: UPDATE_USER_PROFILE,user })
                    SwalAfter('success','Done','Profile Update Successfully!')
                    return  resolve(true);
                }else {
                    SwalAfter('error','Failed',response.data.msg)
                    return  reject(false);
                }
            })
        })

    }

}

export const AddUser = (user)=>{
    return dispatch =>dispatch({type:ADD_USER,user:user});
}
