import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useParams, useSearchParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import {Tabs, Tab, Row, Col, Collapse, Fade} from 'react-bootstrap';
import ContentLoader from "react-content-loader";
import Slider from "react-slick";
import {EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon,} from "react-share";
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton,} from "react-share";
import videoLoader from '../../../../../src/assets/img/video-loader.gif'
import {Toast} from "../../../Widgets/alert";
import LoginModal from "../../../Dashboard/pages/common/LoginModal";
import Plyr from "plyr-react";
import 'plyr-react/plyr.css'
import YouTube from "react-youtube";
export default function List(props) {

    const YT = useRef(null);

    const redirect = useNavigate();
    const { id } = useParams()
    let search = window.location.search;
    let queryParams = new URLSearchParams(search);
    let bookmarkId = queryParams.get('videoId');
    let path = window.location.href.split('?')[0]
    const [showModal,setShowModal] = useState(false)
    // let foo = params.get('query');
    const [searchParams] = useSearchParams();
    const [courseVideos,setCourseVideos] = useState('')
    const [currentVideo,setCurrentVideo] = useState('')
    const [currentVideoIndex,setCurrentVideoIndex] = useState(1)
    const [bookmark,setBookmark] = useState('')
    const [note,setNote] =useState('')
    const [user,setUser] = useState(JSON.parse(localStorage.getItem('user')))
    const [showAllNotes,setShowAllNotes] = useState(false)
    const [courses,setCourses] = useState('')
    const [open,setOpen] = useState(false)
    const [currentVideoSource,setCurrentVideoSource] = useState('')
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const [youtubeOpts,setYoutubeOpts] = useState({
        host: "https://www.youtube-nocookie.com",
        height: '500',
        width: '100%',
        playerVars: {
            autoplay: 1,
            showinfo:0,
            rel:0,
            modestbranding:1,
            autohide:1,
        },
    })

	useEffect(() => {
        getCourse(id)
        getAllCourses(id)
        if(!user){
            localStorage.setItem('redirect-url',window.location.href)
            setShowModal(true)
        }
        // videoCurrentTimeSave()
    }, [])
    var intervalToSaveVideoTime

   const videoCurrentTimeSave = (videoId)=>{
       intervalToSaveVideoTime = setInterval(()=>{
            YT?.current?.internalPlayer?.getPlayerState().then(playerState=>{
                if(playerState === 0){

                }
                if(playerState ===1) { // only save time when video is playing
                    YT?.current?.internalPlayer.getCurrentTime().then(currentTime => {
                            axios.post(window.API_URL + 'current-time/store',
                                {
                                    userId: user.id,
                                    videoId: videoId,
                                    currentTime: currentTime,
                                }).then(response => {
                            })
                    })
                }
            })
        },5000)


    }
    useEffect(()=>()=>clearInterval(intervalToSaveVideoTime),[])
    setTimeout(()=>{
        document.getElementsByClassName('dropdown-toggle')[0]?.classList.add('dropdown')
        document.getElementsByClassName('dropdown-toggle')[0]?.classList.remove('dropdown-toggle')
    },100)


    const getCourse = (id)=>{
        setCurrentVideoSource('')
        axios.get(`${window.API_URL}find_course/`+id)
            .then(({data})=>{
                if(data.videos){

                    setCourseVideos(data.videos)

                   // play first video of course

                    let fistVideo = data.videos.find(video => video.notes[0]?.resume == '1')
                    let index = 1;
                    if(fistVideo){
                        index = data.videos.findIndex(video => video.videoID == fistVideo.videoID)+1
                    }else {
                        fistVideo = data.videos[0];
                    }

                    if(bookmarkId){ // play bookmarked video
                        fistVideo = data.videos.find(video => video.videoID == bookmarkId)
                        index = data.videos.findIndex(video => video.videoID == bookmarkId)+1
                    }


                    if(fistVideo?.video){
                        let sources = {
                            type: "video", sources: [{
                                src: window.ASSET_URL + fistVideo.video,
                                type: 'video/mp4',
                                size: 720,
                            }]
                        }
                        setCurrentVideoSource(sources)
                    }
                    setCurrentVideo(fistVideo)
                    setCurrentVideoIndex(index)

                    var topPos = document.getElementById('active')?.offsetTop;
                    document.getElementById('course-video-list')?.scrollTo({top:topPos-200,behavior:"smooth"})
                    videoCurrentTimeSave(fistVideo?.videoID)
                    if(fistVideo?.notes[0]){

                        let opts = {...youtubeOpts}
                        opts.playerVars.start = fistVideo.notes[0].current_time
                        setYoutubeOpts(opts)

                        if(fistVideo?.notes[0].description) {
                            setNote(fistVideo.notes[0].description)
                            if (fistVideo.notes[0].bookmark == '1') {
                                setBookmark(1)
                            } else {
                                setBookmark(0)
                            }
                        }else {
                            setNote('')
                            setBookmark(0)
                        }
                    }



                }
        })
    }


    const getAllCourses = (id)=>{

        axios.get(`${window.API_URL}get_courses?all=true`)
            .then(({data})=>{
                let courses = data.courses.filter(course=> course.CourseID != id)
                // let courses = data.courses
                setCourses(courses)
            })
    }
    const handleCurrentPlaying = (e='',videoId)=>{
        let video = courseVideos.find(video => video.videoID === videoId)
        let index = courseVideos.findIndex(video => video.videoID === videoId)+1
       let sources =   {type: "video",sources:[{
            src: window.ASSET_URL+video.video,
            type: 'video/mp4',
            size: 720,
        }]}
        setCurrentVideoSource(sources)

        setCurrentVideo(video)
        setCurrentVideoIndex(index)
        if(video.notes[0]){

            let opts = {...youtubeOpts}
            opts.playerVars.start = video.notes[0].current_time
            setYoutubeOpts(opts)


            if(video.notes[0]?.description) {
                setNote(video.notes[0].description)
            }else {
                setNote('')
            }

        }else {
            let opts = {...youtubeOpts}
            opts.playerVars.start = 0
            setYoutubeOpts(opts)
            setNote('')
        }
        if(bookmarkId){
            queryParams.delete('videoId')
        }
        changeVideoResumeStatus(videoId)
        !e && window.scrollTo({top:100,behavior:"smooth"});
         e && handleActive(e);
        videoCurrentTimeSave(video?.videoID);
    }

    const changeVideoResumeStatus = videoId => {
	    const data = {userId:user.id,videoId}
	    axios.post(window.API_URL+'video/resume-status',data)
            .then(response=>{
            })
    }


    const handleActive = (e)=>{
        let currentElement = e.target.closest('.video-list-deials');
        currentElement.classList.add('active')
        let allElements = document.getElementsByClassName('video-list-deials');
        for(let index=0;index<allElements.length;++index){
            let element =  allElements[index]
            if(element !== currentElement){
                element.classList.remove('active')
            }
        }
    }


    const handleCharacterCount = (e)=>{
        if(e.target.value.length<=250) {
            setNote(e.target.value)
        }
    }
    const handleSaveNotes = ()=>{
            const data = {userId:user.id,videoId:currentVideo.videoID,desc:note}
            axios.post(window.API_URL+'video-notes/store',data)
                .then(response=>{
                })
            let tempVideo = {...currentVideo}
            tempVideo.notes[0] = {...tempVideo.notes[0],description:note};
            setCurrentVideo(tempVideo)
            let tempAllVideos = [...courseVideos]
            tempAllVideos[currentVideoIndex-1].notes[0]  = {...tempAllVideos[currentVideoIndex-1].notes[0],description:note};
            setCourseVideos(tempAllVideos)
    }

    const handleBookmarkVideo = ()=>{

        let bookmarkStatus = 1;
        if(currentVideo.notes[0]?.bookmark == '1'){
            bookmarkStatus = 0
        }
        setBookmark(bookmarkStatus)
            const data = {userId:user.id,videoId:currentVideo.videoID,bookmark:bookmarkStatus}
            axios.post(window.API_URL+'video/bookmark',data)
                .then(({data})=>{
                    if(data.bookmark == '1'){
                        Toast('success','Added to Bookmarked')
                    }else {
                        Toast('error','Bookmark Removed')
                    }
                })
            let tempVideo = {...currentVideo}
            tempVideo.notes[0] = {...tempVideo.notes[0],bookmark:bookmarkStatus};
            setCurrentVideo(tempVideo)
            let tempAllVideos = [...courseVideos]
            tempAllVideos[currentVideoIndex-1].notes[0]  = {...tempAllVideos[currentVideoIndex-1].notes[0],bookmark:bookmarkStatus};
            setCourseVideos(tempAllVideos)
    }

    const handleShowAllNotes = ({currentTarget:checkBox})=>{
        setShowAllNotes(checkBox.checked)
    }

    const handleChangeCourse = (courseId)=>{
        redirect('/Courses/CoursesDetails/'+courseId)
        setCourseVideos('')
        setCurrentVideo('')
        setCourses('')
        getAllCourses(courseId)
        getCourse(courseId)
    }

    const downloadPdf = (id)=>{
        window.location = window.API_URL+'get-script-pdf/'+id
    }

    const handleShare = ()=>{
	    setOpen(!open)
    }



    const css = `
    .course-video-list .active{
            background:#ebebeb!important;
            transition: width 2s;
    }
     .course-video-list  .video-list-deials:hover{
            background:#ebebeb!important;
    }
    .slick-initialized .slick-slide {
        padding: 0px 5px;
    }
    button.slick-arrow.slick-next {
        right:-35px;
    }
    .dropdown-item{
        display:table-cell;
    }
    .dropdown-menu{
        transition: width 2s;
    }
    .slick-arrow{
    top:85px;
    }
    a[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -100%;
  left: 0;
}
.colored-toast.swal2-icon-success {
    background-color: gray !important;
}
    `
    const youtube = (e)=>{

    }
    return (
        
        <div className="courses-page">
            <style>{css}</style>
            <div className="container">
                <LoginModal showModal={showModal} closeBtn={false}/>
                <div className="courses-section">
                    <Row className="course-detail-top">
                        <Col lg={7} md={6} className="courses-leftbar">

                                <div className="video-section" style= {{marginBottom:15}}>

                                    {(currentVideo && currentVideo.embedlink ? <YouTube ref={YT} style={{width:"100%",height:"500"}}  videoId={currentVideo.embedlink} onStateChange={youtube} opts={youtubeOpts}  /> :( !currentVideoSource ? <div style={{width:"100%",height:"500px",background:"black",textAlign:"center"}}>
                                                <img style={{marginTop:"190px"}} width="auto" height="100"  src={videoLoader}/></div> : <div>{
                                                currentVideoSource &&  <div><Plyr autoPlay={true} source={currentVideoSource}/></div>}</div>
                                            // <video id="myVideo" className="bg-dark" width="100%" height="500" controls >
                                            //     <source src={window.ASSET_URL+currentVideo.video} type="video/mp4"/></video>

                                        )
                                    )}

                                    {/*{(currentVideo && currentVideo.embedlink ? <iframe id="ytplayer" width="100%" height="500" src={`https://www.youtube-nocookie.com/embed/${currentVideo.embedlink}?rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1`}*/}
                                    {/*                         title="YouTube video player" frameBorder="0"*/}
                                    {/*                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                    {/*                         allowFullScreen="allowFullScreen">*/}

                                    {/*        </iframe> :( !currentVideoSource ? <div style={{width:"100%",height:"500px",background:"black",textAlign:"center"}}>*/}
                                    {/*            <img style={{marginTop:"190px"}} width="auto" height="100"  src={videoLoader}/></div> : <div>{*/}
                                    {/*            currentVideoSource &&  <div><Plyr source={currentVideoSource}/></div>}</div>*/}

                                    {/*    )*/}
                                    {/*)}*/}

                            </div>
                            {!currentVideo && <>
                            <div className="course-import">
                                <div className="import-detail">
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="0" y="17" rx="0" ry="0" width="400" height="40"/>
                                        </ContentLoader>
                                        <ContentLoader viewBox="0 0 380 70">
                                        <rect x="0" y="17" rx="0" ry="0" width="800" height="20"/>
                                        <rect x="0" y="45" rx="0" ry="0" width="200" height="20"/>
                                    </ContentLoader>

                                </div>
                            </div>
                            </>}
                            {/*<Player>*/}
                            {/*    <source src="http://localhost:/FUBackEnd/public/videos/8w8VGIB7c0db3zpDPOl7GS4jlkIgK4pgRtpPPqfg.mp4" />*/}
                            {/*</Player>*/}

                            {/*<ReactPlayer playIcon controls={true} url='http://localhost:/FUBackEnd/public/videos/8w8VGIB7c0db3zpDPOl7GS4jlkIgK4pgRtpPPqfg.mp4' />*/}
                            {currentVideo && <>
                                <div className="course-import">
                                    <div className="import-detail">
                                        <h3>{currentVideo.video_title}</h3>
                                         <p>{currentVideo.description}</p>
                                    </div>
                                    <div className="import-buttons">
                                        <ul>
                                            <li>


                                                <Fade in={!open}  >
                                                 {/*<Collapse in={!open} dimension={"width"}>*/}
                                                 <div className="">
                                                     <button title="Share"  onClick={handleShare}  className="btn">
                                                         <span ><i className="fa fa-share" aria-hidden="true"></i></span>
                                                         {/*<span>Course Guide</span>*/}
                                                     </button>
                                                 </div>
                                                 {/*</Collapse>*/}
                                                </Fade>
                                                 <Collapse in={open} dimension={"width"} style={{marginTop:"-38px"}}>
                                                     <div className="" >
                                                         <ul className="list-group list-group-horizontal">
                                                             <li className="list-group-item">
                                                                 <LinkedinShareButton url={path+'?videoId='+currentVideo.videoID} >
                                                                    <LinkedinIcon  size={32} round={true}/>
                                                                 </LinkedinShareButton>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <FacebookShareButton url={path+'?videoId='+currentVideo.videoID} >
                                                                     <FacebookIcon  size={32} round={true}/>
                                                                 </FacebookShareButton>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <TwitterShareButton url={path+'?videoId='+currentVideo.videoID} >
                                                                     <TwitterIcon  size={32} round={true}/>
                                                                 </TwitterShareButton>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <WhatsappShareButton url={path+'?videoId='+currentVideo.videoID} >
                                                                     <WhatsappIcon  size={32} round={true}/>
                                                                 </WhatsappShareButton>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <EmailShareButton url={path+'?videoId='+currentVideo.videoID} >
                                                                     <EmailIcon  size={32} round={true}/>
                                                                 </EmailShareButton>
                                                             </li>
                                                             <li  className="list-group-item"  >
                                                                 <i onClick={handleShare} className="fa fa-window-close" style={{padding:"5px",fontSize:"25px"}}></i>
                                                             </li>
                                                         </ul>
                                                     </div>
                                                        </Collapse>

                                            </li>
                                            <li>
                                                <a href={window.API_URL+'get-script-pdf/'+currentVideo.videoID} onClick={()=>downloadPdf(currentVideo.videoID)} className="btn" title="Download Course Guide" >
                                                    <span><i className="fa fa-download" aria-hidden="true"></i></span>
                                                    {/*<span>Course Guide</span>*/}
                                                </a>
                                            </li>
                                            <li>
                                                <button className={"btn "} style={{background:(currentVideo.notes[0]?.bookmark == '1'?"#94908d":'')}} title="Bookmark" onClick={handleBookmarkVideo}>
                                                    <span><i className="fa fa-bookmark-o" aria-hidden="true"></i></span>
                                                    {/*<span>bookmark</span>*/}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                            }

                        </Col>
                        <Col lg={5} md={6} className="courses-leftbar">
                            <div className="course-detail-sidebar">
                                <div className="course-profile-section">
                                    <div className="profile-image text-center">
                                        <img src={window.ASSET_URL+'img/course-img/headshot.jpg'} height="83px" width="auto" alt="image"/>
                                    </div>
                                    <div className="profile-detail">
                                        <h3>You’re Watching:</h3>
                                        {currentVideo && <p>{currentVideo.video_title}</p>}
                                    </div>
                                </div>
                                <div className="course-detail-tabs">
                                    <div className="total-lessons">
                                        <p>{courseVideos.length} Lessons</p>
                                    </div>
                                    <Tabs
                                        defaultActiveKey="Lessons"
                                        id="noanim-tab-example"
                                        className="mb-3"
                                        >
                                        <Tab eventKey="Notes" title="My Notes">
                                            <div className="all-notes">
                                                <label className="notes-show">Show all notes
                                                    <input type="checkbox" onClick={handleShowAllNotes} />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            { !showAllNotes && <div className="lesson-list" key={12312}>
                                                <div className="lesson-list-items">
                                                    <div className="lesson-heading">
                                                        {currentVideo && <h3>{currentVideo.video_title} <small> (
                                                            Lesson {currentVideoIndex} )</small></h3>}
                                                        <h4>Currently Watching</h4>
                                                    </div>
                                                    {currentVideo && <div className="lesson-discriptions">
                                                        <textarea name="notes" id="notes" maxLength={250} value={note}
                                                                  onBlur={handleSaveNotes}
                                                                  onInput={handleCharacterCount} rows="5"
                                                                  placeholder="Type Your Notes here.."
                                                                  className="form-control">
                                                            {currentVideo.notes[0]?.description}
                                                        </textarea>
                                                        <label htmlFor=""
                                                               className="pull-right text-black-50">{note.length}/250</label>
                                                    </div>}
                                                </div>
                                            </div>}

                                            { showAllNotes && <div className="lesson-list">
                                                { courseVideos && courseVideos.map((video,i)=>{
                                                    return(
                                                        <div className="lesson-list-items" key={i} >
                                                            <div className="lesson-heading">
                                                                <h3>{video.video_title} <small>( Lesson {i+1} )</small></h3>
                                                                {/*<h4>Currently Watching</h4>*/}
                                                            </div>
                                                            <div className="lesson-discriptions">
                                                                {video.notes[0] && <dd>{video.notes[0].description}</dd>}
                                                                {!video.notes[0] && <dd>Not Notes Available</dd>}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )  }

                                            </div>}
                                        </Tab>
                                        <Tab eventKey="Lessons" title="All Lessons">
                                            <div className="lesson-peragraph">
                                                {currentVideo && <p>{currentVideo.description}</p>}
                                            </div>
                                            <div className="course-video-list" id="course-video-list">
                                                {!courseVideos && Array.from(Array(10), (e, i) =>
                                                    <div className={"video-list-deials "} role="button" key={i}>
                                                    <div className="video-section">
                                                        <ContentLoader viewBox="0 5 110 70" style={{margin: "-10px"}}>
                                                            {/* Only SVG shapes */}
                                                            <rect x="0" y="0" rx="5" ry="5" width="1000" height="1000"/>
                                                            {/*<rect x="80" y="17" rx="4" ry="4" width="300" height="13" />*/}
                                                            {/*<rect x="80" y="40" rx="3" ry="3" width="250" height="10" />*/}
                                                        </ContentLoader>

                                                    </div>
                                                    <div className="video-discription">
                                                        <h2><ContentLoader viewBox="0 0 380 70"
                                                                           style={{margin: "-10px"}}>
                                                            <rect x="0" y="17" rx="0" ry="0" width="300" height="20"/>
                                                            <rect x="0" y="45" rx="0" ry="0" width="200" height="20"/>
                                                        </ContentLoader></h2>
                                                    </div>
                                                </div>)}
                                                { courseVideos && courseVideos.map((video,i)=>
                                                    <div className={"video-list-deials "+(i==(currentVideoIndex-1)?'active':'')} id={(i==(currentVideoIndex-1)?'active':'')} role="button" onClick={e=>handleCurrentPlaying(e,video.videoID)} key={video.videoID}>
                                                        <div className="video-section">
                                                            <img
                                                                loading="lazy"
                                                                width="100%" height="100%"
                                                                role="button"
                                                                src={(video?.thumbnail?.includes('image/') ? window.ASSET_URL+video.thumbnail:`https://img.youtube.com/vi/${video.embedlink}/${(video.thumbnail ?video.thumbnail:'maxresdefault.jpg')}`)}
                                                                alt="image"/>
                                                            {/*{video.embedlink ? <img width="100%" height="100%"*/}
                                                            {/*      src={"https://img.youtube.com/vi/" + video.embedlink + "/maxresdefault.jpg"}/> :*/}
                                                            {/*    <img width="100%" height="100%"*/}
                                                            {/*                         src={window.ASSET_URL+video.thumbnail}/>}*/}

                                                        </div>
                                                        <div className="video-discription">
                                                            <h2>{video.video_title} - {video.video_length}</h2>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="other-course-video">
                        <div className="other-video-title">
                            <h3>All Lessons</h3>
                        </div>
                        <Row className="justify-content-center">
                            {courseVideos && courseVideos.map((video,i)=><Col lg={3} md={3} sm={6} key={video.videoID} role="button">
                                    <div className="video-section mt-3" onClick={e=>handleCurrentPlaying('',video.videoID)}>

                                        <img
                                            width="100%" height="100%"
                                            role="button"
                                            src={(video?.thumbnail?.includes('image/') ? window.ASSET_URL+video.thumbnail:`https://img.youtube.com/vi/${video.embedlink}/${(video.thumbnail ?video.thumbnail:'maxresdefault.jpg')}`)}
                                            alt="image"/>

                                        {/*{video?.embedlink && <img width="100%" height="100%"*/}
                                        {/*                                     src={"https://img.youtube.com/vi/" + video?.embedlink + "/maxresdefault.jpg"}*/}
                                        {/*                                     alt=""/>}*/}

                                        {/*{video?.video && <img width="100%" height="100%"*/}
                                        {/*                                 src={window.ASSET_URL+video?.thumbnail}*/}
                                        {/*                                 alt=""/>}*/}

                                    </div>
                                    <div className="video-discription">
                                        <p>{video?.video_title}</p>
                                    </div>
                                </Col>
                            )}

                        </Row>
                    </div>
                    <div className="other-course-video">
                        <div className="other-video-title">
                            <h3>Other Courses</h3>
                        </div>
                        <Row>
                            <Slider {...settings} style={{textAlign:"center"}}>
                                {!courses && Array.from(Array(5), (e, i) => <Col lg={3} md={3} sm={6} key={i} >
                                    <div className="">
                                        <ContentLoader viewBox="0 0 125 70" >
                                            <rect x="0" y="0" rx="5" ry="0" width="1000" height="1000"/>
                                        </ContentLoader>

                                    </div>
                                    <div className="video-discription">
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="0" y="17" rx="0" ry="0" width="300" height="20"/>
                                            <rect x="0" y="45" rx="0" ry="0" width="200" height="20"/>
                                        </ContentLoader>
                                    </div>
                                </Col>)}
                                {courses && courses.map((course,i)=> course.videos[0] &&
                                    <Col sm={8} key={course.CourseID} onClick={()=>handleChangeCourse(course.CourseID)} role="button">
                                        <div className="video-section">
                                            {/*{course.videos[0]?.embedlink && <img width="100%" height="100%"*/}
                                            {/*      src={"https://img.youtube.com/vi/" + course.videos[0]?.embedlink + "/maxresdefault.jpg"}*/}
                                            {/*      alt=""/>}*/}

                                            {/*{course.videos[0]?.video && <img width="100%" height="100%"*/}
                                            {/*      src={window.ASSET_URL+course.videos[0].thumbnail}*/}
                                            {/*      alt=""/>}*/}

                                            <img
                                                width="100%" height="100%"
                                                role="button"
                                                src={(course.videos[0].thumbnail?.includes('image/') ? window.ASSET_URL+course.videos[0].thumbnail:`https://img.youtube.com/vi/${course.videos[0]?.embedlink}/${(course.videos[0].thumbnail ?course.videos[0].thumbnail:'maxresdefault.jpg')}`)}
                                                alt="image"/>

                                        </div>
                                        <div className="video-discription">
                                            <p>{course.course_title}</p>
                                        </div>
                                    </Col>
                                )}
                            </Slider>

                        </Row>
                    </div>
                    <div className="course-script-section">
                         <div className="course-script-heading">
                            <h4>Script</h4>
                        </div>
                        <div className="course-script-discription">
                            {currentVideo?.script !== "null" ? <p>{currentVideo.script}</p> : <p>Script Not Available</p>}
                            {/*{ (  || !currentVideo?.script)  && <p>Script Not Available</p>}*/}
                        </div>
                    </div>
                </div>
            </div>

        </div>    
    )
}

