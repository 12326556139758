import axios from "axios";
import {SwalAfter, SwalBefore} from "../components/Widgets/alert";
const AxiosInterceptorsSetup = navigate => {
    axios.interceptors.response.use(undefined,err=>{
        if(err?.response?.status === 403 || err?.response?.status === 401){

            if(localStorage.getItem('user-token') !== null){

                if(window.role === 'user') {
                    SwalBefore('Unauthorized User', 'Logging Out..')
                    localStorage.clear()
                    setTimeout(() => {
                        SwalAfter('success', 'Logged Out')
                        window.location = window.SUB_URL + '/login'
                    }, 3000)
                }else {
                    navigate('/not-allowed')
                }
            }

        }
        return Promise.reject(err)
    })
};

export default AxiosInterceptorsSetup;