import React from 'react';
import "./Fragrance.css";
import {Row, Col, Form, Modal, Button, Tabs,Tab} from "react-bootstrap";
import cwimg from '../../../../assets/img/color-wheel-re-export.jpg';

const FragrenceColorWheel = () => {
  return (
    <div>
        <section className="fragrance-composition-things">
            <div className='container'>
                <div className="composition-things-title">
                    <h1>THE FUNDAMENTALS OF COLOR THEORY</h1>
                </div>
                <div className='hr-line'></div>
                <Row className='composition-row'>
                    <Col lg={7} md={7} sm={12}>
                        <div className="composition-things-details">
                            <p>
                                Color theory is both the science and art of using color. It explains how humans perceive
                                color; and the visual effects of how colors mix, match or contrast with each other. Color
                                theory also involves the messages colors communicate; and the methods used to replicate 
                                color.
                            </p>
                            <p>
                                In color theory, colors are organized on a color wheel and grouped into 3 categories: 
                                primary colors, secondary colors and tertiary colors. More on that later.
                            </p>
                            <div className='composition-btn'>
                                <Button type='button' className='resetbht my-composit-btn'>
                                    <a className="text-decoration-none  text-white" target="_blank" href={window.API_URL+'download-pdf/color-wheel'}>DOWNLOAD</a>
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <div className="composition-rightside">
                            <div className='composition-img wheel'>
                                <img src={cwimg}/>
                            </div>
                            <div className="composition-txt">
                                <h4>“People decide whether or not they like a product in 90 seconds or less. 90%
                                 of that decision is based solely on color.”</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    </div>
  )
}

export default FragrenceColorWheel