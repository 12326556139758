import React, {useEffect, useState} from 'react';
import "./Fragrance.css";
import {Row, Col, Form, Modal, Button, Tabs,Tab} from "react-bootstrap";
import topsellingimg1 from '../../../../assets/img/sell-image1.png';
import topsellingimg2 from '../../../../assets/img/sell-image2.png';
import topsellingimg3 from '../../../../assets/img/sell-image3.png';
import topsellingimg4 from '../../../../assets/img/sell-image4.png';
import topsellingimg5 from '../../../../assets/img/sell-image5.png';
import topsellingimg6 from '../../../../assets/img/sell-image6.png';
import topsellingimg7 from '../../../../assets/img/sell-image7.png';
import Loader from 'react-js-loader'
import axios from "axios";

const FragrenceIndustry = () => {

    const [topSellings,setTopSellings] = useState('')
    const cats = [
        {
            'name': 'candles',
            'heading': 'Top Candle Fragrances',
            'img':topsellingimg1

        },
        {
            'name': 'soaps',
            'heading': 'Top soap fragrances',
            'img':topsellingimg3
        },
        {
            'name': 'bodyOils',
            'heading': 'Top body oil Fragrances',
            'img':topsellingimg4
        },
        {
            'name': 'airFresheners',
            'heading': 'Top Air Freshener fragrances',
            'img':topsellingimg5
        },
        {
            'name': 'incenses',
            'heading': 'top incense fragrances',
            'img':topsellingimg6
        },
        {
            'name': 'industrials',
            'heading': 'top household cleaner fragrances',
            'img':topsellingimg7
        }
    ]

    useEffect(()=>{
        getData()
    },[])

    const getData = ()=>{
        axios.get(window.API_URL+'top-selling-fragrance')
            .then(({data})=>{
                setTopSellings(data)
            })
    }

  return (
    <div>
        <section className="topselling-section">
            <div className="container">
            <div className="topselling-button download-button">
                <a target="_blank" href={window.API_URL+'download-pdf/top-fragrance-list'} className="btn resetbht">Download</a>
            </div>
                <div className="topselling-fragrance">
                    <div className="topselling-title">
                        <h1>Top Selling Fragrances</h1>
                    </div>
                    <div className="row align-items-center">
                        <Col lg={6} md={6} sm={12} >
                            <div className="topselling-details topselling-discription">
                                <p>At AFU we care about giving back to you and helping you grow your business. Below you will find our best selling fragrances for <span>candles, Soaps, Incense, Air Freshener, Body oils and Industrial</span> fragrances.</p>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} >
                            <div className="topselling-image">
                                <img src={topsellingimg2} alt="image"/>
                            </div>
                        </Col>
                    </div>
                    <div className="download-button">
                        <a target="_blank"  href={window.API_URL+'download-pdf/top-selling-fragrance'} className="btn resetbht">Download</a>
                    </div>
                </div>

                {cats.map(cat=><div className="topselling-fragrance">
                    <div className="topselling-title">
                        <h2>{cat.heading}</h2>
                    </div>
                    <div className="row align-items-center">
                        <Col lg={6} md={6} sm={12}>
                            <div className="topselling-details">
                                <ul>
                                    {topSellings
                                    && topSellings[cat.name].map(product=><li>{product[2]}
                                        <span>
                                            <a
                                                target="_blank"
                                                href={`https://www.afi-usa.com/shop/FragranceDetails/${
                                                            product[3]
															? product[3].split(',').map(cat => `${cat.trim().replace(/ /g, '-')}-Fragrance-Oil`).join('/')
															: 'Fragrance-oil'
														}/${product[1]}/${encodeURIComponent(
                                                            product[2].replace(/[^0-9A-Za-z ]/g, '').replace(/ +/g, '-').replace(/-$/, '')
														)}`}
                                                className=" text-decoration-none"
                                                style={{color:"#c42625"}}
                                            >
                                                [{product[1]}]</a>

                
                                        </span>
                                    </li>) }
                                    {!topSellings && <li> <Loader

                                        type="spinner-cub"
                                        bgColor={"#c42625"}
                                        title={`Loading Fragrances...`}
                                        color={'#c42625'}
                                        size={100}/></li>}
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="topselling-image">
                                <img src={cat.img} alt="image"/>
                            </div>
                        </Col>
                    </div>
                </div>) }
            </div>
        </section>
    </div>
  )
}

export default FragrenceIndustry