import {isVerified,isLogged} from "../helpers/CurrentUser";
import { Navigate, Outlet } from 'react-router-dom'

export const AfterLogin = (props) => {

    if(!isLogged){
        return <Navigate to="/login" />;
    }else {
        return  <Outlet />;
    }
}

export const BeforeLogin = (props) => {

    if(isLogged){
        return <Navigate to="/user/profile" />;
    } {
        return  <Outlet />;
    }
}

export const NotVerified = ()=>{
    if(!isVerified){
        return <Navigate to="/email/verify" />
    }else {
        return  <Outlet />;
    }
}

export const Verified = ()=>{
    if(isVerified){
        return <Navigate to="/user/profile" />
    }else {
        return  <Outlet />;
    }
}