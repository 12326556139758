import {Button, Col, Row} from "react-bootstrap";
import infoImgFour from "../../../../../assets/img/info-img6.png";
import {Link} from "react-router-dom";
import infoImgFive from "../../../../../assets/img/info-img7.png";
import infoImgSix from "../../../../../assets/img/info-img8.png";
import React from "react";

function StatisticsSection({handleSurveyModal}){
    return(
        <section className="fragrance-info-check">
            <div className="container">
                <Row className="info-check-wrap">
                    <Col lg={4} md={4} sm={12}>
                        <div className="info-check-column">
                            <div className="info-check-image">
                                <img src={infoImgFour} alt="image"/>
                            </div>
                            <div className="info-check-detail">
                                <h3>Fragrance Industry <br/> Statistics</h3>
                                <Link to="/FragranceIndustry" className="btn resetbht check-out-btn">
                                    Check it out
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <div className="info-check-column">
                            <div className="info-check-image">
                                <img src={infoImgFive} alt="image"/>
                            </div>
                            <div className="info-check-detail">
                                <h3>Top Selling<br/> Fragrances</h3>
                                <Link to="/TopSelling" className="btn resetbht check-out-btn">
                                    Check it out
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <div className="info-check-column">
                            <div className="info-check-image">
                                <img src={infoImgSix} alt="image"/>
                            </div>
                            <div className="info-check-detail">
                                <h3>The Fragrance That’s Right For You</h3>
                                <Button onClick={handleSurveyModal} className="btn resetbht check-out-btn">
                                    Check it out
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default StatisticsSection