const StepOne = ({state,handleStepOne})=> {
    const {inputs,errors} = state
    const css = `
     label{
    margin-bottom:2px;
    font-weight: bold;
    }`
    return (
        <div className="row justify-content-center">
            <style>
                {css}
            </style>

            <div className="col-lg-6 col-md-9">
                <h4>Soap Type & Measurement Units</h4>
                <hr/>
                <div className="">

                    <div className=" col-md-12">
                        <div className="row mb-2">
                           <div className="col-md-12">
                                   <label htmlFor="type">Soap Type <span className="text-danger">*</span></label>
                                   <select name="type" value={inputs.type}  className="form-control form-select" onChange={handleStepOne} id="">
                                       <option value="">-Type-</option>
                                       <option value="liquid">Liquid</option>
                                       <option value="solid">Solid</option>
                                   </select>
                                   {errors['type']  && <div className="text-danger text-left"> {errors['type']}</div>}
                           </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-12">
                                  <label htmlFor="">Measurement Unit <span className="text-danger">*</span></label>
                                  <select name="measureUnit" className="form-control form-select" value={inputs.measureUnit} onChange={handleStepOne} id="">
                                      <option value="">-Measurement Unit-</option>
                                      <option value="oz">Ounce</option>
                                      <option value="g">Gram</option>
                                      <option value="percentage">Percentage</option>
                                  </select>
                                {errors['measureUnit'] && <div className="text-danger text-left"> {errors['measureUnit']}</div>}
                            </div>
                        </div>
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div style={{textAlign:"left"}} hidden={state.oilHide} >
                                <br/>
                                   <label htmlFor="">Oil Weight <span className="text-danger">*</span></label>
                                   <input type="number" name="oilWeight" value={inputs.oilWeight} onChange={handleStepOne} placeholder="Weight of Oils" className="form-control" min="0"/>
                                   {errors['oilWeight'] && <div className="text-danger text-left"> {errors['oilWeight']}</div>}
                                <br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" defaultChecked={true}  onClick={handleStepOne} type="radio" name="percentage" id="oz"
                                           value="oz"/>
                                    <label className="form-check-label" htmlFor="oz">OZ</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input"  onClick={handleStepOne} type="radio" name="percentage" id="g"
                                           value="g"/>
                                    <label className="form-check-label" htmlFor="g">Gram</label>
                                </div>
                            </div>
                        </div>
                    </div>

                  <div className="row mb-2">
                      <div className="col-md-12">
                             <label htmlFor="">SuperFatting Level <span className="text-danger">*</span></label>
                             <select name="superfattingLevel" value={inputs.superfattingLevel} onChange={handleStepOne} className="form-control form-select" id="">
                                 <option value="">-Superfatting Level-</option>
                                 {Array.from(Array(10),(e,i)=>{
                                     return (
                                         <option value={i+1} key={i}>{(i+1 === 1? 'None':i+1+'%')}</option>
                                     );
                                 })}

                             </select>
                             {errors['superfattingLevel'] && <div className=" text-danger text-left"> {errors['superfattingLevel']}</div>}
                      </div>
                  </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepOne;