import React,{Component} from "react";
import axios from "axios";
import Select from 'react-select'
import CreatableSelect  from 'react-select/creatable'
import ContentLoader from "react-content-loader"
// import Pagination from 'Widgets/Pagination';
import Pagination from "../../Widgets/Pagination";


class FragranceCalculatorComponent extends Component{
	constructor() {
		super();
		this.myForm = React.createRef();
	}
	state = {
		name:'',
		odorCategories:[],
		categories:{
			VPCat:'Air Freshener',
			BodyOilCat:'Body Oil',
			CandlesCat:'Candle',
			Diffuser:'Diffuser',
			EssentialOils_Formula:'Essential Oil',
			IncenseCat:'Incense',
			IndustrialCat:'Industrial',
			SoapCat:'Soap',
		},
		rawMaterialList:[{value:'',label:'No Record',disabled:true}],
		casNumbersList:[{value:'',label:'No Record',disabled:true}],
		rawMaterial: '',
		rawMaterialValue: {},
		casNumberValue: {},
		products:[],
		odorInputs: true,
		odorValue: '',
		pagination: {
			current_page: 1,
			from: 0,
			last_page: 0,
			per_page: 0,
			to: 0,
			total: 0,
		},
		form:{
			name: '',
			odorCategory: '',
			odorMin: '',
			odorMax: '',
			priceMin: '',
			priceMax: '',
			phthalateCheckb: '',
			propCheckb: '',
			category:''
		},
		dataFound:true,
		paginationVisibility:true,
		formBtn:false

	}
	componentDidMount() {
		this.getOdorCategories();
		this.getCasNumber();
		this.getRawMaterial()
	}

	getOdorCategories = ()=>{
		axios.get(window.API_URL+'categories')
			.then(response=>{
				let odorCategories = response.data.odorCategories;
				this.setState({odorCategories})
			})
	}


	getRawMaterial = (rawMaterial = '') => {
		axios.get(window.API_URL + 'raw-material?find='+rawMaterial)
			.then(response => {
				let rawMaterialList = response.data.rawMaterials
				this.setState({rawMaterialList})
			})
	}

	getCasNumber = (casNo = '') => {
		axios.get(window.API_URL + 'cas-number?find='+casNo)
			.then(response => {
				let casNumbersList = response.data.casNumbers
				this.setState({casNumbersList})
			})
	}

	filterRawMaterial = (inputValue, actionMeta)=>{
		if(actionMeta.action === 'input-change'){
				this.getRawMaterial(inputValue)
		}
	}
	filterCasNumber = (inputValue, actionMeta)=>{
		if(actionMeta.action === 'input-change'){
			this.getCasNumber(inputValue)
		}
	}


	onSelectRawMaterial = (inputValue, actionMeta)=>{
		if (actionMeta.action === 'clear'){
			this.getCasNumber()
			this.getRawMaterial()
		}
		this.setState({
			rawMaterial:(inputValue ? inputValue.value : ''),
			rawMaterialValue: inputValue,
			casNumberValue:{
				label:(inputValue ? inputValue.value.replace(/^0+/, '') : ''),
				value:(inputValue ? inputValue.value : '')
			}
		});

	}

	onSelectCasNumber = (inputValue, actionMeta)=>{

		if(actionMeta.action === 'clear'){
			this.getRawMaterial()
			this.setState({rawMaterialValue:inputValue})
		}

		if(actionMeta.action === "select-option"){

			axios.get(window.API_URL + 'raw-material?casNo='+inputValue.value)
				.then(response => {
					let rawMaterialList = response.data.rawMaterials

					let rawMaterialValue = rawMaterialList[0]
					this.setState({rawMaterialList,rawMaterialValue})
				})
		}

			this.setState({
				casNumberValue:inputValue,
				rawMaterial:(inputValue ? inputValue.value : '')
			});

	}


	handleFormSubmit = e=>{
		e.preventDefault()
		let form = {
			name:e.target['name'].value,
			odorCategory:e.target['odorCategory'].value,
			category:e.target['category'].value,
			odorMin:e.target['odorMin'].value,
			odorMax:e.target['odorMax'].value,
			priceMin:e.target['priceMin'].value,
			priceMax:e.target['priceMax'].value,
			phthalateCheckb:e.target['phthalateCheckb'].checked,
			propCheckb:e.target['propCheckb'].checked,
		};
		this.setState({form});

		setTimeout(()=>{
			this.getData()
		},10)
	}
	getData = (page=1,paginate=false)=>{
		this.setState({dataFound:false,formBtn:true});
		const {
			name,
			odorCategory,
			odorMin,
			odorMax,
			priceMin,
			priceMax,
			phthalateCheckb,
			propCheckb,
			category,
		} = this.state.form;

		let url = window.API_URL+'find-product?name='+name+'&cas='+ this.state.rawMaterial+
			'&odorCategory='+odorCategory+'&odorMin='+odorMin+'&odorMax='+odorMax+
			'&priceMin='+priceMin+'&priceMax='+priceMax+
			'&phthalateCheckb='+phthalateCheckb+'&propCheckb='+propCheckb+
			'&page='+page+'&category='+category;

		if (paginate===true){
			url = window.API_URL+'find-product?page='+page;
		}

		axios.get(url)
			.then(response=>{
			let products = response.data.products.data;
			let productsPaging = response.data.products;
			let pagination = {
				current_page: productsPaging.current_page,
				from: productsPaging.from,
				last_page: productsPaging.last_page,
				per_page: productsPaging.per_page,
				to: productsPaging.to,
				total: productsPaging.total,
			}

			this.setState({products,pagination,dataFound:true,paginationVisibility:( products &&products.length > 0? false:true ),formBtn:false})
		})
	}

	handleReset = ()=>{
		this.setState({
			rawMaterialValue:{
				label:'',
				value:''
			},
			casNumberValue:{
				label:'',
				value:''
			}
		});
		setTimeout(()=>{
			this.getData()
		},10)
	}

	handleOdorInputs = evt=>{ // on change odor category
		if (!evt.target.value){
			this.setState({odorInputs: true})
		}else {
			this.setState({odorInputs: false})
			// this.setState({odorValue:evt.target.selectedOptions[0].text})
			this.setState({odorValue:evt.target.value})
		}
	}

	handlePagination = e=>{

		this.getData(e)
// console.log(evt)
// 		this.myForm.current.submit()
	}


	render() {
		const {
			categories,
			odorCategories,
			rawMaterialList,
			products,
			casNumbersList,
			rawMaterialValue,
			casNumberValue,		
			pagination,
			paginationVisibility,
			formBtn
		} = this.state;
		const css = `
		label {
    margin-bottom: 3px;
}
		`;


		return (
			<div>
				<div className="">
					<style>
						{css}
					</style>

						<div className="col-md-12 candlecalculator-sec">
							<div className="card">
								<div className="card-header">
									<h4>Fragrance Calculator</h4>
								</div>

								<form action="" onSubmit={this.handleFormSubmit} method="POST" ref={this.myForm}>
								<div className="card-body">
									<div className="row fragrance-calculator-fields">

										<div className="col-lg-2 col-md-4">
											<div className="form-group">
												<label htmlFor="exampleInputEmail1">Name</label>
												<input type="text" name="name" placeholder="Enter name" className="form-control"/>
											</div>
										</div>

										<div className="col-lg-2 col-md-4">
											<div className="form-group">
												<label htmlFor="exampleInputEmail1">Categories</label>
												<select  name="odorCategory"  className="form-select">
													<option value=""  defaultValue="">All</option>
													{Object.keys(categories).map((cat, i)=> <option value={cat} key={i}>{categories[cat]}</option>)}

												</select>
											</div>
										</div>

										<div className="col-lg-2 col-md-4">
											<div className="form-group">
												<label htmlFor="category">Odor Categories</label>
												<select  name="category" className="form-select" onChange={this.getRawMaterial}  >
													<option value="" defaultValue="">All</option>
													{odorCategories.map(cat=> <option value={cat.CategoryNumber} key={cat.CategoryNumber}>{cat.CategoryType}</option>)}
												</select>
											</div>
										</div>
										<div className="col-lg-3 col-md-6">
											<div className="form-group">
												<label htmlFor="casNo">Cas#</label>
												<CreatableSelect
													value={casNumberValue}
													isClearable
													onChange={this.onSelectCasNumber}
													onInputChange={this.filterCasNumber}
													options={casNumbersList}
												/>
											</div>
										</div>
										<div className="col-lg-3 col-md-6">
											<div className="form-group">
												<label htmlFor="odorCategory">Raw Material</label>
												<CreatableSelect
													value={rawMaterialValue}
													isClearable
													onChange={this.onSelectRawMaterial}
													onInputChange={this.filterRawMaterial}
													options={rawMaterialList}
												/>
											</div>
										</div>
									</div>
									<div className="row fragrance-calculator-fields">
										<div className="col-lg-4 col-md-6">
											<div className="form-group" style={{textAlign:"center"}}>
													<label htmlFor="category">IFRA Preferred Usage Level</label>
												<div className="row ifra-preferred-fields" style={{padding:"0px"}}>
													<div className="col-md-6">
														<input type="number" name="odorMin" min="0" placeholder="Min Value" className="form-control"/>
													</div>
													<div className="col-md-6">
														<input type="number" name="odorMax" min="0"  placeholder="Max Value" className="form-control"/>
													</div>
												</div>

											</div>
										</div>

										<div className="col-lg-4 col-md-6">
											<div className="form-group" style={{textAlign:"center"}}>
													<label htmlFor="category" className="font-weight-bold">Price Range @25lbs</label>
												<div className="row ifra-preferred-fields" style={{padding:"0px"}}>
													<div className="col-md-6 " >
														<input type="number" name="priceMin" min="0" placeholder="Min Price" className="form-control"/>
													</div>
													<div className="col-md-6 " >
														<input type="number" name="priceMax" min="0" placeholder="Max Price" className="form-control"/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-2 col-md-4 check-fields" style={{padding: "38px 8px 5px"}} >
											<div className="form-check" >
												<input type="checkbox"  id="propCheckb" name="propCheckb" className="form-check-input"/>
												<label htmlFor="propCheckb"  style={{cursor:"pointer"}} className="form-check-label">PROP 65 FREE</label>
											</div>

										</div>
										<div className="col-lg-2 col-md-4 check-fields"  style={{padding: "38px 8px 5px"}} >
											<div className="form-check">
												<input type="checkbox" id="phthalateCheckb" name="phthalateCheckb" className="form-check-input"/>
												<label htmlFor="phthalateCheckb" style={{cursor:"pointer"}} className="form-check-label">PHTHALATE FREE</label>
											</div>

										</div>
									</div>
									<div className="row">
										<div className="col-md-12 text-center">
											<button type="submit" disabled={formBtn} style={{color:"white!important"}} className="btn btn-dark m-2">Submit</button>
											<button type="reset" onClick={this.handleReset} disabled={formBtn} className="btn btn-danger ">Reset</button>
										</div>
									</div>
								</div>
								</form>
							</div>
							<div className="records-listitems" hidden={paginationVisibility}>
								<Pagination pagination={this.state.pagination} offset={10} paginate={this.handlePagination} />
							</div>

							<div className="calculater-table">
								<div className="table-responsive">
									<table className="table">
										<thead>
										<tr>
											<th scope="col">Code</th>
											<th scope="col">Name</th>
											<th scope="col">Category</th>
											<th scope="col">Price 25lb</th>
											<th scope="col">IFRA Usage Level</th>
											<th scope="col">Phthalate Free</th>
											<th scope="col">Prop 65 Free</th>
											{/*<th scope="col" hidden={this.state.odorInputs}>{ odorCategories[this.state.odorValue] }</th>*/}
										</tr>
										</thead>
										<tbody hidden={this.state.dataFound}>
										{Array.from(Array(10),(e,i)=>{
											return (
												<tr>
													<td>
														<ContentLoader
															speed={1}
															width={100}
															height={50}
															viewBox="0 0 333 160"
															backgroundColor="#f3f3f3"
															foregroundColor="#ecebeb"
														>
															<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />
															{/*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*/}

														</ContentLoader>
													</td>		<td>
													<ContentLoader
														speed={1}
														width={100}
														height={50}
														viewBox="0 0 333 160"
														backgroundColor="#f3f3f3"
														foregroundColor="#ecebeb"
													>
														<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />
														{/*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*/}

													</ContentLoader>
												</td>		<td>
													<ContentLoader
														speed={1}
														width={100}
														height={50}
														viewBox="0 0 333 160"
														backgroundColor="#f3f3f3"
														foregroundColor="#ecebeb"
													>
														<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />
														{/*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*/}

													</ContentLoader>
												</td>		<td>
													<ContentLoader
														speed={1}
														width={100}
														height={50}
														viewBox="0 0 333 160"
														backgroundColor="#f3f3f3"
														foregroundColor="#ecebeb"
													>
														<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />
														{/*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*/}

													</ContentLoader>
												</td>		<td>
													<ContentLoader
														speed={1}
														width={100}
														height={50}
														viewBox="0 0 333 160"
														backgroundColor="#f3f3f3"
														foregroundColor="#ecebeb"
													>
														<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />
														{/*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*/}

													</ContentLoader>
												</td>
													<td>
														<ContentLoader
															speed={1}
															width={100}
															height={50}
															viewBox="0 0 333 160"
															backgroundColor="#f3f3f3"
															foregroundColor="#ecebeb"
														>
															<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />
															{/*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*/}

														</ContentLoader>
													</td>
													<td  hidden={this.state.odorInputs}>
														<ContentLoader
															speed={1}
															width={100}
															height={50}
															viewBox="0 0 333 160"
															backgroundColor="#f3f3f3"
															foregroundColor="#ecebeb"
														>
															<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />
															{/*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*/}

														</ContentLoader>
													</td>
													<td >
														<ContentLoader
															speed={1}
															width={100}
															height={50}
															viewBox="0 0 333 160"
															backgroundColor="#f3f3f3"
															foregroundColor="#ecebeb"
														>
															<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />
															{/*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*/}

														</ContentLoader>
													</td>
												</tr>)
										})}

										</tbody>
										<tbody hidden={!this.state.dataFound}>

										{products && products.length > 0 ? products.map((product,index) =>
												<tr key={index}>
													
													<td scope="row">
														<a
															href={`https://www.afi-usa.com/shop/FragranceDetails/${
															product.category
																? product.category.split(',').map(cat => `${cat.trim().replace(/ /g, '-')}-Fragrance-Oil`).join('/')
																: 'Fragrance-oil'
															}/${product.Code}/${encodeURIComponent(
															product.Name.replace(/[^0-9A-Za-z ]/g, '').replace(/ +/g, '-').replace(/-$/, '')
															)}`}
															target="_blank"
															className="text-decoration-none"
														>
															{product.Code}
														</a>
														</td>


													<td scope="row">
													<a
														href={`https://www.afi-usa.com/shop/FragranceDetails/${
														product.category
															? product.category.split(',').map(cat => `${cat.trim().replace(/ /g, '-')}-Fragrance-Oil`).join('/')
															: 'Fragrance-oil'
														}/${product.Code}/${encodeURIComponent(
														product.Name.replace(/[^0-9A-Za-z ]/g, '').replace(/ +/g, '-').replace(/-$/, '')
														)}`}
														target="_blank"
														className="text-decoration-none"
													>
														{product.Name}
													</a>
													</td>
													<td scope="row">{product.categoryName}</td>
													<td scope="row">${parseFloat(product.price).toFixed(2)}</td>
													<td>
														{/*{product.cat9}%*/}
														{ (isNaN(parseFloat(product.cat9).toFixed(2)) ? 'Not limited': parseFloat(product.cat9).toFixed(2) + '%')}
													</td>
													<td scope="row"><input type="checkbox" readOnly={true} checked={(product.PhthalateFree === "0" ? true:false)} /> </td>
													<td scope="row">
														<input type="checkbox" readOnly={true} checked={(product.CAPROP65 !== "1" ? false:true)} />
													</td>
													<td scope="row" hidden={this.state.odorInputs} >
														{/*{parseFloat(this.state.odorValue).toFixed(2)}*/}
														{parseFloat((this.state.odorValue[0] === '1' ?product.cat9 : (this.state.odorValue[0] === '2'?product.cat10 : 100))).toFixed(2)} %
													</td>

													{/*<th scope="row">*/}
													{/*	<input type="checkbox" readOnly={true} checked={(product.IFRA === null ? false:true)} />*/}
													{/*</th>*/}
												</tr>
											) :
											<tr>
												<td colSpan={6}>
													<h3>No Data Available</h3>
												</td>
											</tr> }
										</tbody>
									</table>
								</div>
							</div>
							<div className="" hidden={paginationVisibility}>
								<Pagination pagination={this.state.pagination} offset={10} paginate={this.handlePagination} />
							</div>
						</div>


					<span className="hide-mb-space">
				<br/>
				<br/>
				<br/>
				</span>

				</div>
			</div>
		)
	}

}
export default FragranceCalculatorComponent;