import {Modal,Button} from "react-bootstrap";
import React, { useState} from "react";
import Cropper from "react-easy-crop";
import Logo from "../../../../../../assets/img/logo.png";
import {Link} from "react-router-dom";

 const EditImageModal= ({handleModal,showModal,img,onCropComplete,saveCropedImg})=> {

     const [crop, setCrop] = useState({ x: 0, y: 0 })
     const [zoom, setZoom] = useState(1)

     const handleZoom = (e)=>{
         setZoom(e.target.value)
     }



     const css = `
     .App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: relative;
  margin: 30px auto;
  width: 500px;
  height: 300px;
}

.crop-container2 {
  position: relative;
  margin: 30px auto;
  width: 500px;
  height: 300px;
}

.crop-container3 {
  position: relative;
  margin: 30px auto;
  width: 300px;
  height: 500px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}
     `

    return (
        <>
            <style>
                {css}
            </style>
            <Modal
                show={showModal}
                onHide={handleModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="text-white " style={{background:"#4e4e4e",height:"50px"}}>
                    <Modal.Title>Update Image</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height:"330px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="App">
                                <div className="crop-container">
                                    <Cropper
                                        image={img}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={4 / 3}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </div>
                                <div className="controls">
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{display:"block"}}>
                    <div className="row" >
                        <div className="col-md-12 ">
                            <div className="form-group mb-2">
                                <input type="range" name="zoom"  step={0.1} value={zoom} onChange={handleZoom} min={1} max={3} className="form-range"/>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <button className=" btn btn-outline-dark pull-right " onClick={saveCropedImg} >
                                Crop
                            </button>

                        </div>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditImageModal