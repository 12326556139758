import ContentLoader from "react-content-loader";
import React from "react";

const TableLoader = ({tdNumber,trNumber,hidden})=>{
            return (Array.from(Array(trNumber),(e,i)=>{
                return(
                    <tr hidden={hidden} key={i}>
                        {Array.from(Array(tdNumber),(e,i)=>{
                            return(<td key={i}>
                                <ContentLoader
                                    speed={1}
                                    width={100}
                                    height={50}
                                    viewBox="0 0 333 160"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"

                                >
                                    <rect x="48" y="8" rx="3" ry="3" r="10" width="100%" height="70%" />
                                </ContentLoader>
                            </td>);
                        })
                        }
                    </tr>)}
                ));

}

export default TableLoader;
