import React, {useState} from "react";
import {Toast,toastCss} from "../../../../Widgets/alert";

function LyeCalculatorComponent() {

	let [calc,setCalc] = useState({candle:'',fragrance:''})
	let [final,setFinal] = useState({fragrance:'',wax:''})

	const handleCalculation = ({currentTarget:input})=>{
		let tempCalc = {...calc};
		tempCalc[input.name] = (input.value < 0 ? '':parseFloat(input.value));

		if (input.name === 'fragrance' && !calc.candle){
			Toast('error','Candle Size Required')
			return false;
		}else {
			let tempFinal = {...final};
			tempFinal.fragrance = parseFloat(((tempCalc.candle*tempCalc.fragrance)/100)).toFixed(2)
			tempFinal.wax = parseFloat(tempCalc.candle - tempFinal.fragrance).toFixed(2)
			setFinal(tempFinal)
		}
		setCalc(tempCalc)
	}

	return (
			<div className="row justify-content-center candlecalculator-sec">
				<style>{toastCss()}</style>
				<div className="col-md-12">
					<div className="card">
						<div className="card-header"><h4>Candle Calculator</h4></div>
						<div className="card-body">
							<div class="row justify-content-center">
								<div className="col-lg-7 col-md-8 col-sm-12">
									<div className="candle-form">
										<div className="form-group mb-2">
											<label htmlFor="size" ><span style={{fontWeight:"bold"}}>Candle Size</span> (Ounces)</label>
											<input type="number" value={calc.candle} min={0} onInput={handleCalculation} className="form-control" name="candle" id="size" placeholder="Candle Size" />
										</div>
										<div className="form-group mb-2">
											<label htmlFor="fragrance"  ><span style={{fontWeight:"bold"}}>Desired Fragrance Load</span> (Percentage)</label>
											<input type="number" value={calc.fragrance} min={0} onInput={handleCalculation} className="form-control" name="fragrance"  id="fragrance" placeholder="Desired Fragrance Load" />
										</div>
										<table className="table">
											<tbody>
											<tr>
												<th width={'50%'}>Fragrance Amount:</th>
												<td >{final.fragrance > 0 && isFinite(final.fragrance)&& <span>{final.fragrance} oz</span>}</td>
											</tr>
											<tr>
												<th>Wax Amount:</th>
												<td className="text-left">{final.wax && isFinite(final.wax) && <span>{final.wax} oz</span>}</td>
											</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

	)
};

export default LyeCalculatorComponent;