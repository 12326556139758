import {Link, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Toast} from "../../../Widgets/alert";
import Loader from 'react-js-loader'
import Pagination from "../../../Widgets/Pagination";
import SearchBox from "./includes/SearchBox";
import FormulaPopupModal from "./includes/FormulaPopupModal";
const Search = ()=>{

    let [show, setShow] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();
    const [keyword,setKeyword] = useState(searchParams.get("keyword"))
    const [result,setResult] = useState([])
    let param = (searchParams.get("keyword") === 'undefined' ? "":searchParams.get("keyword"))
    let _srchType = (searchParams.get("searchType") === 'undefined' ? "name":searchParams.get("searchType"))
    const [loader, setLoader] = useState(false)
    const [pagination,setPagination] = useState({current_page:1})
    const [synonymKeyword,setSynonymKeyword] = useState('')
    const [tempSearchType,setTempSearchType] = useState('')
    const[searchType,setSearchType] = useState('name')
    const [fragId, setFragId] = useState('')
    const searchTypes = [
        {
            label:'"Name Search"',
            type:"name"
        },
        {
            label:`"Name, CAS, EINECS, FEMA, FLAVIS"`,
            type:"cas"
        },
        {
            label:'"JECFA"',
            type:"jecfa"
        },
        {
            label:'"Odor (Odour)"',
            type:"odor"
        },
        {
            label:'"Flavor (Flavour)"',
            type:"flavor"
        }

    ]

    useEffect(()=>{
        setKeyword(param)
        setSearchType(_srchType)
        setTempSearchType(_srchType)
        handleSearch(param,_srchType)
        setSynonymKeyword(param)
    },[param,_srchType])

    const handleModal =()=>{
        setShow(!show)
    }


    const getFormula = (id)=>{

        setFragId(id)
        handleModal()

    }


    const handleInput = (e)=>{

        // if(e.target.getAttribute('searchType') === 'jecfa' ) {
        //     if(/^\d+$/.test(e.target.value)) {
        //         setKeyword(e.target.value)
        //     }else {
        //         setKeyword('')
        //     }
        // }else {
        if(e.target.name === 'searchType'){
            setSearchType(e.target.value)
        }else{

            setKeyword(e.target.value)
        }
        // }
        // setSearchType(e.target.getAttribute('searchType'))
    }

    const handleSearch = (searchKeyword,sType='')=>{

        if(searchKeyword.trim()) {
            setPagination({current_page:1})
            setSynonymKeyword(keyword)
            setTempSearchType(sType?sType:searchType)

            getData(1,sType,searchKeyword)

        }else {
            Toast('warning','Enter Some Text')
            setLoader(false)
        }
    }
    const getData = (page = 1,sType='',searchKeyword='')=>{
        setLoader(true)
        setResult('')
        if(!sType){
            sType = searchType
        }
        let keyWord = keyword
        if(searchKeyword){
            keyWord = searchKeyword
        }
        axios.get(window.API_URL + 'search?keyword=' + keyWord+'&page='+page+'&searchType='+sType)
            .then(({data}) => {
                setPagination(data)
                if(data?.data?.length) {
                    setResult(data.data)
                }else {
                    setResult('')
                    Toast('warning','Result Not Found')
                }
                setLoader(false)
            })
    }

    return(
        <div className="container">
            <FormulaPopupModal
                show={show}
                handleModal={handleModal}
                fragId={fragId}
            />
            <div className="row">
                <div className="col-md-12">
                    <div className="card" style={{height:"100%"}}>
                        <div className="card-header " style={{background:'#c42625'}}>
                            <h3 className="text-white">Fragrance University Search</h3>
                        </div>
                        <div className="card-body">
                            <div className="row justify-content-center">
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    {/*<label htmlFor="Search" className="mb-1 mt-2">Search</label>*/}
                                    <div className="searchbar-field input-group">
                                        <select name="searchType" className="form-select form-control" value={searchType} onChange={handleInput} style={{width:"150px"}}  id="">
                                            <option value="name"> Name Search</option>
                                            <option value="cas"> CAS, Name,  EINECS, FEMA, FLAVIS</option>
                                            <option value="jecfa"> JECFA</option>
                                            <option value="odor"> Odor (Odour)</option>
                                            <option value="flavor"> Flavor (Flavour)</option>
                                        </select>
                                        <input
                                            onKeyDown={(e)=>(e.key==='Enter'?handleSearch(keyword):'')}
                                            onInput={handleInput}
                                            value={keyword}
                                            type="search"
                                            className="form-control"
                                            style={{width:"68%"}}
                                            placeholder="Enter text"/>

                                        <div className="input-group-append">
                                            <button
                                                onClick={()=>handleSearch(keyword)}
                                                className="btn btn-outline-danger"
                                                style={{borderRadius:"0px"}}
                                                type="button">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <Pagination pagination={pagination} offset={10} paginate={getData}/>
                                            </div>
                                            <div className="col-md-5">
                                                {synonymKeyword && <p>Search Result for "{synonymKeyword}"
                                                    in {(searchType === 'name' ? '"Name Search"' : searchTypes.find(type => type.type === tempSearchType)?.label)}</p>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <table className="table table-hover table-striped">
                                                    <tbody >
                                                    {result && result.map((data,key)=> <tr key={key}>
                                                        <td>
                                                            { (data.category === 'All Ingredients' ||  data.category === 'Fragrances')  ? (
                                                                (data.fragrance_id !== null) ? (
                                                                        <a className="text-danger" role="button" onClick={()=>getFormula(data.fragrance_id)} >
                                                                            {(data?.synonym?.includes(synonymKeyword) ? data?.synonym : data.heading)}
                                                                        </a>
                                                                ):(
                                                                    <a
                                                                        target="_blank"
                                                                        className="text-decoration-none"
                                                                        href="https://www.afi-usa.com/shop/index.cfm?"
                                                                    >
                                                                        {(data?.synonym?.includes(synonymKeyword)  ? data.synonym : data.heading)}
                                                                    </a>
                                                                    )):(
                                                                        <Link
                                                                        className="text-decoration-none"
                                                                        to={{pathname: `/Categories/${data.category.replace(/\s/g, '_')}/${data.cat_id}/RawMaterialsDetails/${data.ID}`}}
                                                                        >
                                                                         {(data?.synonym?.includes(synonymKeyword) ? data.synonym : data.heading)}
                                                                        </Link>
                                                            )}
                                                            {tempSearchType === 'cas' && (data.cas ? <p><strong>Cas: </strong> {data.cas} </p> :'')}
                                                            {tempSearchType === 'odor' && (data.odor ? <p><strong>Odor: </strong> {data.odor} </p> :'')}
                                                            {tempSearchType === 'flavor' && (data.odor ? <p><strong>Flavor: </strong> {data.odor} </p> :'')}
                                                            {tempSearchType === 'jecfa' && (data.jecfa ? <p><strong>{synonymKeyword.length <4? 'JECFA':'FEMA'}: </strong> {data.jecfa} </p> :'')}
                                                            <small
                                                                className="text-black-50"> ({data.category})</small>
                                                        </td>
                                                    </tr>)}
                                                    </tbody>
                                                </table>

                                                {loader &&  <div style={{padding:"100px"}}><Loader type="spinner-cub" bgColor={"#c42625"} title={"Searching ..."} color={'#c42625'} size={100} /></div>}
                                                {!result && !loader && <p style={{padding:"100px",textAlign:"center"}}><strong>No record found please search with another keyword</strong></p>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-7">
                                                {result && <Pagination pagination={pagination} offset={10} paginate={getData}/>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Search