import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import axios from "axios";
import Loader from "react-js-loader";
import {SwalAfter, SwalBefore} from "../../../Widgets/alert";

function StoreRedirect() {
    const params = useParams();
    const navigate = useNavigate()

    useEffect(()=>{
        getRawMaterial(params.fragranceId)
    },[params])


    const getRawMaterial = (id)=>{
        axios.get(window.API_URL+'get-raw-by-fragrance/'+id)
            .then(({data})=>{
                if(data.length !== 0){
                    navigate('/Categories/'+data.mainTabeName.replaceAll(' ','_')+'/'+data.maintabid+'/RawMaterialsDetails/'+data.id)

                }else {
                    navigate('/Categories/All_Ingredients/3')
                }
            })
    }


    return (
        <div className="row">
            <div className="col-md-12">
               <div style={{padding:"200px"}} >
                   <Loader type="spinner-cub" bgColor={"#c42625"} title={"Redirecting..."} color={'#c42625'} size={100} />
               </div>
            </div>
        </div>
    )
}

export default StoreRedirect