// import './App.css';
import React from 'react';

import {
    Routes,
    Route,
    Navigate, useLocation
} from 'react-router-dom';

import Bodycomponent from '../components/Dashboard/Mainbody/Bodycomponent';
// import FragranceInfocomponent from '../components/Dashboard/pages/FragranceInfocomponent';
import FragranceInfo from '../components/Dashboard/pages/FragranceInfo/FragranceInfo';
import CalculatorComponent from '../components/Dashboard/pages/CalculatorComponent';
import RawMaterialsDetails from '../components/Dashboard/pages/FragranceDetails/RawMaterialsDetails';
import AbsoluteComponent from '../components/Dashboard/pages/AbsoluteComponent';
import CategoryDetails from '../components/Dashboard/pages/FragranceDetails/CategoryDetails';
import CoursesDetails from "../components/admin/pages/Courses/CoursesDetails";
import HomeCourseComponent from '../components/admin/pages/Courses/HomeCourseComponent';
import Login from '../components/admin/Authentication/login';
import Logintest from '../components/admin/Authentication/login_test';
import LoginTest from '../components/admin/Authentication/loginTest';
import ProtectedRoutes from './ProtectedRoutes';
import { createBrowserHistory } from "history";
import SignUp from "../components/Dashboard/pages/user/SignUp";
import ForgotPassword from "../components/Dashboard/pages/user/ForgotPassword";
import ResetPassword from "../components/Dashboard/pages/user/ResetPassword";
import EmailVerified from '../components/Dashboard/pages/user/includes/EmailVerified'
import EmailVerify from "../components/Dashboard/pages/user/includes/EmailVerfiy";
import UserProfile from "../components/Dashboard/pages/user/profile/UserProfile";
import {AfterLogin, BeforeLogin, NotVerified, Verified} from "./RouteAuthentication";
import CategoryDetailShow from "../components/Dashboard/pages/Categories/CategoryDetailShow"
import ScrollToTop from "../components/Widgets/ScrollToTop";
import OnClickImageComponent from "../components/Dashboard/pages/OnClickImageComponent";
import OnClickLinkComponent from "../components/Dashboard/pages/OnClickLinkComponent";
import RawMaterialsCrudComponent from "../components/Dashboard/pages/RawMaterialsCrudComponent";
import PageNotFound from "../components/Dashboard/pages/common/PageNotFound";
import Search from "../components/Dashboard/pages/search/Search";
import FragranceComposition from "../components/Dashboard/pages/FragranceInfo/FragranceComposition";
import FragranceIndustry from "../components/Dashboard/pages/FragranceInfo/FragranceIndustry";
import FragranceWheel from "../components/Dashboard/pages/FragranceInfo/FragranceWheel";
import TopSelling from "../components/Dashboard/pages/FragranceInfo/TopSelling";
import Molecule3dView from "../components/Dashboard/pages/FragranceDetails/Molecule3dView";
import FragrenceColorWheel from '../components/Dashboard/pages/FragranceInfo/FragrenceColorWheel';
import StoreRedirect from "../components/Dashboard/pages/common/StoreRedirect";
import GoogleCallback from "../components/admin/Authentication/SocialLogins/GoogleCallback";
import FacebookCallback from "../components/admin/Authentication/SocialLogins/FacebookCallback";
import LinkedinCallback from "../components/admin/Authentication/SocialLogins/LinkedinCallback";
import FakeLogin from "../components/admin/Authentication/FakeLogin";
import ContactUs from "../components/Dashboard/pages/ContactUs";
import AboutUs from "../components/Dashboard/pages/AboutUs";
import DataRetentionPolicy from "../components/Dashboard/pages/DataRetentionPolicy";
import PrivacyPolicy from "../components/Dashboard/pages/PrivacyPolicy";

function UserRoutes() {
    const location = useLocation();
    const history = createBrowserHistory()

    return (
        <React.Fragment>
            <ScrollToTop>
                <Routes history={history}>

                    <Route exact path="/" element={<Bodycomponent />} />

                    {/*<Route exact path="/contact-us" element={<h1>contact us</h1>}/>*/}
                    <Route exact path="/terms-of-service" element={<PrivacyPolicy/>}/>
                    <Route exact path="/data-retention-policy" element={<DataRetentionPolicy/>}/>
                    <Route exact path="/about-us" element={<AboutUs/>}/>
                    <Route exact path="/contact-us" element={<ContactUs />}/>
                    <Route exact path="/fake-login" element={<FakeLogin />}/>
                    <Route exact path="/auth/linkedin" element={<LinkedinCallback />}/>
                    <Route exact path="/auth/facebook" element={<FacebookCallback />}/>
                    <Route exact path="/auth/google" element={<GoogleCallback />}/>
                    <Route exact path="/store/:fragranceId" element={<StoreRedirect/>} />
                    <Route exact path="/molecule-3d-view" element={<Molecule3dView/>} />
                    <Route exact path="/search" element={<Search/>} />
                    <Route exact path="/not-found" element={<PageNotFound/>} />

                    <Route exact path="/Categories/:Name/:id" element={<CategoryDetails />} />
                    <Route exact path="/Categories/:Name/:id/details/:detailId" element={<CategoryDetailShow />} />
                    {/* <Route exact path="/FragranceInfo" element={<FragranceInfocomponent />} /> */}
                    <Route exact path="/FragranceInfo" element={<FragranceInfo />} />
                    <Route exact path="/FragranceComposition" element={<FragranceComposition />} />
                    <Route exact path="/FragranceIndustry" element={<FragranceIndustry />} />
                    <Route exact path="/FragranceWheel" element={<FragranceWheel />} />
                    <Route exact path="/TopSelling" element={<TopSelling />} />
                    <Route exact path="/FragranceColorWheel" element={<FragrenceColorWheel />} />

                    <Route exact path="/RawMaterialsCrud" element={<RawMaterialsCrudComponent />} />

                    <Route exact path="/admin/login" element={<Navigate replace to="/Login" />} />
                    <Route exact path="/*" element={<CalculatorComponent />}/>
                    <Route exact path="/CalculatorComponent" element={<Navigate replace to="FragranceCalculator" />} />
                    <Route exact path="/Absolutes" element={<AbsoluteComponent />} />

                    {/* <Route exact path="/OnClickImage/:id" element={<OnClickImageComponent />} /> */}

                    <Route path="/Categories/:name/:id/RawMaterialsDetails/:detail_id" element={<RawMaterialsDetails />} />
                    
                    {/*<Route path="/Categories/:name/:id/RawMaterialsDetails/:detail_id/OnClickImage/:id" element={<OnClickImageComponent />} />*/}
                    <Route path="/Categories/:name/:id/RawMaterialsDetails/:detail_id/:detail_name" element={<OnClickImageComponent />} />

                    <Route path="/Categories/:name/:id/RawMaterialsDetails/:detail_id/Typical_GC_Analysis/:id" element={<OnClickLinkComponent />} />

                    <Route exact path="/Courses" element={<HomeCourseComponent />} />

                    <Route exact path="/Courses/CoursesDetails/:id" element={<CoursesDetails />} />

                    <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
                    <Route exact path="/ResetPassword/:token" element={<ResetPassword />} />
                    <Route exact path="/" element={<AfterLogin/>}>

                        <Route exact path="/" element={<Verified/>} >
                            <Route exact path="/email/verify" element={<EmailVerify />} />
                            <Route exact path="/email/verified" element={<EmailVerified />} />
                        </Route>

                        <Route exact path="/" element={<NotVerified/>}>
                            <Route exact path="/user">
                                <Route path="/user/profile" element={<UserProfile/>}/>
                                <Route path="/user/profile/edit" element={<UserProfile/>}/>
                            </Route>
                        </Route>
                    </Route>
                    <Route exact path="/" element={<BeforeLogin/>}>
                        <Route exact path="/Login" element={<Login />} />
                        <Route exact path="/Logintest" element={<Logintest />} />
                        <Route exact path="/Login-test" element={<LoginTest />} />
                        <Route exact path="/signup" element={<SignUp />} />
                    </Route>

                </Routes>
            </ScrollToTop>

        </React.Fragment>

    );
}

export default UserRoutes;

