import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, {useEffect, useState} from "react";
import Loader from 'react-js-loader'
import Pagination from "../../../../Widgets/Pagination";
import axios from "axios";

function FormulaPopupModal({show, handleModal,fragId}) {
    const [formulas,setFormulas] = useState({current_page:1})
    const [keyword,setKeyword] = useState('')
    const [frag,setFrag] = useState('')
    const [raws,setRaws] = useState('')
    useEffect(()=>{
        if(fragId) {
            getFormula(fragId)
        }
        setFormulas({current_page:1})
    },[fragId])

   const getFormula = (id,search=false)=>{

        let page = formulas?.current_page
       if(search){
           page = 1
       }

        setFormulas('')
        setFrag('')
        setRaws('')
       axios.get(window.API_URL+'formulas/'+id+'?search='+keyword+'&page='+page)
           .then(response=>{

               setRaws(response.data.data)
               setFormulas(response.data)
               setFrag(response.data.fragrance)
           })
   }

    return (
        <>
            <Modal show={show} onHide={handleModal}>
                <Modal.Header className="bg-danger text-white" closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Footer className="text-left " style={{display:"block"}}>
                    <div className="row">
                        <div className="col-md-12">
                            {frag &&  <label htmlFor="">Fragrance: {frag.Name}</label>}
                            <div className="input-group">
                                <input
                                    onKeyDown={(e)=>(e.key==='Enter'?getFormula(fragId,true):'')}
                                    onInput={(e)=>setKeyword(e.target.value)}
                                    value={keyword}
                                    type="search"
                                    className="form-control"
                                    style={{width:"68%"}}
                                    placeholder="Enter text"/>

                                <div className="input-group-append">
                                    <button
                                        onClick={()=>getFormula(fragId,true)}
                                        className="btn btn-outline-danger"
                                        style={{borderRadius:"0px",padding:"0.49rem .75rem"}}
                                        type="button">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">

                            <table className="table table-striped " style={{fontSize:"17px"}}>
                                <tbody>

                                {!raws &&  <tr style={{padding:"20px"}} aria-colspan="2"><Loader type="spinner-cub" bgColor={"#c42625"} title={"Loading Fragrances ..."} color={'#c42625'} size={100} /></tr>}
                                {raws && raws.map((form,i)=><tr key={i} >
                                    <td>
                                        <a href={'https://www.afi-usa.com/shop/ItemsDetails/'+form.code} target="_blank" className="text-danger">
                                            {form.code}
                                        </a>
                                    </td>
                                    <td>
                                        <a href={'https://www.afi-usa.com/shop/ItemsDetails/'+form.code} target="_blank" className="text-danger">
                                            {form.name}
                                        </a>
                                    </td>
                                </tr>)}
                                {raws && raws?.length ===0 && <tr >
                                    <td colSpan="2" className="text-center">
                                        <label htmlFor="">No Record Found</label><br/>
                                        <a href="https://www.afi-usa.com/shop/index.cfm?" target="_blank" className="text-danger">
                                            See All Fragrances
                                        </a>
                                    </td>
                                </tr>}
                                </tbody>
                            </table>
                            {formulas && <Pagination pagination={formulas} offset={10} paginate={()=>getFormula(fragId)}/>}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default  FormulaPopupModal;