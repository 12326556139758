import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import BannerVideo from '../../../assets/img/banner-video.mp4';
import Searchlogo from '../../../assets/img/search.png';
import Banner from '../../../assets/img/banner.png';
import {Link, useNavigate} from 'react-router-dom'
import UsageCalIcon from '../../../assets/img/usage-calculator.png';
import FragranceSearchIcon from '../../../assets/img/fragrance-search.png';
import ConverstionCalIcon from '../../../assets/img/converstion-calculator.png';
import IngredientSIcon from '../../../assets/img/ingredient-search.png';
import LoginModal from "../pages/common/LoginModal";
import EmailVerifyModal from "../pages/common/EmailVerifyModal";
import moment from "moment";
import VideoPlaceHolder from '../../../assets/img/video-loader.gif'
import ContentLoader from "react-content-loader";
import NotFoundModal from "./NotFoundModal";

function Bodycomponent() {

	const [courses, setCourses] = useState([])
	const [targetCourse, SetTargetCourse] = useState([""])
	const [firstcoruse,SetFirstcoruse] = useState("")
	const [loading, setLoading] = useState(false)
	const [courseset, setCourseset] = useState(false)
	const navigate = useNavigate();
	let [showModal,setShowModal] = useState(false)
	let [fistvid,setFistvid] = useState("")
	let [usr,setUsr]= useState('')
	const [searchText,setSearchText] = useState('')
	const [searchResult,setSearchResult] = useState('')
	const [hide,setHide] = useState(true)
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	let [courseVideos,setCourseVideos] =useState('')
	const controller = new AbortController();
	const config = {
		signal: controller.signal
	}
	useEffect( () => () => controller.abort(), [] );//unmount

	useEffect(() => {
		fetchCourses()
		setUsr(localStorage.getItem('user'))
		startCourse(29,true,'start')

	}, [])

	const handleCourseSearch = (e)=>{
		setSearchText(e.target.value)

		if(!e.target.value.trim()){
			setHide(true)
			setSearchResult('')

		}else {
			setHide(false)

		}
			axios.get(`${window.API_URL}search-courses?title=` + e.target.value)
				.then(({data}) => {
					setSearchResult(data)
					if(data.msg && e.target.value){
						// handleShow()
						navigate('/Courses?redirect=true')
						setSearchResult('')
					}
				})
	}

	const fetchCourses = async () => {
		await axios
			.get(`${window.API_URL}coureses_list`,config)
			.then(({ data }) => {
				setCourses(data)
				// let firstcoruse = data.find(obj => obj.course_title ==="Fragrance 101");
				  SetFirstcoruse(data[0])
				// setCourseVideos(firstcoruse[0].videos)
				  setLoading(true)
				 	 
			})

	}
	const handleModal = ()=>{
		setShowModal(!showModal)
	}


	const handleActive = (e)=>{
		let currentElement = e.target;
		currentElement.classList.add('active')
		let allElements = document.getElementById('courseCategories-wrap').getElementsByTagName('a')
		for(let index=0;index<allElements.length;++index){
			let element =  allElements[index]
			if(element !== currentElement){
				element.classList.remove('active')
			}
		}
	}
	const startCourse =  (id,slider=false,e=false)=> {

		if(slider){

			if(e){
				if(e !== 'start')
				handleActive(e)
			}else {
				navigate(`/Courses/CoursesDetails/${id}`)
				return false;
			}

			let url=`${window.API_URL}course_videos_list/${id}`;
			setLoading(false);
			SetFirstcoruse('')
			axios.get(url).then(({data})=>{
				if (data.videos) {
					const videos = data.videos.slice(0,4)
					setCourseVideos(videos)
					setFistvid(videos[0])
					if(courses.length > 0) {
						let targetCourse = courses.find(obj => obj.CourseID === id);
						SetFirstcoruse(targetCourse)
						SetTargetCourse(targetCourse)
						setCourseset(true)
						setLoading(true)
					}
						
				}
			}).catch((e) => {
			});
			return false;
		}
		if(usr && usr != 'undefined') {
			usr= JSON.parse(usr);
			let validDate = moment(usr.email_verified_at, moment.ISO_8601, true).isValid()

			if (!validDate){
				handleModal()
				return false;
			}
			navigate(`/Courses/CoursesDetails/${courseVideos[0]?.CourseID}?videoId=`+id)
		}
		else {
			handleModal()
		}
		

	}


	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	const css = `
	div[disabled]
	{
pointer-events: none;
opacity: 0.7;
}
#courseCategories-wrap .active{
color:white;
background:gray;
}

	`
	return (
		<div>
			<div className="main-content">
				{/*<NotFoundModal*/}
				{/*	show={show}*/}
				{/*	handleShow={handleShow}*/}
				{/*	handleClose={handleClose}/>*/}
			<style>
				{css}
			</style>
				{/*<LoginModal  handleModal={handleModal} showModal={showModal}/>*/}
				{ !usr && <LoginModal handleModal={handleModal} showModal={showModal}/>}
				{ usr && <EmailVerifyModal handleModal={handleModal} showModal={showModal}/>}
				{/* <div className="banner-wrap" style={{ 'backgroundImage': `url(${Banner})` }}> */}
				<div className="banner-wrap">
					<div className="banner-video">
						<video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
							<source src={BannerVideo} type="video/mp4" />
						</video>
					</div>
					<div className="banner-description">
						<div className="container">
							{/* <div className="line">1</div>  */}
							<div className="banner-info">
								<h2>Courses to Help <span>You Grow Your Business</span></h2>
								<div className="fragrance-growform">
									<div className="grow-form">
										<input type="text"  onBlur={()=>setTimeout(()=>setSearchResult(''),200)} autoComplete="off" value={searchText} onInput={handleCourseSearch} placeholder="search our courses" className="form-control" name="search2" />
										<button type="submit"><img src={Searchlogo} alt="logo" className="img-responsive" /></button>
									</div>
									{searchResult && <div hidden={hide} className="grow-formlist">

										{!searchResult.msg && <ul>
											{searchResult.map(result => <li><Link
												to={"/Courses/CoursesDetails/" + result.CourseID}>{result.course_title}</Link>
											</li>)}
										</ul>}
										{/*{searchResult.msg&& <ul>*/}
										{/*		<li className="text-center">We are always adding new content, <br/>Check back later.</li>*/}
										{/*	</ul>}*/}
									</div>}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="courseCategories-wrap" id="courseCategories-wrap">
				<div className="container">

					<Slider {...settings}>
						{
							courses.map((row, key) => (
								<div className="item" disabled={!loading} >

									<h2 style={{cursor:"pointer"}} key={key}><a  onClick={(e) => startCourse(row.CourseID,true,e)}>{row.course_title}</a></h2>

								</div>))}
					</Slider>
					<div className="ol-line"></div>
				</div>
			</div>
			<div className="category-info">
				<div className="container">
					<div className="row align-center">
						<div className="col-md-8">
						{/*{loading===true && courseset==true && <h3>{targetCourse.course_title}</h3>}*/}
						{firstcoruse && <h3>{firstcoruse.course_title}</h3>}
						{/*{loading && <h2><ContentLoader*/}
						{/*	speed={1}*/}
						{/*	width={100}*/}
						{/*	height={50}*/}
						{/*	viewBox="0 0 200 70"*/}
						{/*	backgroundColor="#b62322"*/}
						{/*	foregroundColor="#cd2a29"*/}
						{/*>*/}
						{/*	<rect x="0" y="0" rx="3" ry="3" r="" width="150px" height="100" />*/}
						{/*	/!*<rect x="48" y="8" rx="3" ry="3" r="10" width="1000" height="100" />*!/*/}

						{/*</ContentLoader></h2>}*/}
						{/*	{console.log(firstcoruse)}*/}
							<ul>
							{/*{loading===true && courseset==true && <li style={{textTransform:"none"}}>{targetCourse.description}</li>}*/}
							{firstcoruse && <li style={{textTransform:"none"}}>{firstcoruse.description}</li>}
							</ul>

							{loading===true && courseset==true &&<a style={{cursor:"pointer"}} onClick={() => startCourse(courseVideos[0]?.videoID)}>click to view Course</a>}
							{loading===true && courseset==false &&<a style={{cursor:"pointer"}} onClick={() => startCourse(courseVideos[0]?.videoID)}>click to view Course</a>}


						</div>

						<div className="col-md-4 img-right">
							{!loading && <div className="text-center" style={{width:"100%",height:"230px",background:"black"}}><img width="auto" style={{marginTop:"50px"}} height="" src={VideoPlaceHolder} /></div>}
						{loading===true && courseset==false &&<iframe width="100%" height="230" src="https://www.youtube.com/embed/fMI5KrK4SQQ?autoplay=1&rel=0&modestbranding=1&autohide=1&showinfo=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>}
							{loading===true &&fistvid!='' && courseset==true  && <iframe width="100%" height="230"  src={`https://www.youtube.com/embed/${fistvid?.embedlink}?autoplay=1&rel=0&modestbranding=1&autohide=1&showinfo=0`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>}

						</div>
					</div>
				</div>
			</div>
			<div className="courses-wrap sec-padding">
				<div className="container">

					<div className="row justify-content-center">
						{!loading && Array.from(Array(4), (e, i) =><div className="col-md-3" key={i}>
								<div className="course-item">
									<div className="thumbnail">
										<ContentLoader viewBox="0 5 110 70" >
											{/* Only SVG shapes */}
											<rect x="0" y="0" rx="5" ry="5" width="1000" height="1000"/>
											{/*<rect x="80" y="17" rx="4" ry="4" width="300" height="13" />*/}
											{/*<rect x="80" y="40" rx="3" ry="3" width="250" height="10" />*/}
										</ContentLoader>
									</div>
									<h4 className="text-center"><ContentLoader viewBox="0 0 380 70"
													   style={{margin: "-10px"}}>
										<rect x="70" y="17" rx="0" ry="0" width="250" height="20"/>
										<rect x="110" y="45" rx="0" ry="0" width="150" height="20"/>
									</ContentLoader></h4>


								</div>
							</div>
							)}
						{ loading && courseVideos && courseVideos.map(video =>{
							return(
								<div className="col-md-3" key={video.id}>
									<div className="course-item">
										<div className="thumbnail">
											{/*<i className="fa fa-play"></i>*/}
											<i className="fa fa-youtube-play" onClick={() => startCourse(video.videoID)}></i>
											<img style={{width:"auto",height:"170px",borderRadius:"0px"}} loading="lazy" src={"https://img.youtube.com/vi/"+video?.embedlink+"/maxresdefault.jpg"} />
											{/*<iframe width="100%" height="170"*/}
											{/*		src={"https://img.youtube.com/vi/"+video.embedlink+"/0.jpg"}*/}
											{/*		title="YouTube video player" frameBorder="0"*/}
											{/*		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
											{/*		allowFullScreen></iframe>*/}
										</div>
										<h4>{video.video_title} </h4>


									</div>
								</div>
							)
						})}
					</div>

				</div>
			</div>
			<div className="fragrance-u">
				<div className="container">
					<div className="fragrance-info border_overall_15">
						<h2>Fragrance University</h2>
						<h3>Over 40+ hours of content | Everything you need to start under 1 roof 100+ Videos | 20,000 Fragrances | over 50+ years of experience </h3>
					</div>
				</div>
			</div>
			<div className="main-categories sec-padding">
				<div className="container">
					<h3 className="after-bar"><span>Main Categories</span></h3>
					<div className="cst-row d-flex flex-wrap">
					{
					
					courses.map((row, key) => (
			        <div className="category-top-box "  key={key} style={{marginBottom:20,cursor:"pointer"}} onClick={() => startCourse(row.CourseID,true,false)}>
			          <div className="course-item">
			            <div className="thumbnail">
			              <img loading="lazy" src={`${window.ASSET_URL}${row.img}`} className="img-responsive" alt=""/>
						  <h4 >Starting your {row.course_title}</h4> 
			            </div>              
			          </div>
			        </div>))}
						
					</div>
				</div>
			</div>
			<div className="main-categories main-resources sec-padding pb-50">
				<div className="container">
					<h3 className="after-bar"><span>resources</span></h3>
					<div className="cst-row d-flex flex-wrap justify-content-center">

							<div className="category-top-box" style={{cursor:"pointer"}}>
								<div className="course-item">
									<div className="thumbnail">
										<Link className="text-decoration-none" to="/CalculatorComponent/FragranceCalculator">
										<img src={UsageCalIcon} className="img-responsive" alt="" />
										<h4 >Usage Calculator</h4>
										</Link>
									</div>
								</div>
							</div>


							<div className="category-top-box" style={{cursor:"pointer"}}>
								<div className="course-item">
									<div className="thumbnail">
										<a className="text-decoration-none" target="_blank" href="https://www.afi-usa.com/shop/index.cfm?">
										<img src={FragranceSearchIcon} className="img-responsive" alt="" />
										<h4 >Fragrance Search</h4>
										</a>
									</div>
								</div>
							</div>

						<div className="category-top-box" style={{cursor:"pointer"}}>
							<div className="course-item">
								<div className="thumbnail">
									<Link className="text-decoration-none" to="/CalculatorComponent/mass-convertor">
									<img src={ConverstionCalIcon} className="img-responsive" alt="" />
									<h4 >Conversion Calculator</h4>
									</Link>
								</div>
							</div>
						</div>
						<div className="category-top-box" style={{cursor:"pointer"}}>
							<div className="course-item">
								<div className="thumbnail">
									<Link className="text-decoration-none" to="/Categories/All_Ingredients/3">
									<img src={IngredientSIcon} className="img-responsive" alt="" />
									<h4 >Ingredient Search</h4>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default Bodycomponent;