import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import Loader from "react-js-loader";
import React, {useEffect, useState} from "react";

const CosmeticFunction = ({cosmeticFunc})=>{

    const params = useParams()
    const catId = params.id
    const catName = params.Name
    const [data,setData] = useState('')
    useEffect(()=>{
        setData(cosmeticFunc)
    },[cosmeticFunc])
    const css = `
    .item-link:hover{
    background:rgb(246 246 246);
    }
    `
    return(
        <div className="row">
            <style>{css}</style>
            {!data && <Loader type="spinner-cub" bgColor={"#c42625"} title={"Loading..."} color={'#c42625'} size={100} />}
            {data && data.map(cos =>
                <div  className="col-md-3 item-link" key={cos.ID}>
                    <Link  to={"/Categories/"+catName+"/"+catId+"/details/"+cos.ID} style={{textDecoration:"none"}}>
                        <h4  style={{borderBottom:"1px solid #e6e6e6"}}>{cos.inner_link_heading}</h4>
                    </Link>
                </div>
            )
            }
        </div>
    )
}

export default CosmeticFunction