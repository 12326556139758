import React from 'react';

const DataRetentionPolicy = () => {
    return (
        <div className="policy-container">
            <style>
                {css}
            </style>
            <h1>Data Retention Policy</h1>
            <p>
                Aromatic Fragrances International seeks to ensure that it retains only data necessary to effectively conduct its program activities and work in fulfillment of its mission. The need to retain data varies widely with the type of data and the purpose for which it was collected. Aromatic Fragrances International strives to ensure that data is only retained for the period necessary to fulfill the purpose for which it was collected and is fully deleted when no longer required. This policy sets forth Aromatic Fragrances International’s guidelines on data retention and is to be consistently applied throughout the organization.
            </p>
            <h2>Scope</h2>
            <p>
                This policy covers all data collected by Aromatic Fragrances International and stored on Aromatic Fragrances International owned or leased systems and media, regardless of location. It applies to both data collected and held electronically (including photographs, video and audio recordings) and data that is collected and held as hard copy or paper files. The need to retain certain information may be mandated by federal or local law, federal regulations and legitimate business purposes, as well as the EU General Data Protection Regulation (GDPR).
            </p>
            <h2>Reasons for Data Retention</h2>
            <p>
                Aromatic Fragrances International retains only that data that is necessary to effectively conduct its program activities, fulfill its mission and comply with applicable laws and regulations.
            </p>
            <ul>
                <li>Providing an ongoing service to the data subject (e.g. sending a newsletter, publication or ongoing program updates to an individual, ongoing training or participation in Aromatic Fragrances International’s programs, processing of employee payroll and other benefits)</li>
                <li>Compliance with applicable laws and regulations associated with financial and programmatic reporting by Aromatic Fragrances International to its funding agencies and other donors</li>
                <li>Compliance with applicable labor, tax and immigration laws</li>
                <li>Other regulatory requirements</li>
                <li>Security incident or other investigation</li>
                <li>Intellectual property preservation</li>
                <li>Litigation</li>
            </ul>
            <h2>Data Duplication</h2>
            <p>
                Aromatic Fragrances International seeks to avoid duplication in data storage whenever possible, though there may be instances in which for programmatic or other business reasons it is necessary for data to be held in more than one place. This policy applies to all data in Aromatic Fragrances International’s possession, including duplicate copies of data.
            </p>
            <h2>Retention Requirements</h2>
            <p>
                Aromatic Fragrances International has set the following guidelines for retaining all personal data as defined in the Institute’s data privacy policy.
            </p>
            <ul>
                <li>Website visitor data will be retained as long as necessary to provide the service requested/initiated through the Aromatic Fragrances International website.</li>
                <li>Contributor data will be retained for the year in which the individual has contributed and then for [Duration] after the date of the last contribution. Financial information will not be retained longer than is necessary to process a single transaction.</li>
                <li>Event participant data will be retained for the period of the event, including any follow up activities, such as the distribution of reports, plus a period of [Duration];</li>
                <li>Program participant data (including sign-in sheets) will be retained for the duration of the grant agreement that financed the program plus any additional time required under the terms of the grant agreement.</li>
                <li>Personal data of subgrantees, subcontractors, and vendors will be kept for the duration of the contract or agreement.</li>
                <li>Employee data will be held for the duration of employment and then [Duration] after the last day of employment.</li>
                <li>Data associated with employee wages, leave, and pension shall be held for the period of employment plus [Duration], with the exception of pension eligibility and retirement beneficiary data which shall be kept for [Duration].</li>
                <li>Recruitment data, including interview notes of unsuccessful applicants, will be held for [Duration] after the closing of the position recruitment process.</li>
                <li>Consultant (both paid and pro bono) data will be held for the duration of the consulting contract plus [Duration] after the end of the consultancy.</li>
                <li>Board member data will be held for the duration of service on the Board plus for [Duration] after the end of the member’s term.</li>
                <li>Data associated with tax payments (including payroll, corporate, and VAT) will be held for [Duration].</li>
                <li>Operational data related to program proposals, reporting, and program management will be held for the period required by the Aromatic Fragrances International donor, but not more than [Duration].</li>
            </ul>
            <h2>Data Destruction</h2>
            <p>
                Data destruction ensures that Aromatic Fragrances International manages the data it controls and processes it in an efficient and responsible manner. When the retention period for the data as outlined above expires, Aromatic Fragrances International will actively destroy the data covered by this policy. If an individual believes that there exists a legitimate business reason why certain data should not be destroyed at the end of a retention period, he or she should identify this data to his/her supervisor and provide information as to why the data should not be destroyed. Any exceptions to this data retention policy must be approved by Aromatic Fragrances International’s data protection officer in consultation with legal counsel. In rare circumstances, a litigation hold may be issued by legal counsel prohibiting the destruction of certain documents. A litigation hold remains in effect until released by legal counsel and prohibits the destruction of data subject to the hold.
            </p>
        </div>
    );
}

export default DataRetentionPolicy;

const css = `.policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Bebas Neue Pro",sans-serif;
}

.policy-container h1 {
  margin-bottom: 20px;
}

.policy-container h2 {

  margin-top: 30px;
  margin-bottom: 10px;
}

.policy-container p {
  margin-bottom: 15px;
}

.policy-container ul {
  margin-bottom: 15px;
  padding-left: 20px;
  list-style-type: square;
}

.policy-container li {
  margin-bottom: 5px;
}

`
