import {useLocation, useParams} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import FragranceDetailGeneralNew from './FragranceDetailGeneral';
import axios from './../../../../helpers/axios';
import Pagination from './../../../Widgets/Pagination';
function CategoryDetails() {
	const params = useParams()
	const location = useLocation();

	const [pagination, setPagination] = useState([]);
	const [loading, setLoading] = useState(false);
	const [offset] = useState(10);
	const [query, setQuery] = useState('');
	const [letter,setLetter] = useState('')
	// let alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

	useEffect(() => {
		setQuery('');
		pagination.current_page=1
		getData('');
		resetLatterPagination()
	}, [params.id]);

	let alphabet = 'abcdefghi'.split('');
	let alphabet1 = 'jklmnopq'.split('');
	let alphabet2 = 'rstuvwxyz'.split('');
	const handleAlphabetPagination = (e,letter)=>{
		pagination.current_page=1
		setLetter(letter)
		getData(letter)
		handleActive(e)
	}
	const handleActive = (e)=>{
		 e.target.classList.add('active')
		let currentElement = e.target
		currentElement.classList.add('active')
		let allElements = document.getElementsByClassName('page-link');
		for(let index=0;index<allElements.length;++index){
			let element =  allElements[index]
			if(element !== currentElement){
				element.classList.remove('active')
			}
		}
	}
	const getData =(letter)=> {
		if (letter==='a'){
			resetLatterPagination()
		}

		let url;
		if(query===''){
			url=`${window.API_URL}Fragrances/getCategoriesDetails/`+params.id+'?page=' +
			pagination.current_page+'&letter='+letter;
		}else{
			url=`${window.API_URL}Fragrances/getCategoriesDetails/`+params.id+'?search=' +
			query+'&page='+pagination.current_page+'&letter='+letter;
		}
		setLoading(false);
		axios.get(url).then(response => {
			let Pagination = response.data.getCatDetails;
			// setPagination({data:response.data.getCatDetails})
			setPagination(Pagination)

			setLoading(true);
		}).catch((e) => {

		});

	}
	const handleSubmit = (event) => {
		event.preventDefault();
		pagination.current_page=1
		getData('');
		resetLatterPagination()
	}

	const resetLatterPagination = ()=>{
		let els = document.querySelector('.pagination')
		els.getElementsByClassName('active')[0]?.classList.remove('active')
		let els1 = document.querySelector('.pagination1')
		els1.getElementsByClassName('active')[0]?.classList.remove('active')
		let els2 = document.querySelector('.pagination2')
		els2.getElementsByClassName('active')[0]?.classList.remove('active')
	}


	const css = `
 .pagination .active{
	color: white!important;
	margin-bottom:0px;
	}
	.pagination {
	margin-bottom:0px;
	display: inline-flex;
	}
	.page-link {
    border: 1px solid;
    margin: 3px;
    background: #f0f0f0;
    font-size: 13px;
    border-radius: 0px;
    font-weight: 400;
    padding: 2px 6px;
}
.page-link:hover {
    background:#c42625;
    color:white!important;
}
	`
	return (

			<div className='cat-wrap'>
				<style>
					{css}
				</style>
				<section className="gray-bar">
					<div className="container">
						<h3>{params?.Name?.replaceAll('_',' ')}</h3>
					</div>
				</section>
				<div className="container">
					<div className="row flex-directions pagination-wrap">
						<div className="col-md-6">
							<Pagination pagination={pagination} offset = {offset} paginate={()=>getData(letter)}/>
							<div className=" right-custom-search custom-1200">
								<div className="row d-flex ">
									<form className="form-inline float-right mt-3 mb-0" onSubmit = {handleSubmit }>
										<div className="input-wrap">
											<input
												type="search"
												className="form-control mb-2 mr-sm-2"
												id="search"
												onChange={(event)=>{
													setQuery(event.target.value)
												}}
												value={query}
											/>
										</div>
										<div className="button-wrap">
											<button
												className="btn btn-danger mb-2 mr-sm-2"
												type="submit"
											>
												Go
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-md-6 mt-3 text-center">
							<nav aria-label="Page navigation example ">
								<ul className="pagination">
									{alphabet.map((letter,i)=><li className="page-item">
										<button className={"page-link btn btn-danger text-danger "}
												onClick={(e)=>handleAlphabetPagination(e,letter)} style={{textTransform:"capitalize"}} >
											{letter}
										</button></li>)}

								</ul>
							</nav>
							<nav aria-label="Page navigation example " className="text-center">
								<ul className="pagination pagination1">
									{alphabet1.map((letter,i)=><li className="page-item">
										<button className={"page-link btn btn-danger text-danger "}
												onClick={(e)=>handleAlphabetPagination(e,letter)} style={{textTransform:"capitalize"}} >
											{letter}
										</button></li>)}

								</ul>
							</nav>
							<nav aria-label="Page navigation example ">
								<ul className="pagination pagination2" >
									{alphabet2.map((letter,i)=><li className="page-item">
										<button className={"page-link btn btn-danger text-danger "}
												onClick={(e)=>handleAlphabetPagination(e,letter)} style={{textTransform:"capitalize"}} >
											{letter}
										</button></li>)}

								</ul>
							</nav>
						</div>

					</div>
					{/*<div className="row mt-4">*/}


					{/*</div>*/}
				</div>
				<FragranceDetailGeneralNew catDetails={pagination.data} loading={loading} ID={params.id}/>



			</div>

	)
}
export default CategoryDetails;