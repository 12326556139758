import {Button, Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import { Widget } from 'react-typeform-embed';

function SurveyModal({show,handleClose}){

    const [survey,setSurvey] = useState('');

    useEffect(()=>{
        getSurvey()
    },[])

    const getSurvey = ()=>{
        axios.get(window.API_URL+'survey/active')
            .then(({data})=>{
                setSurvey(data.survey_code)
            })
    }

    return(
        <>
            <Modal show={show}   onHide={handleClose} backdrop="static">
                <Modal.Header style={{background:"#c41425",color:"#fff"}} closeButton>
                    <Modal.Title><h3>Fragrance That's Right For You</h3></Modal.Title>
                </Modal.Header>
                <Modal.Footer style={{display:'block',padding:"0px"}}>
                    <div className="row m-0">
                        <div className="col-md-12 p-0">
                            <Widget id={survey} height={500}  />
                            {/*<object type="text/html" data={survey} style="width:100%; height:100%"/>*/}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SurveyModal;