import Slider from "react-slick";
import {Col, Row} from "react-bootstrap";
import infoImgTwo from "../../../../../assets/img/info-img2.png";
import {Link} from "react-router-dom";
import infoimageOne from "../../../../../assets/img/info-img1.png";
import React, {useEffect, useState} from "react";
import VideoModal from "./VideoModal";
import Loader from "react-js-loader";
import "./VideoSlider.css";

function VideosSlider ({giveawayVideos,handleVideoModal}){

    const [videos,setVideos] = useState('')


    useEffect(()=>{
        setVideos(giveawayVideos)
    },[giveawayVideos])

    return(<section className="fragrance-giveaway">
            <div className="container">
                <Slider {...settings} style={{textAlign:"center"}}>
                    {videos && videos.map(video=><div className="fragrance-giveaway-slider">
                        <Row>
                            <Col lg="6" md={6} sm={12}>
                                <div className="giveaway-slider-image">
                                    <img
                                        onClick={() => handleVideoModal(video)}
                                        role="button"
                                        src={(video?.thumbnail?.includes('image/') ? window.ASSET_URL+video.thumbnail:`https://img.youtube.com/vi/${video.embedlink}/${(video.thumbnail ?video.thumbnail:'maxres2.jpg')}`)}
                                        alt="image"/>
                                    {/*<img  role="button" src={`https://img.youtube.com/vi/${video.embedlink}/maxres2.jpg`} alt="image"/>*/}
                                    {/*<img src={infoImgTwo} alt="image"/>*/}
                                </div>
                            </Col>
                            <Col lg="6" md={6} sm={12}>
                                <div className="giveaway-slider-detail">
                                    <h2>{video.video_title}</h2>
                                    <h3>{video.description.substr(0,60)}...</h3>
                                    <div className="giveaway-slider-buttons">
                                        <button
                                            className="btn resetbht giveaway-btn "
                                            onClick={ ()=>handleVideoModal(video)}
                                        >
                                            Watch Now</button>
                                        <a href={video.giveaway_link} target="_blank" className="btn resetbht giveaway-btn ">Enter Here</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>) }

                    {!videos && <div className="fragrance-giveaway-slider">
                        <Row>

                            <Col lg="12" md={12} sm={12}>
                                 <div style={{padding: "50px"}}>
                                    <Loader
                                        type="spinner-cub"
                                        bgColor={"#ffffff"}
                                        title={`Loading Slider Videos...`}
                                        color={'#e7e7e7'}
                                        size={100}/>
                                </div>
                            </Col>
                        </Row>
                    </div>}
                    {/*<div className="fragrance-giveaway-slider">*/}
                    {/*    <Row>*/}
                    {/*        <Col lg="6" md={6} sm={12}>*/}
                    {/*            <div className="giveaway-slider-image">*/}
                    {/*                <img src={infoimageOne} alt="image"/>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col lg="6" md={6} sm={12}>*/}
                    {/*            <div className="giveaway-slider-detail">*/}
                    {/*                <h2>AFI’s Giveaways</h2>*/}
                    {/*                <h3>Every month we give one of you $1,000 in FREE Fragrance!</h3>*/}
                    {/*                <div className="giveaway-slider-buttons">*/}
                    {/*                    <a href="#" className="btn resetbht giveaway-btn ">Watch Now</a>*/}
                    {/*                    <Link to="#" className="btn resetbht giveaway-btn ">Enter Here</Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}
                    {/*<div className="fragrance-giveaway-slider">*/}
                    {/*    <Row>*/}
                    {/*        <Col lg="6" md={6} sm={12}>*/}
                    {/*            <div className="giveaway-slider-image">*/}
                    {/*                <img src={infoImgTwo} alt="image"/>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col lg="6" md={6} sm={12}>*/}
                    {/*            <div className="giveaway-slider-detail">*/}
                    {/*                <h2>AFI’s Giveaways</h2>*/}
                    {/*                <h3>Every month we give one of you $1,000 in FREE Fragrance!</h3>*/}
                    {/*                <div className="giveaway-slider-buttons">*/}
                    {/*                    <a href="#" className="btn resetbht giveaway-btn ">Watch Now</a>*/}
                    {/*                    <Link to="#" className="btn resetbht giveaway-btn ">Enter Here</Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}
                </Slider>
            </div>
        </section>)
}
export default VideosSlider

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1
};