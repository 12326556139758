import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useParams} from 'react-router-dom';
import Loader from 'react-js-loader'
import BackArrow from "../../../assets/img/arrow-back.gif";
import Viewer from 'miew-react'

export default function OnClickImage() {


    const params = useParams();
    const [frag_supp_spon_on_click_img, setfragSuppSponOnClick] = useState('')
    const [imgDetail, setImgDetail] = useState('')
    const [d3, setD3] = useState(false)
    const [url,setUrl] = useState('')

    useEffect(() => {
        fetchFragSuppSponOnClick()
    }, [])

    const handleStyle = ()=>{
       const elem = document.getElementsByClassName("atom-info");
       if(elem){
           setTimeout(()=>{
              const opacity = window.getComputedStyle(elem[0]).opacity
               if(opacity === '1'){
                   document.getElementById("table").style.marginTop = "120px";
               }else {
                   document.getElementById("table").style.marginTop = "0px";
               }
           },10)


       }

    }

    const handleLoadMole = useCallback((prop)=>{
        // let cas = '1000284-53-6'
         if (imgDetail) {
             const cas = imgDetail?.value.replace('3D/inchi','').trim()
             let url = window.ASSET_URL + `moles/${cas}.mol`
             // url = 'http://localhost:/FUBackEnd/public/mole.pdb'
             // console.log(url)
             var xhr = new XMLHttpRequest();
             xhr.open('GET', url, true);
             xhr.responseType = 'blob';
             xhr.onload = function (e) {
                 if (this.status == 200) {
                     var myBlob = this.response;
                     prop.load(myBlob, {sourceType: 'file', fileType: 'pdb'})
                 }
             };
             xhr.send();
         }


    },[imgDetail])

    const fetchFragSuppSponOnClick =  () => {
        axios.get(`${window.API_URL}frag_supp_spon_on_click/${params.detail_id}`)
            .then(({data}) => {
                // setUrl(window.ASSET_URL + 'moles/' + data?.imgDetail?.value.replace('3D/inchi', '').trim() + '.pdb')
                setUrl( data?.imgDetail?.value.replace('3D/inchi', '').trim())
                setfragSuppSponOnClick(data.data)
                setImgDetail(data.imgDetail)

                setD3(true)
            })
    }  


    return (
        <div className="container">
            <h3 className=" text-center" style={{background:"#c42625",color:"#fff", padding:"5px 0"}}><span className="m-5">{params.detail_name}</span></h3>
            <div className="row justify-content-center">
                <div className="col-md-1"></div>
                <div className="col-md-10 col-sm-12 col-12 container-video" >
                    {d3 && <div className="mien-canvas-graph" onClick={handleStyle}> <Viewer
                        options={{
                            load: window.API_URL+'proxyUrltoLoad?case='+url
                        }}
                            /> </div>}
                </div>
                <div className="col-md-1 col-sm-12 col-2 backbutton-space">
                    <Link to={`/Categories/${params.name}/${params.id}/RawMaterialsDetails/${params.detail_id}`} className="btn  backbutton btn-dark pull-right form-control bg-white "
                          style={{backgroundImage:`url(${BackArrow})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'left',color:"black"}}>
                        <span style={{paddingLeft:"20px"}}>Back</span>
                    </Link>

                </div>
                <div className="col-md-1"></div>
                <div className='col-md-10 col-sm-12'>
                    { !frag_supp_spon_on_click_img && <div style={{padding:"50px"}}>
                        <Loader
                            type="spinner-cub"
                            bgColor={"#c42625"}
                            title={`Loading ${params.detail_name}...`}
                            color={'#c42625'}
                            size={100} />
                    </div>}

                    <table id="table" className="table table-striped" style={{width: "100%", tableLayout: "fixed"}}>
                        <tbody>
                        {frag_supp_spon_on_click_img &&   frag_supp_spon_on_click_img.map((row, key) =>row?.value && row?.value !=='Right Click Jmol Molecule For More Options. Jmol is a free download found Here.'
                            &&  <tr key={key}>
                                <td style={{wordWrap: "break-word"}}>
                                    {row.keys=="" &&
                                    <p>
                                        <a href={row.link} target="_blank" className="text-decoration-none">{row?.value}</a>
                                    </p>
                                    }
                                    {row.keys!=="" &&
                                    <p style={{fontSize:"18px"}}>
                                        <strong style={{fontWeight:"900"}}>{row.keys}:</strong>{row?.value}
                                    </p>
                                    }
                                </td>
                            </tr>)
                        }
                        </tbody>
                    </table>



                </div>
                <div className="col-md-1">

                </div>
            </div>
        </div>
    )
}