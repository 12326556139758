import React from 'react';

import FragranceCalculatorComponent from './FragranceCalculatorComponent';
import LyeCalculatorComponent from './LyeCalculatorComponent';
import CandleCalculatorComponent from './calculator/candle-calculator/CandleCalculatorComponent';
import MassConvertor from "./calculator/mass-convertor/MassConvertor";
import {Routes, Route, NavLink, Navigate} from 'react-router-dom';

function CalculatorComponent() {
	const css = `
	.calc-container .fragrancecalculator-section h3{
	padding: 16px 16px 5px 16px;
    color:white!important;
	background: #c42625;
	margin: 0 0 20px;
	text-align: center;
	}
	.card{margin-bottom: 15px;}
	`
	return (
		<div>
			<style>
				{css}
			</style>
		<div>	
		</div>		
		<div>
			<div className="calc-section">
			<div className="container calc-container">
					<div className="row fragrancecalculator-section">
						<div className="col-md-12 " style={{padding:"0px"}}>
							<h3 className="">Calculators <p>Calculate your fragrance for the perfect batch</p></h3>
						</div>
						<div className='tabs-button-wrap'>
						{/* <input className="button" type="button" value="FRAGRANCE"/> */}
						<NavLink to='/CalculatorComponent/FragranceCalculator' type="button"   className="nav-link btn btn-outline-danger button">Fragrance</NavLink>
						<NavLink to='/CalculatorComponent/LyeCalculator' type="button"  className="nav-link btn btn-outline-danger button1">Lye</NavLink>
						<NavLink to='/CalculatorComponent/CandleCalculator' type="button"   className="nav-link btn btn-outline-danger button2">Candle</NavLink>
						<NavLink to='/CalculatorComponent/mass-convertor' type="button"  className="nav-link btn btn-outline-danger button3">Mass Convertor</NavLink>
						</div>
								
					</div>
					<Routes>            

						<Route path="/CalculatorComponent/FragranceCalculator" element={<FragranceCalculatorComponent/>}/>

						<Route path="/CalculatorComponent/LyeCalculator" element={<LyeCalculatorComponent/>}/>

						<Route path="/CalculatorComponent/CandleCalculator" element={<CandleCalculatorComponent/>}/>

						<Route path="/CalculatorComponent/mass-convertor" element={<MassConvertor/>}/>
						<Route path="/*" element={<Navigate to='/not-found'/>}/>

					</Routes>					

					{/* <LyeCalculatorComponent/> */}
				</div>					
			</div>
		</div>
		</div>
	);
}
export default CalculatorComponent;