import Viewer,{} from 'miew-react'

import {useCallback, useEffect, useRef, useState} from "react";
const Molecule3dView = ()=>{
   const [miewObj,setMiewObj] = useState('')
    const reff = useRef(null)
    // const helloInit = (props)=>{
    //    testing(props)
    // }
    const testing = ()=>{
    }
    const handleLoadMole = useCallback((prop)=>{
        const url = window.ASSET_URL+'moles/1000284-53-6.mol'
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function(e) {
            if (this.status == 200) {
                var myBlob = this.response;
                prop.load(myBlob,{sourceType:'file',fileType:'mol'})
            }
        };
        xhr.send();
    },[])

    return(
       <div className="container">
           <div className="row">
               <div className="col-md-12 " style={{height:"500px"}}>
                   <button onClick={testing}>test</button>
                   <Viewer onInit={handleLoadMole}/>
               </div>
           </div>

       </div>
    )
}
export default Molecule3dView