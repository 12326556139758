import {Modal,Button} from "react-bootstrap";
import React, {useState} from "react";
import Logo from '../../../../assets/img/logo.png'
import {Link} from "react-router-dom";

const EmailVerifyModal = ({handleModal,showModal})=>{

    return (
        <>

            <Modal
                show={showModal}
                onHide={handleModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="text-white " style={{background:"#c42625",height:"50px"}}>
                    <Modal.Title> <i className="fa fa-ban"></i> Email Verification Required</Modal.Title>
                </Modal.Header>
                <Modal.Footer style={{padding:"30px",marginBottom:"30px",display:"block"}}>
                    <div className="row text-center" >
                        <div className="col-md-12">
                            {/*<img src={Logo} alt="logo" style={{height:"120px",width:"auto"}} className="img-responsive" />*/}
                            <i className="fa fa-envelope" style={{fontSize:"100px"}}></i>
                        </div>
                        <div className="col-md-10 offset-1" >
                            <h3>Your Email is not Verified, You cannot access this feature.</h3>
                        </div>
                        <div className="col-md-12">
                            <Link to={'/user/profile'} className="btn btn-outline-info">Verify Email</Link>
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EmailVerifyModal