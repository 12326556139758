import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import infoimageOne from "../../../../../assets/img/info-img1.png";
import React, {useEffect, useState} from "react";
import Loader from 'react-js-loader'
import VideoModal from "./VideoModal";


function AllThingsFragrance ({topVideos,handleVideoModal}){

    const [videos,setVideos] = useState('')
    const [showAll,setShowAll] = useState(true)

    useEffect(()=>{
        const tempVideos = topVideos
        setVideos(tempVideos?.slice(0,3))
    },[topVideos])

    const handleShowAll = ()=>{
        if(topVideos) {
            let allVideos = [...topVideos]

            if (showAll) {
                setVideos(allVideos)
            } else {
                setVideos(allVideos?.slice(0, 3))
            }
            setShowAll(!showAll)
        }

    }

    return(
        <section className="fragrance-info-things">
            <style>{css}</style>
            <div className="container">
                <div className="info-things-title">
                    <h1>ALL THINGS FRAGRANCE</h1>
                </div>
                <Row className="info-things-wrap justify-content-center">
                    {!videos && <div style={{padding: "50px"}}>
                        <Loader
                            type="spinner-cub"
                            bgColor={"#ffffff"}
                            title={`Loading DIY Videos...`}
                            color={'#e7e7e7'}
                            size={100}/>
                    </div>}
                    {videos && videos.map(video=><Col key={video.videoID} lg={4} md={4} sm={12}>
                        <div className="info-things-column">
                            <div className="info-things-image">
                                <img
                                    loading="lazy"
                                    onClick={() => handleVideoModal(video)}
                                    role="button"
                                    src={(video?.thumbnail?.includes('image/') ? window.ASSET_URL+video.thumbnail:`https://img.youtube.com/vi/${video.embedlink}/${(video.thumbnail ?video.thumbnail:'maxresdefault.jpg')}`)}
                                    alt="image"/>
                            </div>
                            <div className="info-things-detail">
                                <h4 onClick={()=>handleVideoModal(video)} role="button">{video.video_title}</h4>
                                <p onClick={()=>handleVideoModal(video)}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={video.VideoId}>{video.description}</Tooltip>}
                                    >
                                      <span className="d-inline-block">
                                       <p role="button">{video.description.substr(0,30)}...</p>
                                      </span>
                                    </OverlayTrigger>
                                </p>
                            </div>
                        </div>
                    </Col>)}
                </Row>
                <div className="info-things-seemore">
                    <Button className="seemore-btn" onClick={handleShowAll}>{ showAll ? 'See More' : 'See Less'}</Button>
                </div>
            </div>
        </section>
    )
}
export default AllThingsFragrance

const css = `
.popover-title{
    background: red!important;
}
`