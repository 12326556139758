import {Link} from "react-router-dom";
import TableLoader from "../../../../Widgets/TableLoader";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import BackArrow from "../../../../../assets/img/arrow-back.gif";
import Pagination from "../../../../Widgets/Pagination";
import Loader from "react-js-loader";


const CosmeticDetail = ({categoryDetails,getData,pagination,offset,catId,handleSearch,keyword})=>{

    const params = useParams();

    const url = '/Categories/'+params.Name+'/'+params.id+'/RawMaterialsDetails/'
    let [loader,setLoader] = useState(false)
    setTimeout(()=>{
        setLoader(true)
    },400)
    return(
        <div className="container">
            <div className="row">
            <div className="col-md-12 text-white" style={{background:"#c42625"}}>
                <h3  className="text-white">Cosmetic Ingredients Listing Cosmetic</h3>
            </div>
                <div className="col-md-6">
                    <Pagination pagination={pagination} offset={offset} paginate={()=>getData(pagination.current_page,keyword)} />
                </div>
                <div className="col-md-6 right-custom-search custom-1200">
                    <div className="row d-flex right-align">
                        <form className="form-inline float-right mt-3 mb-0" onSubmit={handleSearch}>
                            <div className="input-wrap">
                                <input
                                    type="search"
                                    className="form-control mb-2 mr-sm-2"
                                    id="search"
                                />
                            </div>
                            <div className="button-wrap">
                                <button
                                    className="btn btn-danger form-control mb-2 mr-sm-2"
                                    type="submit"
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </form>
                        <div className="col-md-3">
                            <div className="" >
                                <Link to={'/Categories/Cosmetic_Functions/'+catId} className="btn btn-dark form-control pull-right bg-white  mb-1"
                                      style={{backgroundImage:`url(${BackArrow})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'left',color:"black"}}>
                                    <span style={{paddingLeft:"20px"}}>Back</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


            <div className="col-md-12">
                <div className="row">
                    {!categoryDetails && <div style={{padding:"100px"}}>
                        <Loader type="spinner-cub" bgColor={"#c42625"} title={"Loading Data..."} color={'#c42625'} size={100} />
                    </div>}
                    { categoryDetails && categoryDetails.map((detail,i) => <div className="col-md-6 mt-2" key={detail.id}>

                        <div className="inner-ingredients-info-box" style={{height:"100%"}}>
                            <h5>
                                <span    className="text-black-50">{detail.pre_text}</span>
                                <Link
                                    to={url+detail.id+'?index=true'}
                                    state={{ maintabid: `${params.id}`, ID: `${detail.id}`, rawMaterialName:`${detail.link_text}` }}
                                >
                                    {detail.link_text}
                                </Link>
                            </h5>
                            <span>
                                <p>
                                    <div>
                                       {detail.post_text}
                                    </div>
                                </p>
                            </span>
                        </div>


                            </div>)


                    }

                </div>
            </div>

        </div>
        </div>


)
}


export default CosmeticDetail