import Swal from 'sweetalert2'

export function Toast (type,msg){
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
    })
   return  Toast.fire({
        toast: true,
        icon: type,
        title: msg
    })
}


export function Logintost(msg){
  Swal.fire({
    position: 'center',
    icon: 'error',
    text:msg,
    showConfirmButton: false,
    timer: 1700
  })
  
}

export function SwalBefore(title,msg=''){

    Swal.fire({
        title: title,
        text:msg,
        imageUrl: "images/ajaxloader.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
    });
    Swal.showLoading()

}

export function SwalAfter(icon,title,msg,confirmBtn=false){
    Swal.fire({
        icon: icon,
        title: title,
        text: msg,
        showConfirmButton: confirmBtn,
        timer: 1000
    });
}





export function toastCss (){
   return (  `
	.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}`);
}