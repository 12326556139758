import React from 'react';
import axios from './../../../helpers/axios';
import './Head.css';
import {NavDropdown, Navbar, Nav, Container, Dropdown} from "react-bootstrap";
import { Link,NavLink} from 'react-router-dom';
import Logo from '../../../assets/img/logo.png'
import avatarPlaceholder from "../../../assets/img/avatar-placeholder.png";
import Swal from "sweetalert2";
import {LogoutAction} from "../../../redux/actions/AuthActions";
import {useNavigate} from "react-router";
import {connect, useDispatch} from "react-redux";


class Head extends React.Component {
  state = {
    Categories: [],
    loading: false,
    open: false,
    user:'',
    userImage:'',
    keyword:'',
    _searchType:'name'
  }

  componentDidMount() {
    let user = localStorage.getItem('user');
    user = JSON.parse(user)
    let userImage = window.ASSET_URL+user?.avatar

    this.setState({user,userImage,keyword:''})

    setTimeout(()=>{
      let element = document.getElementsByClassName("dropdown-basic-head")
      element[0]?.classList.remove('dropdown-toggle')
    },10)

    axios.get(`${window.API_URL}Fragrances`)
      .then(res => {
        this.setState({
          Categories: res.data.getCategories,
          loading: true
        });
      })

    /*window.addEventListener('scroll', () => {
      let activeClass = 'sticky-header';
      if(window.scrollY === 0){
        activeClass = '';
      }
      this.setState({ activeClass });
    });*/

  }


  constructor(props) {
    super(props);
    this.state = {
      Categories: null
    };
  }

  reloadImage = (img) => {
    img.onerror = null
    this.setState({userImage:avatarPlaceholder})
  }

    handleLogout = (e)=>{
    const {dispatch,navigate} = this.props
    Swal.fire({
      title: 'Please Confirm..',
      text: "You are Signing Out",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(LogoutAction(navigate));
        // logout()
      }
    })
  }

  handleSearch = (e)=>{
    if(e.target.name === '_searchType'){
      this.setState({_searchType:e.target.value})
    }else {
      this.setState({keyword:e.target.value})
    }
  }

  hotJar = ()=>{
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments)

      };
      h._hjSettings = {hjid: 3279489, hjsv: 6};
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }



  css = `
  .navbar-nav .active{
  background:#000!important;
  color:white!important;
  }
  .dropdown-basic-head a:hover{
  background:white;
  }
  `

  
  propTypes() {
    return {
      children: React.PropTypes.any,
    }
  }

  render() {

    this.hotJar()
    const {user,userImage,keyword,_searchType} = this.state

    let catdropdown = "";
    if (!this.state.loading) {
      catdropdown = 'lodaing...!';
    } else {
      catdropdown = this.state.Categories.map((item,i) => {
        // return(<Dropdown.Item eventKey={item.id}>{item.TabName}</Dropdown.Item>);
          return (<>{i===0?<a className="fragrances-icon" key={item.id} target="_blank" href="https://www.afi-usa.com/shop/">Fragrances</a>:'' }

            {(item.id == '21' ?(
                <a className="fragrances-icon" key={item.id} target="_blank" href="https://www.afi-usa.com/shop/" >Fragrances</a>
            ) : ( item.id != '34' &&
                <NavDropdown.Item key={item.id}> <Link key={item.id}
                to={{pathname: `/Categories/${item.TabName.replace(/\s/g, '_')}/${item.id}`}}
                state={{CatName: `${item.TabName}`, ID: `${item.id}`}}
                data={{CatName: `${item.TabName}`, ID: `${item.id}`}}
                ref={this.Head}>
              {item.TabName}
            </Link>  </NavDropdown.Item>
            ))}

          </>);
        // return (<a className="catLinkDropDown" href={window.Full_url+'/Categories/'+`${item.TabName.replace(/\s/g, '_')}/${item.id}`}>{item.TabName}</a>)
      });
    }


    return (
      <section className={this.state.activeClass}>
        <header>
          <div className="top-header">
            <div className="container">
              <div className="row align-center">
                <style>{this.css}</style>
                <div className="col-md-2 col-sm-3 col-lg-2">
                  <div className="logo-wrap">
                    <Link to='/'>
                      <img src={Logo} alt="logo" className="img-responsive" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-9">
                  <div className="row header-head">
                    <div className="col-sm-12 col-md-12 col-lg-7 pl-0 text-wrap">
                      <div className="header-info">
                        <h2>fragrance university</h2>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7 col-lg-5 head-searchbar">
                      <div className="search-wrap">
                        <form action="" onSubmit={e=>e.preventDefault()}>
                          <select name="_searchType" className="form-select shadow-none " defaultValue={_searchType} onChange={this.handleSearch}  id="">
                            <option value="name"> Name Search </option>
                            <option value="cas"> CAS, Name,  EINECS, FEMA, FLAVIS</option>
                            <option value="jecfa"> JECFA</option>
                            <option value="odor"> Odor (Odour)</option>
                            <option value="flavor"> Flavor (Flavour)</option>
                          </select>
                          <input type="search" onInput={this.handleSearch} className="shadow-none" value={keyword} placeholder="Search..." name="search2" />
                          <Link to={"/search?keyword="+keyword+'&searchType='+(_searchType==undefined?'name':_searchType)} ><button><i className="fa fa-search"></i></button></Link>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="menu-wrap">
                    <div className='for-mobile'>
                      <Navbar expand="lg">
                          <Navbar.Toggle aria-controls="basic-navbar-nav" />
                          <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                              <NavDropdown title="Categories">
                                {catdropdown}
                                {/* <Link to='/Coursescomponent'>courses</Link>  
                              <Link to='/FragranceInfo'>fragrance info</Link> 
                              <Link to='/CalculatorComponent'>Calculators</Link>  */}
                              </NavDropdown>
                              <NavLink to='/Courses' className="nav-link">courses</NavLink>
                              <NavLink to='/FragranceInfo' className="nav-link">fragrance info</NavLink>
                              <NavLink to='/CalculatorComponent' className="nav-link">Calculators</NavLink>
                              {!user && <NavLink to='/login' className="nav-link">Login</NavLink>}
                              {/*{user && <NavLink to='/user/profile' className="nav-link">Profile</NavLink>}*/}


                            </Nav>
                            {user &&  <Dropdown className="profile-user" style={{paddingLeft:"20px"}}>
                              <Dropdown.Toggle variant="" className="p-0" size="small dropdown-basic-head" id="dropdown-basic-head">
                                {/*{user.name}*/}
                                <img src={userImage} height="30" style={{border:"none"}} className="rounded-circle-front" onError={this.reloadImage} width="auto" alt=""/>
                              </Dropdown.Toggle>

                              <Dropdown.Menu >
                                <Dropdown.Item as={Link} to="/user/profile" >My Account</Dropdown.Item>
                                {/*<Dropdown.Item as={Link} to="/user/profile?pw=true" >Change Password</Dropdown.Item>*/}
                                {/*<Dropdown.Item  ><NavLink to="/user/profile#1">Change Password</NavLink></Dropdown.Item>*/}
                                <Dropdown.Item onClick={this.handleLogout}  >Logout</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>}

                          </Navbar.Collapse>
                      </Navbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </section>
    );
  }
}

const FinalHead = (props)=>{
  const navigate = useNavigate();
  return <Head {...props} navigate={navigate} />;
}
// Wrap and export
const mapDispatchToProps =( dispatch)=> {
  return{
    dispatch
  }
}
export default connect(null, mapDispatchToProps)(FinalHead)
