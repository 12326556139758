import ToggleButton from 'react-toggle-button'
import axios from "axios";
const  StepThree = (props)=> {
  const  {stepOne, ingredients, oils, handleOilBatch,handleToggle,toggle} = props
    let oilWeight = stepOne.oilWeight;
    let measureUnit = (stepOne.measureUnit === "oz" ? 'oz':'g')
  if (stepOne.percentage){
      measureUnit = (stepOne.percentage && stepOne.percentage === "g" ? 'g':'oz')
  }

    let totalOilFat = 0;
    if (Object.keys(ingredients).length === 0){
        return false;
    }
    totalOilFat =  Object.values(ingredients).reduce((a, b) => parseFloat(a) + parseFloat(b));

    let lyeNeeded = 0;
    let waterQty = 0
    oils.map((oil) =>{
        if (ingredients[oil.id] && !isNaN(ingredients[oil.id])){

            let sap = parseFloat(oil.sap)

            if(stepOne.type === 'liquid'){
                sap = parseFloat(oil.koh)
            }


            let ingredient =  parseFloat(ingredients[oil.id]).toFixed(2);
            let oilCalc = (oilWeight ? parseFloat((ingredient/totalOilFat)*oilWeight) : parseFloat(ingredient));
            let tempLye = (oilCalc * sap);
            tempLye = tempLye - (tempLye * ((stepOne.superfattingLevel)/100))
            lyeNeeded = parseFloat(lyeNeeded+tempLye)

            let tempWaterQty = parseFloat(tempLye/0.3)
            tempWaterQty = (tempWaterQty-tempLye)
            waterQty = waterQty + tempWaterQty
        }
    })

    if  (!toggle && measureUnit==='g'){
        waterQty = parseFloat(waterQty*28.34952);
        lyeNeeded = parseFloat(lyeNeeded* 28.34952)
    }

    let totalOilCalc = (oilWeight ? parseFloat(oilWeight) : totalOilFat).toFixed(2)
    if (!toggle && (measureUnit==='g') ){
        totalOilCalc = (oilWeight ? parseFloat(oilWeight* 28.34952) : parseFloat(totalOilFat* 28.34952)).toFixed(2)

    }
    if (!toggle && (measureUnit==='oz')){
        totalOilCalc = (oilWeight ? parseFloat(oilWeight/ 28.34952) : parseFloat(totalOilFat/ 28.34952)).toFixed(2)
        lyeNeeded = parseFloat(lyeNeeded/ 28.34952)
        waterQty = parseFloat(waterQty/ 28.34952)
    }
    const oilsAr = []
    {oils.map(oil =>{
        if (ingredients[oil.id] && !isNaN(ingredients[oil.id])){
            let ingredient =  parseFloat(ingredients[oil.id]).toFixed(2);

            let oilCalc = (oilWeight ? parseFloat((ingredient/totalOilFat)*oilWeight).toFixed(2) : ingredient);
            if (!toggle && (measureUnit==='g') ){
                oilCalc = (oilWeight ? parseFloat(((ingredient/totalOilFat)*oilWeight) * 28.34952).toFixed(2) : parseFloat(ingredient * 28.34952).toFixed(2))
            }
            if (!toggle && (measureUnit==='oz')){
                oilCalc = (oilWeight ? parseFloat(((ingredient/totalOilFat)*oilWeight)/28.34952).toFixed(2) : parseFloat(ingredient/28.34952).toFixed(2))
            }
            let tempObj = {id:oil.id,name:oil.name,qty:oilCalc, percentage:((ingredient * 100)/totalOilFat).toFixed(2)}
            oilsAr.push(tempObj)
        }
    })}

    const handlePrint = ()=>{
        const lye = lyeNeeded.toFixed(2)
        const oz_liquid = waterQty.toFixed(2)
        const batchYield = (lyeNeeded+waterQty+parseFloat(totalOilCalc)).toFixed(2)
        const lyeAndLiquid = (lyeNeeded+waterQty).toFixed(2)
        let url = window.API_URL+'print-lye-result?lye='+lye
            +"&oz_liquid="+oz_liquid+"&oils="+JSON.stringify(oilsAr)
            +"&unit="+measureUnit+"&totalOil="+totalOilCalc
            +"&soapType="+stepOne.type+"&superfat="+stepOne.superfattingLevel
            +"&batch="+batchYield+"&lyeAndLiquid="+lyeAndLiquid;
        window.open(url, '_blank')
    }


    return (
        <div>
            <div className="col-md-12 " >
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h4>Result <span style={{float:"right",cursor:"pointer",fontSize:"20px"}} onClick={handlePrint}> <i className="fa fa-download text-danger"></i> Download PDF </span></h4>
                        <hr/>
                        <p style={{textAlign:"center"}}>
                            <span style={{fontWeight:"bold"}}>Soap Type:</span> {stepOne.type},
                            <span style={{fontWeight:"bold"}}> Measurement Unit:</span> {(stepOne.measureUnit === 'percentage'? 'Percentage':(measureUnit === 'oz'?'Ounces':'Gram'))},&nbsp;
                             <span style={{fontWeight:"bold"}}>Superfat:</span> {stepOne.superfattingLevel}%
                        </p>
                        <div id="lye-result">
                            <table className="table table-bordered"  >
                                <thead style={{background: "#ebebeb"}}>
                                <tr>
                                    <th>
                                        LYE & LIQUID
                                    </th>
                                    <th colSpan="2">
                                        AMOUNT
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Lye ({stepOne.type === 'solid' ? "Sodium":"Potassium" }  Hydroxide)</td>
                                    <td colSpan="2">{lyeNeeded.toFixed(2)} {measureUnit}</td>
                                </tr>
                                <tr>
                                    <td>Ounces of Liquid</td>
                                    <td colSpan="2">{waterQty.toFixed(2)} {measureUnit}</td>
                                </tr>
                                <tr>
                                    <th>Total</th>
                                    <th colSpan="2">{(lyeNeeded+waterQty).toFixed(2)} {measureUnit}</th>
                                </tr>
                                </tbody>

                                <thead style={{background: "#ebebeb"}}>
                                <tr>
                                    <th>
                                        OIL & FATS
                                    </th>
                                    <th>
                                        AMOUNT  <span style={{float:'right'}}>
                                    <ToggleButton
                                        inactiveLabel={measureUnit}
                                        activeLabel={measureUnit}
                                        value={toggle}
                                        onToggle={handleToggle}/>
                                </span>
                                    </th>
                                    <th>
                                        %
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {/*{oils.map(oil =>{*/}
                                {/*    if (ingredients[oil.id] && !isNaN(ingredients[oil.id])){*/}
                                {/*        let ingredient =  parseFloat(ingredients[oil.id]).toFixed(2);*/}

                                {/*        let oilCalc = (oilWeight ? parseFloat((ingredient/totalOilFat)*oilWeight).toFixed(2) : ingredient);*/}
                                {/*        if (!toggle && (measureUnit==='g') ){*/}
                                {/*            oilCalc = (oilWeight ? parseFloat(((ingredient/totalOilFat)*oilWeight) * 28.34952).toFixed(2) : parseFloat(ingredient * 28.34952).toFixed(2))*/}
                                {/*        }*/}
                                {/*        if (!toggle && (measureUnit==='oz')){*/}
                                {/*            oilCalc = (oilWeight ? parseFloat(((ingredient/totalOilFat)*oilWeight)/28.34952).toFixed(2) : parseFloat(ingredient/28.34952).toFixed(2))*/}
                                {/*        }*/}

                                {/*        return (*/}
                                {/*            <tr key={oil.id}>*/}
                                {/*                <td>*/}
                                {/*                    {oil.name}*/}
                                {/*                </td>*/}
                                {/*                <td>*/}
                                {/*                    { oilCalc} {measureUnit}*/}
                                {/*                </td>*/}
                                {/*                <td> {((ingredient * 100)/totalOilFat).toFixed(2)} %</td>*/}
                                {/*            </tr>*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*})}*/}
                                {oilsAr.map(oil=><tr key={oil.id}>
                                    <td>{oil.name}</td>
                                    <td>{oil.qty} {measureUnit}</td>
                                    <td>{oil.percentage} {measureUnit}</td>
                                </tr>)}
                                <tr key={totalOilCalc} style={{fontWeight:"bold"}}><td>Total</td><td> {totalOilCalc} {measureUnit}</td> <td>100.00%</td></tr>
                                </tbody>
                                <thead style={{background: "#ebebeb"}}>
                                <tr >
                                    <th>Totals</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        LYE & LIQUID
                                    </td>
                                    <td>{(lyeNeeded+waterQty).toFixed(2)} {measureUnit}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        OIL & FATS
                                    </td>
                                    <td><td> {totalOilCalc} {measureUnit}</td> </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>TOTAL BATCH YIELD</th>
                                    <th>{(lyeNeeded+waterQty+parseFloat(totalOilCalc)).toFixed(2)} {measureUnit}</th>
                                    <th></th>
                                </tr>
                                </tbody>
                                <tbody>

                                </tbody>
                            </table>
                        </div>

                        <div className="col-md-12">
                            <div  >
                                <br/>
                                <label htmlFor="" style={{fontWeight:"bold"}}>Resize Batch</label>
                                <input type="number" name="oilWeight" onChange={handleOilBatch} value={oilWeight}   placeholder={measureUnit} className="form-control" min="0"/>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default StepThree;

