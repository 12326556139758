import React, { useEffect, useState} from "react";
import avatarPlaceholder from '../../../../../assets/img/avatar-placeholder.png';
import profileusericon from '../../../../../assets/img/profile-user-icon-01.png';
import {Row, Col, Form, Modal, Button, Tabs,Tab} from "react-bootstrap";
import {useLocation} from "react-router";
import Bookmarks from "./includes/Bookmarks";
import MyNotes from "./includes/MyNotes";
import axios from "axios";
import ContinueLearning from "./includes/ContinueLearning";
import NotStarted from "./includes/NotStarted";
import EditProfileModal from "./includes/EditProfileModal";


const UserProfile = ()=>{
    let user = ''
    if(localStorage.user) {
        user = JSON.parse(localStorage.user)
    }
    let [avatar,setAvatar] = useState('');
    const {search} = useLocation()
    const [bookmarkVideos,setBookmarkVideos] = useState('')
    const [videoNotes,setVideoNotes] = useState('')
    const [userVideos,setUserVideos] = useState('')
    const [notStarted,setNotStarted] = useState('')
    const [loader,setLoader] = useState(false)
    const [progressVideos,setProgressVideos] = useState(false)
    const [changePw,setChangePw] = useState(false)



    useEffect(()=>{
        setAvatar(window.ASSET_URL+user?.avatar)
        // handleChangeTab(search)
        getBookmarks()
        getVideoNotes()
        getUserVideos()
        getNotStarted()
    },[search])

    const lessonsLeft = (courseId)=>{

        let totalVideos = userVideos && userVideos?.filter(video=>video.CourseID == courseId)
        let watchedVideos = videoNotes && videoNotes[courseId]?.filter(video=>video.current_time > 0)
        return (parseInt(totalVideos?.length)-parseInt(watchedVideos?.length));
    }



    const getNotStarted =()=> {
        axios.get(window.API_URL+'courses/not-started')
            .then(({data})=>{
                setNotStarted(data)
            })
    }

    const getUserVideos =()=> {
        axios.get(window.API_URL+'user-videos')
            .then(({data})=>{
                setUserVideos(data.videos)
                setProgressVideos(data.progressVideos)
                // setUserVideos(data)
            })
    }

    const getVideoNotes =()=> {
        setLoader(true)
            axios.get(window.API_URL+'video-notes/'+user?.id)
                .then(({data})=>{
                    setVideoNotes(data)
                    setLoader(false)
                })

        }

        const getBookmarks =()=> {
            axios.get(window.API_URL+'bookmarks/'+user?.id)
                .then(({data})=>{
                    setBookmarkVideos(data)
                })
        }



    const  reloadImage = (img) => {
        img.onerror = null
        setAvatar(profileusericon)
    }
    const [show, setShow] = useState(false);
    const [showOne, setShowOne] = useState(false);
    const handleClose = () => {
        setShow(false)
        setChangePw(false)
    };
    const handleShow = () => setShow(true);
    const handleShowOne = () => setShowOne(true);
    const handleCloseOne = () => setShowOne(false);


        return (
            <React.Fragment>
                <section className="profile-page">
                    <div className="profile-header">
                        <div className="container">

                            <Row className="profile-details">
                                <Col lg={6} md={6} sm={8}>
                                    <div className="profile-user">
                                        <div className="profile-icon">
                                            <img src={avatar} onError={reloadImage} className="rounded-circle-front" alt="icon"/>
                                        </div>
                                        <div className="profile-discription">
                                            <h2>{user?.name}</h2>
                                            <p><span><i className="fa fa-map-marker" aria-hidden="true"></i></span> <span>{user?.location}</span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={4}>
                                    <div className="edit-profile">
                                        <Button className="editprofile-btn resetbht"  onClick={handleShow}>
                                            <span><i className="fa fa-pencil" aria-hidden="true"></i></span><span>Edit Profile</span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>


                    <ContinueLearning lessonsLeft={lessonsLeft} videos={progressVideos} />
                    <Bookmarks lessonsLeft={lessonsLeft} bookmarkVideos={bookmarkVideos} />
                    <MyNotes lessonsLeft={lessonsLeft} loader={loader} videoNotes={videoNotes} />
                    <NotStarted courses={notStarted} />
                </section>

                <EditProfileModal
                    user={user}
                    handleShow={handleShow}
                    handleClose={handleClose}
                    show={show}
                    changePw={changePw}
                    setChangePw={setChangePw}
                    avatar={avatar}
                />

            </React.Fragment>
        );
}

export default UserProfile