import {Button, Col, Modal, Row, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Loader from "react-js-loader";
import profilenotimg1 from "../../../../../../assets/img/profile-icon-01.png";
import {Link} from "react-router-dom";

const MyNotes = ({videoNotes,loader})=>{

    const [notes,setNotes] = useState({})
    const [showNotes, setShowNotes] = useState(true);
    const [notesModal, setNotesModal] = useState(false);
    const [courseNotes, setCourseNotes] = useState('')



    useEffect(()=>{
        const tempNotes = {...videoNotes}
        setNotes(firstN(tempNotes,3))

    },[videoNotes])

    const handleDisplayNotesInModal = (id)=>{
        setCourseNotes(videoNotes[id])
        handleNotesModal()
    }

    const handleShowNotes = ()=>{
        let allNotes = {...videoNotes}
        if(showNotes){
            setNotes(allNotes)
        }else {
            setNotes(firstN(allNotes,3))
        }
        setShowNotes(!showNotes)

    }

    const handleNotesModal = ()=>{
        setNotesModal(!notesModal)
    }

    const firstN =(obj, n)=> {
        return Object.keys(obj) //get the keys out
            .sort() //this will ensure consistent ordering of what you will get back. If you want something in non-aphabetical order, you will need to supply a custom sorting function
            .slice(0, n) //get the first N
            .reduce(function(memo, current) { //generate a new object out of them
                memo[current] = obj[current]
                return memo;
            }, {})
    }
    let i=0;
    return(
        <div className="profile-course profile-course-mynote">
            <style>{style}</style>
        <div className="container">
            <div className="profile-course-heading">
                <h2>My Notes</h2>
                <p>Revisit your notes and key factors you may have noted down</p>
            </div>
            {<div className="profile-course-list profile-course-note">

                <Row className="justify-content-center">
                    {loader && <div style={{padding:"50px"}}>
                        <Loader type="spinner-cub" bgColor={"#c42625"} title={"Loading My Notes..."} color={'#c42625'} size={100} />
                    </div>}
                    {Object.keys(notes).length !== 0 && Object.keys(notes).map(key => notes[key][0]?.course?.course_title && <Col lg={4} md={4} sm={6} key={key} className="profile-course-column">
                        <div className="profile-course-detail">
                            <div className="profile-notes">
                                <div className="profile-notes-title">
                                    <h3>{notes[key][0]?.course?.course_title}</h3>
                                </div>
                                <div className="profile-notes-detail">
                                    <img loading="lazy" src={`${window.ASSET_URL}${notes[key][0]?.course?.img}`} alt="image"/>
                                    <Button className="view-note-btn resetbht" onClick={()=>handleDisplayNotesInModal(notes[key][0]?.course?.CourseID)}>View Notes</Button>
                                </div>
                            </div>
                        </div>
                    </Col>)}

                </Row>
                {notes && (
                    <div className="profile-cours-showall">
                    {(Object.keys(notes).length ===0 && !loader)? (
                        <div className="text-center">
                            <p>Notes are not Available</p>
                            <Link to="/Courses" className="btn  btn-outline-danger" >Start a Course</Link>
                        </div>
                    ):(
                        <Button className="showall-btn resetbht"
                             onClick={handleShowNotes}>{(showNotes ? " All Notes" : "Show Less")}</Button>)}
                </div>)}
            </div>}
        </div>
            <Modal className="view-note-modal"  show={notesModal} onHide={handleNotesModal}>
                <Modal.Header closeButton={true}></Modal.Header>
                <Modal.Body>
                    <div className="course-detail-sidebar">
                        <div className="course-profile-section">
                            <div className="profile-image text-center">
                                <img loading="lazy" style={{height:"150px"}} height="150px" src={window.ASSET_URL+courseNotes[0]?.course?.img} alt="image"/>
                            </div>
                            <div className="profile-detail">
                                <h3 className="text-center text-black">{courseNotes[0]?.course?.course_title}</h3>
                                {/*<p>How To's Sticks Incense</p>*/}
                            </div>
                        </div>
                        <div className="course-detail-tabs">
                            <div className="total-lessons">
                                {/*<p>10 Lessons</p>*/}
                            </div>
                            <Tabs
                                defaultActiveKey="Notes"
                                id="noanim-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="Notes" title="My Notes">
                                    <div className="all-notes">
                                        <label className="notes-show">Show all notes
                                            <input type="checkbox" disabled checked={true} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="lesson-list">
                                        {courseNotes && courseNotes.map(note=> {

                                            if(note.description){
                                                return(
                                                    <div className="lesson-list-items" key={note.id}>
                                                        <div className="lesson-heading">
                                                            <h3 style={{fontWeight: "bold"}}>{note.video.video_title}</h3>
                                                            {/*<h4>Currently Watching</h4>*/}
                                                        </div>
                                                        <div className="lesson-discriptions">
                                                            <dd style={{fontSize: "18px"}}>{note.description}</dd>
                                                        </div>
                                                    </div>
                                                )
                                            }else {
                                                i++
                                            }

                                        }) }

                                        { courseNotes && i === courseNotes.length && <div className="lesson-list-items text-center" style={{padding:"100px 10px"}} >
                                            {/*<div className="lesson-heading">*/}
                                                <h3 style={{fontWeight: "bold"}}>No Notes available</h3>
                                                {/*<h4>Currently Watching</h4>*/}
                                            {/*</div>*/}
                                            <div className="lesson-discriptions">
                                                <Link to={'/Courses/CoursesDetails/'+courseNotes[0].course_id} className="btn btn-outline-danger">Watch Videos</Link>
                                                {/*<dd style={{fontSize: "18px"}}>No Notes available</dd>*/}
                                            </div>
                                        </div>}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
    </div>
    )
}

export default MyNotes

const style = `
.view-note-modal .modal-header .btn-close {
    z-index: 9999;
    top: 10;
    right: 23px;
    position: absolute;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem -1.5rem -4.5rem auto;
    color: red!important;
}

.view-note-modal .modal-header {
    padding:0px;
}
`