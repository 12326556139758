import {Link} from "react-router-dom";


const PageNotFound = ()=>{

    const css = `
    
.mainbox {
  background-color: #fff;
  margin: auto;
  height: 600px;
  width: 600px;
  position: relative;
}

  .err {
    color: #c42625;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 20%;
    top: 8%;
  }

.far {
  position: absolute;
  font-size: 8.5rem;
  left: 42%;
  top: 15%;
  color: #c42625;
}

 .err2 {
    color: #c42625;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 68%;
    top: 8%;
  }

.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    color:#c42625;
    left: 16%;
    top: 45%;
    width: 75%;
  }

a {
  text-decoration: none;
  color: #c42625;
}

a:hover {
  text-decoration: underline;
}
    `

    return(<>
            <style>{css}</style>
            <div className="mainbox ">
                <div className="err">4</div>
                <i className="fa fa-question-circle far fa-spin mt-3"></i>
                <div className="err2">4</div>
                <div className="msg">Maybe this page moved? Got deleted? <p>Let's go <Link to="/" className="btn btn-danger"><i className="fa fa-home"></i> home</Link> and try from there.</p></div>
            </div>
    </>
    )
}

export default PageNotFound