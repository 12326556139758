import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Logo from '../../../assets/img/logo.png'


function NotFoundModal({show,handleClose}) {


    return (
        <div className="not-found-modal">
            <style>{style}</style>

            <Modal show={show} id="not-found-modal" onHide={handleClose} animation={false}>
                <Modal.Header id="header-n" style={{background:"#c41425"}} closeButton>
                    <h2 className="text-center text-white " id="h2-n">STAY TUNED</h2>
                </Modal.Header>
                <Modal.Footer id="footer-n">
                    <h3>
                        <p>We don't currently offer</p>
                        <p>what you're looking for, but</p>
                        <p>we are always adding new</p>
                        <p>content, so be sure to</p>
                        <p>check back later</p>
                    </h3>
                    <img src={Logo} alt="Image"/>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NotFoundModal

const style = `
#footer-n{
display:block!important;
text-align:center!important;
border-left: 4px solid!important;
border-bottom: 4px solid!important;
border-right: 4px solid!important;
font-weight:bold;
}
#header-n{
border: 4px solid!important;
}


#h2-n{
    display: contents!important;
}
#not-found-modal .modal-header .btn-close {
    border-radius: 25px!important;
    position: absolute!important;
    background-color: #c1c1c1!important;
    right: -5px!important;
    top: -5px!important;
    padding: 0.5rem 0.5rem!important;
    opacity: 1!important;
    border: 3px solid!important;
}
#not-found-modal img {
       height: 137px;
    border-top: solid black;
    padding-top: 23px;
}
#not-found-modal h3 {
    font-weight:bold;
}
#not-found-modal p {
    margin-top: 0;
    margin-bottom: 0px;
    font-size:40px;
}
`