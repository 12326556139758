import ImgHeader from '../../../assets/img/about-header.jpg'
import ImgLaptop from '../../../assets/img/about-laptop.png'
import {Link} from "react-router-dom";
function AboutUs (){




    return(
        <div className="container contact-us">
            <style>{css}</style>
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="banner">
                        {/*<div className="banner-text">WHY FRAGRANCE U?</div>*/}
                    </div>
                    <p>
                        Fragrance University
                        was born from AFI's
                        collective desire to
                        educate and uplift
                        fragrance fans and
                        businesses alike.
                        Whether you're a mom and pop shop
                        working out of your home or a million
                        dollar operation, Fragrance U is for YOU.
                        What began as our founder's dream of
                        educating others on how to build a
                        business based on fragrance became a
                        full fledged collection of courses on
                        everything from candles, to soaps, and
                        everything in between.
                    </p>
                    <div >
                        <p>
                            <img className="p-img" src={ImgLaptop} />
                            Anyone can take
                            advantage of Fragrance U's expansive
                            course offerings which will never be
                            locked behind a paywall; our goal is to
                            help you, no matter what stage of
                            research, development, or production
                            you're at.
                            If you want to learn how to create a
                            business based on fragrance or just want
                            to turn your hobby into cashflow, dive
                            into our content and see for yourself just
                            how easy it is!
                            </p>

                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <Link to="/login" className="large-button">LET'S GET STARTED</Link>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;

const css =  `

.large-button {
text-decoration:none;
    background-color: #c42625;
    border: 4px solid black;
    border-radius: 21px;
    color: white;
    font-size: 32px;
    font-weight: bold;
    padding: 5px 20px;
}
.large-button:hover {
    color: #f4f4f4;
}

.p-img {
 float:right;
 width: 330px;
 margin-top: -20px;
 border-radius: 10px;
}

.banner {
background-image: url(`+ImgHeader+`);
background-repeat: no-repeat;
background-size: cover;
height: 240px;
position: relative;
border: 7px solid #c42625;
border-radius: 12px;
}
.banner-text {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
text-align: center;
color: #fff;
border: 5px solid #fff;
padding: 20px;
font-size: 40px;
font-weight: bold;
}


@media (min-width: 768px) and (max-width: 1024px) {

.large-button {
    font-size: 18px;
}

.p-img {
    width: 180px;
    margin-top: -10px;
}
.banner-text {
    font-size: 25px;
    
    }
    
    .banner {
    height:140px;
    
    }

}


@media (max-width: 767px){
.banner-text {
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
}


.p-img {
    width: 328px;
    height: 175px;
    float: none;
    display: block;
    margin-top: -10px;
    border-radius: 6px;
    text-align: center;
}
.banner {
    height: 128px;
    border: 5px solid #c42625;
    border-radius: 6px;
}
.large-button {
    font-size: 18px;
}

}

`