import {Button, Col, Row} from "react-bootstrap";
import React from "react";
import infoIconOne from "../../../../../assets/img/info-01.png";
import infoIconTwo from "../../../../../assets/img/info-02.png";
import infoIconThree from "../../../../../assets/img/info-03.png";
import infoIconFour from "../../../../../assets/img/info-04.png";
import infoIconSix from "../../../../../assets/img/info-06.png";

function SocialSection (){
    return(<>
            <section className="fragrance-available">
                <div className="container">
                    <Row>
                        <Col lg={8} md={8} sm={12}>
                            <div className="fragrance-available-title">
                                <h2>Over 4,000 Fragrances Available to ship in under 3 days!</h2>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <div className="fragrance-available-button">
                                <Button className="search-now-btn resetbht">
                                    <a href="https://www.afi-usa.com/shop/index.cfm?" target="_blank" className="text-decoration-none text-white">Search Now</a> </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        <section className="fragrance-follow">
            <div className="container">
                <div className="fragrance-follow-detail">
                    <div className="fragrance-follow-title">
                        <h2>Follow Us:</h2>
                    </div>
                    <ul>
                        <li><a target="_blank" href="https://web.facebook.com/afiusa"><img src={infoIconOne} alt="icon"/></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/aromaticfragrancesintl/"><img src={infoIconTwo} alt="icon"/></a></li>
                        <li><a target="_blank" href="https://www.youtube.com/channel/UCyKbpNByp_0AHl4R5NIdaVA"><img src={infoIconThree} alt="icon"/></a></li>
                        <li><a target="_blank" href="https://twitter.com/Afragrance"><img src={infoIconFour} alt="icon"/></a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/aromatic-fragrances-international"><img src={infoIconSix} alt="icon"/></a></li>
                        {/*<li><a href="#"><img src={infoIconFive} alt="icon"/></a></li>*/}
                        {/*<li><a href="#"><img src={infoIconSeven} alt="icon"/></a></li>*/}
                    </ul>
                </div>
            </div>
        </section></>

    )
}

export default SocialSection