import React, {useEffect, useState} from 'react';
import Joi from "joi-browser";
import {Toast,toastCss} from "../../Widgets/alert";
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { LoginAction } from '../../../redux/actions/AuthActions';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import avatar from '../../../assets/img/avatar-placeholder.png'
export default function LoginTest() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loginUrl, setLoginUrl] = useState(null);
    const [fbUrl, setFbUrl] = useState(null);
    const [linkedUrl, setLinkedUrl] = useState(null);
    const [fields, setFields] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors]=useState([]);

    const schema = {
        email:Joi.string().required().email().label('Email'),
        password:Joi.string().required().min(6).label('Password'),
    }

    //const authResponse = useSelector(state=>state.userAuth.authResponse);

    useEffect(()=>{
        getGoogleLogin()
        getFacebookLogin()
        getLinkedinLogin()
    },[])

    const getGoogleLogin = ()=>{
        fetch(window.API_URL+'auth?provider=google', {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                console.log(response)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => setLoginUrl( data.url ))
            .catch((error) => console.error(error));
    }
  const getFacebookLogin = ()=>{
        fetch(window.API_URL+'auth?provider=facebook', {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                console.log(response)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => setFbUrl( data.url ))
            .catch((error) => console.error(error));
    }
  const getLinkedinLogin = ()=>{
        fetch(window.API_URL+'auth?provider=linkedin', {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                console.log(response)
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => setLinkedUrl( data.url ))
            .catch((error) => console.error(error));
    }

    const UserLogin = (e) => {
        e.preventDefault();
        let errors = validate(fields);
        if (!_.isEmpty(errors)){
            Toast('error','Please Fill All Inputs');
            setErrors(errors)
            return false;
    };
    dispatch(LoginAction(fields, navigate));
}

   
    const validate = (form)=>{
        let result = Joi.validate(form,schema,{abortEarly:false})
        if (!result.error)return null;

        const errors ={};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }

        return errors;
    }

    const handleFieldChange = ({currentTarget:input}) => {
        const form = {...fields}

        form[input.name] = input.value
        setFields(form)
        setErrors(validate(form) || {})

    }
    return (
        <div className="container login-page my-login-page">
            <div className="row justify-content-center">
                <style>
                    {toastCss()}
                    {style}
                </style>
                <div className="col-lg-6 col-md-8">
                    <div className="card" >
                        <div className="card-header " style={{ background: "#c42625" }} ><h4 style={{ color: "white" }}>Login</h4></div>
                        <div className="card-body">
                            <div className='card-body-img'>
                                <img src={avatar} alt="" />
                            </div>
                            <div className="row">
                                <Form onSubmit={UserLogin}>
                                    <Form.Group className="mb-2">
                                        <Form.Label htmlFor="email" style={{marginBottom:"0px"}}>Email address <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" name="email" margin="normal"
                                            variant="outlined"
                                            label="email"
                                            id="email"
                                            value={fields.email}
                                            onChange={handleFieldChange} />
                                            {errors['email']  && <small className="text-danger text-left"> {errors['email']}</small>}
                                        {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text> */}
                                    </Form.Group>

                                    <Form.Group className="mb-2" htmlFor="password">
                                        <Form.Label  htmlFor="password" style={{width:"100%",marginBottom:"0px"}}>Password <span className="text-danger">*</span>
                                        {/*    <small className="pull-right">*/}
                                        {/*        <Link to="/ForgotPassword">*/}
                                        {/*    Forgot Password?*/}
                                        {/*</Link>*/}
                                        {/*</small>*/}
                                        </Form.Label>
                                        <Form.Control type="password" placeholder="Password" name="password" margin="normal"
                                            variant="outlined"
                                            id="password"
                                            value={fields.password}
                                            onChange={handleFieldChange} />
                                            {errors['password']  && <small className="text-danger text-left"> {errors['password']}</small>}
                                    </Form.Group>
                                    {/* <Form.Group className="mb-2" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Check me out" />
                                    </Form.Group> */}
                                    <div className="login-button">
                                        <Button variant="btn btn-outline-danger form-control" type="submit">
                                            Login
                                        </Button>
                                    </div>
                                    <div className="or-container">
                                       <div className="line-separator"></div>
                                       <div className="or-label">or login with</div>
                                       <div className="line-separator"></div>
                                    </div>
                                    <div className="row">
                                       <div className="col-md-12 text-center">
                                           <div className="btn-toolbar" role="toolbar"
                                                 aria-label="Toolbar with button groups">
                                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                                    <a href={loginUrl} type="button" className="btn btn-outline-light rounded-circle m-2">
                                                        <i className="fa fa-google text-danger"></i>
                                                    </a>
                                                    <a href={fbUrl} type="button" className="btn btn-outline-light rounded-circle m-2">
                                                        <i className="fa fa-facebook-square text-primary"></i>
                                                    </a>
                                                    <a href={linkedUrl} type="button" className="btn btn-outline-light rounded-circle m-2">
                                                        <i className="fa fa-linkedin  text-primary"></i>
                                                    </a>
                                                </div>
                                            </div>
                                
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center">
                                        <small><label htmlFor=""><Link to="/ForgotPassword" style={{textDecoration:"none"}}>Forgot Password?</Link></label></small>
                                    </div>

                                  
                                    
                                </Form>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className=" text-center">
                                Don't have an account? <Link to="/SignUp" style={{textDecoration:"none"}}>Sign up</Link>

                            </div>
                        </div>

                        {/*<div className="card-footer">*/}

                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </div>

    )
}

const style =`
.btn-toolbar{
display:block;
}
.btn-google {
        color: #545454;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 1px #ddd;
}

.or-container {
    align-items: center;
    color: #ccc;
    display: flex;
    margin: 8px 0;
}
.or-label {
    flex-grow: 1;
    margin: 0 15px;
    text-align: center;
}

.line-separator {
    background-color: #ccc;
    flex-grow: 5;
    height: 1px;
}
`
