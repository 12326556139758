import {Modal,Button} from "react-bootstrap";
import React, {useState} from "react";
import Logo from '../../../../assets/img/logo.png'
import {Link} from "react-router-dom";

const LoginModal = ({handleModal,showModal,closeBtn=true})=>{

    return (
        <>

            <Modal
                show={showModal}
                onHide={handleModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton={closeBtn}  className="text-white " style={{background:"#c42625",height:"50px"}}>
                    <Modal.Title>Login Required</Modal.Title>
                </Modal.Header>
                <Modal.Footer style={{padding:"30px",marginBottom:"30px"}}>
                    <div className="row text-center login-form-holder" >
                        <div className="col-md-12">
                            <img src={Logo} alt="logo" style={{height:"120px",width:"auto"}} className="img-responsive" />
                        </div>
                        <div className="col-md-12">
                            <div className="login-form">
                                <Link to="/login" className="form-control btn btn-outline-danger" onClick={handleModal}>
                                    Login
                                </Link>
                                <div className="or-text">OR</div>
                                <Link to="/SIgnUp" className="form-control btn btn-outline-dark">SignUp</Link>
                            </div>
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LoginModal