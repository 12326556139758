import React, { useState } from 'react';
import Joi from "joi-browser";
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner'
import { SwalBefore, toastCss } from "../../../Widgets/alert";
import _ from 'lodash';
// import { useDispatch, useSelector } from 'react-redux';
// import { LoginAction } from '../../../redux/actions/AuthActions';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { createBrowserHistory } from "history";
import { Form, Button } from "react-bootstrap";
export default function ForgotPassword() {

    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const [email,Setemail] = useState('');
    const [errors, setErrors]=useState([]);
    const [schema, setSchema] = useState({
        email:Joi.string().required().email().label('Email'),
      
    })


    const handleFieldChange = e => {

        Setemail(e.target.value)
        if(!validateEmail(e.target.value)){
            setErrors({email:"Email Adress is Not Valid"})
        }else {
            setErrors('')
        }

        if(!e.target.value){
            setErrors({email:"Email is required"})
        }

        // validate(email);
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    //const authResponse = useSelector(state=>state.userAuth.authResponse);

    const sentEmailResetPas =async (e) => {
       
        e.preventDefault();
        if(!email){
            setErrors({email:"Email is required"})
            return
        }
        if(errors)return;

   // if(email==''||email ==null)
   // {
   //  Swal.fire({
   //      text:'Please provide email',
   //      icon:"error"
   //    })
   // }
  
    
    let url=`${window.API_URL}sent_reset_email`;
        const formData = new FormData()
        let linkk = `${window.RESET_URL}`+"ResetPassword";
        formData.append('email', email)
        formData.append('link', linkk)
        console.log(formData)
        SwalBefore("Sending Email") 
        await axios.post(url, formData).then(({data})=>{
          
            if(data.status==200)
            {
                Swal.fire({
                    icon:"success",
                    text:data.message
                  })
            }
            else
            {
                Swal.fire({
                    text:data.message,
                    icon:"error"
                  })
            }
        
            //   navigate(`/courses/videos/${id}`)
            }).catch(({response})=>{
              if(response.data.status==500){
                // setValidationError(response.data.errors)
                Swal.fire({
                    text:response.data.message,
                    icon:"error"
                  })
              }
            })
        }


    const validate = (form)=>{
        let result = Joi.validate(form,schema,{abortEarly:false})
        if (!result.error)return null;

        const errors ={};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }

        return errors;
    }


    return (
        <div className="container login-page">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                    <div className="card" >
                        <div className="card-header " style={{ background: "#c42625" }} ><h4 style={{ color: "white" }}>Reset Password</h4></div>
                        <div className="card-body">
                            <div className="row">
                                <Form onSubmit={sentEmailResetPas}>
                                    <Form.Group className="mb-2" id="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" name="email" margin="normal"
                                            variant="outlined"
                                            label="email"
                                            id="email" 
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            value={email}
                                            onChange={handleFieldChange}
                                        />
                                            {errors['email']  && <small className="text-danger text-left"> {errors['email']}</small>}
                                    </Form.Group>
                                    <div className="login-button">
                                        <Button variant="outline-danger form-control" type="submit">
                                            Get Link
                                        </Button>
                                    </div>                     
                                </Form>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
