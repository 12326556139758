import {Modal} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import Plyr from "plyr-react";
import 'plyr-react/plyr.css'
import YouTube from "react-youtube";
import videoLoader from "../../../../../assets/img/video-loader.gif";
import "./VideoModal.css";

const VideoModal = ({handleModal,showModal,closeBtn=true,data,heading,currentVideo,handleYoutubeVideoStateChange})=>{

    const  [video,setVideo] = useState('')
    const YT = useRef(null)
    const [youtubeOpts,setYoutubeOpts] = useState({
        host: "https://www.youtube-nocookie.com",
        height: '500',
        width: '100%',
        playerVars: {
            autoplay: 1,
            showinfo:0,
            rel:0,
            modestbranding:1,
            autohide:1,
        },
    })



    useEffect(()=>{
        setVideo(currentVideo)
    },[currentVideo])

    return (
        <>
            <Modal
                show={showModal}
                onHide={handleModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <style>{style}</style>
                <Modal.Header closeButton={closeBtn}  className="text-white " style={{background:"#c42625",}}>
                    {video?.video_title}
                </Modal.Header>
                <Modal.Footer className="p-0 bg-black" >
                    <div className="row text-center login-form-holder p-0 m-0" >
                        <div className="col-md-12 p-0">
                            {/*<Plyr autoPlay={true} source={{*/}
                            {/*    type: "video", sources: [{*/}
                            {/*        src: window.ASSET_URL + 'videos/fu-intro.mp4',*/}
                            {/*        type: 'video/mp4',*/}
                            {/*        size: 720,*/}
                            {/*    }]*/}
                            {/*}}/>*/}
                            {video && (video?.embedlink === null?(
                                <div><Plyr autoPlay={true} source={{
                                    type: "video", sources: [{
                                        src: window.ASSET_URL + video?.video,
                                        type: 'video/mp4',
                                        size: 720,
                                    }]
                                }}/></div>
                            ):( <YouTube ref={YT} style={{width: "100%", height: "500"}} videoId={video?.embedlink}
                                                                             opts={youtubeOpts} onStateChange={handleYoutubeVideoStateChange} />
                            ))}
                            {!video &&
                                <div style={{width: "100%", height: "500px", background: "black", textAlign: "center"}}>
                                    <img style={{marginTop: "190px"}} width="auto" height="100" src={videoLoader}/>
                                </div>}

                            {/*<iframe width="100%" height="500" src={`https://www.youtube.com/embed/${video?.embedlink}?rel=0&modestbranding=1&autohide=1&showinfo=0&autoplay=1`}*/}
                            {/*        title="YouTube video player" frameBorder="0"*/}
                            {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                            {/*        allowFullScreen="allowfullscreen">*/}
                            {/*</iframe>*/}
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default VideoModal

const style =`
.modal-header{
border: 0px;
}

.modal-footer{
border: 0px;
}
`