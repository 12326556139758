import React from 'react';
import "./Fragrance.css";
import {Row, Col, Form, Modal, Button, Tabs,Tab} from "react-bootstrap";
import fragrnaceInfo1mg from '../../../../assets/img/fragrnace-inner-img1.png';

const FragrenceIndustry = () => {
  return (
    <div>
        <section className="fragrance-composition-things">
            <div className='container'>
                <div className="composition-things-title">
                    <h1>FRAGRANCE INDUSTRY<br/> STATISTICS</h1>
                </div>
                <div className='hr-line'></div>
                <Row className='composition-row'>
                    <Col lg={8} md={7} sm={12}>
                        <div className="composition-things-details">
                            <p>
                            Cultures across the world have used various botanicals, herbs, essential oils 
                            and other materials to fragrance them and their surroundings for centuries. 
                            The desire to smell good is a universal goal that most everyone can relate with 
                            and the memories we associate with fragrances further our connection with scent. 
                            Throughout hundreds of years of research and experimentation, the modern world of 
                            fragrance was born in the 20th century with the discovery of countless synthetic 
                            raw materials that could be used to add complexity, depth, and entirely new aromas 
                            to the existing library of scent. 
                            </p>
                            <p>
                            Valued at approximately $3.2 billion dollars, the fragrance industry spans the globe 
                            with hundreds of manufacturers who offer unique selections of fragrance accords for 
                            anyone from smalltown candle makers to million dollar perfume houses and everyone in 
                            between! Industry growth is driven by the rising interest in cosmetics and personal 
                            care, as well as a desire to have a clean and fragrant personal space. Leaders include 
                            Givaudan, Firminch SA, Symrise AG, and International Flavors & Fragra.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} md={5} sm={12}>
                        <div className="composition-rightside">
                            <div className='composition-img'>
                                <img src={fragrnaceInfo1mg} />
                            </div>
                            <div className='composition-btn'>
                                <Button type='button' className='resetbht my-composit-btn'>
                                    <a target="_blank" className="text-white text-decoration-none" href={window.API_URL+'download-pdf/industry-statistics'}>DOWNLOAD</a>
                                </Button>
                            </div>
                            <div className="composition-txt">
                                <h4>“The desire to smell<br/> good is a universal goal<br/> that most everyone<br/>
                                    can relate with and the memories we associate with fragrances 
                                    further our connection with scent.”</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    </div>
  )
}

export default FragrenceIndustry