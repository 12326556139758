

function AbsoluteComponent() {

	return (
		<div>
		<div>	
		</div>		
		<div>
			<div className="">
			<div className="container ">
					<div className="row ">
						<div className="col-md-12 ">
							<h2 className="centerText">Absolutes</h2>
							<ul>
							<hr className="hr"/>
								<li className="centerText"> Your Absolutes</li>								
							</ul>						
						</div>				
					
					</div>
				</div>			
				<br/>
				<br/>
				<br/>					
			</div>
		</div>
		</div>
	);
}
export default AbsoluteComponent;