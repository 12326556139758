import './App.css';
import React, {useEffect, useState} from 'react';
import { lazy, Suspense } from 'react';
import Head from './components/Dashboard/Header/Head';
import { LogoutAction } from '../src/redux/actions/AuthActions';
import { useDispatch} from 'react-redux';
import { useNavigate } from "react-router-dom";
import UserRoutes from "./routes/userRoutes";
import IdleTimer from './IdleTimer';
import Logo from './assets/img/logo.png'
import axios from "axios";
import {SwalAfter} from "./components/Widgets/alert";
import AxiosInterceptorsSetup from "./helpers/AxiosInterceptorsSetup";
const Footer = lazy(() => import("./components/Dashboard/Footer/Footer"));

function App() {

    const AdminDashboard =  (window.role !== 'guest') && lazy(() => import("./components/admin/Dashboard.js"));

    const dispatch = useDispatch();

    const [isTimeout, setIsTimeout] = useState(false);

    
      useEffect(()=>{
       handleLogout()
      }, []);




      const handleLogout = ()=>{
        const timer = new IdleTimer({
          timeout: 10,
          onTimeout:() => {
            setIsTimeout(true);
            if(localStorage.getItem('user-token')) {
              dispatch(LogoutAction(''));
            };
          },
          onExpired: () =>{
            setIsTimeout(true);
          }
        });

        return () => {
          timer.cleanUp();
        };
      }
      
    
  return (
    <div>
      {<AxiosInterceptorNavigate />}
        {window.role !== 'user' &&
        <Suspense fallback={<div className="text-center "><img height="200px" className="align-middle"  src={Logo} alt=""/></div>}>
          <AdminDashboard/>
        </Suspense>
        }


        {(window.role === 'user') &&   <Suspense fallback={<div className="text-center "><img height="200px" className="align-middle"  src={Logo} alt=""/></div>}>
            <Head/>
            <div style={{marginBottom:"20px"}}>
                <UserRoutes/>
            </div>
            <Footer/>
        </Suspense>}
    </div>
  );
}
function AxiosInterceptorNavigate() {
  let navigate = useNavigate();
  AxiosInterceptorsSetup(navigate);
  return <></>;
}

export default App;

