import React, {useEffect, useRef, useState} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import {useNavigate, useSearchParams} from 'react-router-dom'
import LoginModal from "../../../Dashboard/pages/common/LoginModal";
import moment from "moment";
import EmailVerifyModal from "../../../Dashboard/pages/common/EmailVerifyModal";
import ContentLoader,{Code} from "react-content-loader";
import VideoModal from "./VideoModal";
import Plyr from "plyr-react";
import NotFoundModal from "../../../Dashboard/Mainbody/NotFoundModal";


function HomeCourseComponent() {
	
	const navigate = useNavigate();
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [show, setShow] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [notFound,setNotFound] = useState('')
	const [courses, setCourses] = useState([])
	const [pagination, setPagination] = useState([]);
	const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState('');
	let [showModal,setShowModal] = useState(false)
	let [showVideoModal,setShowVideoModal] = useState(false)
	const [bannerVideoUrl,setBannerVideoUrl] = useState('')
	const [videoPlay,setVideoPlay] = useState(false)
	const controller = new AbortController();
	const config = {
		signal: controller.signal
	}
	useEffect( () => () => controller.abort(), [] );//unmount



	const handleModal = ()=>{
		setShowModal(!showModal)
	}
	const handleVideoModal = ()=>{
		setShowVideoModal(!showVideoModal)
	}
	const usr= JSON.parse(localStorage.getItem('user'));

	const fetchCourses = async () => {
		let url;
	   if(query==='' ||query===null ){
		   url=`${window.API_URL}get_courses?page=`+pagination.current_page;
			}
			else
			{ 
			url =  window.API_URL+'get_courses?search='+query+'&page='+pagination.current_page;
			}
	 
	  setLoading(false);
	   await axios.get(url,config).then(({data})=>{
		   setCourses(data.courses)
		   let Pagination = data.courses;
		   setPagination(Pagination)
		   setLoading(true);
	   }).catch((e) => {
	   });
   }

	const handleReadMore= (e)=>{
		e.target.parentElement.style.display = 'none'
		e.target.parentElement.nextSibling.style.display = 'block'
	}

	const handleReadLess= (e)=>{
		e.target.parentElement.style.display = 'none'
		e.target.parentElement.previousSibling.style.display = 'block'
	}


	const startCourse =  (id)=>
	{    

		if(usr)
		{
			let validDate = moment(usr.email_verified_at, moment.ISO_8601, true).isValid()

			if (!validDate){
				handleModal()
				return false;
			}
			navigate(`CoursesDetails/${id}`);
		}
		else {
			handleModal()
		}

	}
	
	useEffect(() => {
		setBannerVideoUrl({
			type: "video", sources: [{
				src: window.ASSET_URL + 'videos/fu-intro.mp4',
				type: 'video/mp4',
				size: 720,
			}]
		})
		setQuery('');
		pagination.current_page=1
        fetchCourses()
		setVideoPlay(true)

		if(searchParams.get('redirect')){
			handleShow()
		}

    }, [searchParams])
	const css = `
	
	.intor-thumbnail{
	border: 8px solid #c42625;
	
	}
	.plyr--html5{
	border: 5px solid #c42625;
	}
	`


	return (
		<div>
			<section className="course-section " style={{paddingTop:"20px!important"}}>
				<NotFoundModal
					show={show}
					handleShow={handleShow}
					handleClose={handleClose}/>
				<style>{css}</style>
				{ !usr && <LoginModal handleModal={handleModal} showModal={showModal}/>}
				{ usr && <EmailVerifyModal handleModal={handleModal} showModal={showModal}/>}
				<VideoModal handleModal={handleVideoModal} showModal={showVideoModal}  />
				<div className="container">
					<div className="row">
						<div className="col-md-4 col-sm-3 "></div>
						<div className="col-md-4 col-sm-6 text-center mb-2">
							<div className="course-main-video" style={{width:"100%",border:"6px solid red!important"}} >
								{/*<i className="fa fa-youtube-play play-btn" role="button" onClick={handleVideoModal} ></i>*/}
								{/*{videoPlay && <Plyr*/}
								{/*	autoPlay={videoPlay}*/}
								{/*	style={{border:"6px solid red!important"}}*/}
								{/*	ref={reff}*/}
								{/*	source={bannerVideoUrl}/>}*/}
								<iframe width="100%" src="https://www.youtube-nocookie.com/embed/I1boFlrambw?autoplay=1&showinfo=0&rel=0&modestbranding=1&autohide=1"
								        title="YouTube video player" frameBorder="0"
								        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								        allowFullScreen="allowfullscreen">
								</iframe>
								{/*<img width="100%" className="intor-thumbnail" 	height="275" src={window.ASSET_URL+'img/course-img/intro-img.png'} alt=""/>*/}
							</div>
						</div>
					</div>
				</div>
				<div className="container">

					<div className="course-details">
						<Row className="course-row">
							{ !loading && Array.from(Array(10), (e, i) => {
								return (
									<Col className="course-column" lg={4} md={6} sm={6} key={i}>
										<div className="" style={{border: "3px solid #ddd!important", padding: "10px"}}>
											<ContentLoader viewBox="0 0 380 70">
												<rect x="150" y="0"  width="100" height="20"/>
												<rect x="0" y="30"  width="400" height="10"/>
												<rect x="0" y="50"  width="400" height="10"/>
											</ContentLoader>
											<ContentLoader viewBox="0 0 380 70">
												<rect x="0" y="0"   width="330" height="10"/>
												<rect x="0" y="20" rx="5" ry="5" width="200" height="35"/>
												<circle cx="350" cy="40" r="30"/>
											</ContentLoader>
										</div>
									</Col>)
							})}
							{
								loading && (
									courses.data.map((row, key) => (

										<Col className="course-column" lg={4} md={6} sm={6} key={key}>
											<div className="course-boxes">
												<div className="course-boxes-title start-course-title">
													{/* <h2>Fragrance U Intro</h2> */}
													<h2>{row.course_title}</h2>
												</div>

												<div className="course-boxes-content start-course-text">

													<p>
														{row.description.length > 124 && row.description.substring(0, 125) + '...'}
														{row.description.length > 124 &&
														<u className=" text-success" style={{fontSize: "20px"}}
														   role="button" onClick={handleReadMore}>read more</u>}
														{row.description.length < 125 && row.description}
													</p>
													<p style={{display: "none"}}>
														{row.description} &nbsp;&nbsp;<u className=" text-danger"
																						 style={{fontSize: "20px"}}
																						 role="button"
																						 onClick={handleReadLess}>read
														less</u>
													</p>
												</div>
												<div className="course-boxes-button start-course-btn">
													<div className="start-button">
														<button onClick={() => startCourse(row.CourseID)}
																className="start-button-text">Start Course
														</button>
														{/* <Link to={`/CoursesDetails/${row.CourseID}`} className="start-button-text">
                                        Start Course
                                       </Link> */}
														{/* <a href={`/Coursescomponent/${row.CourseID}`} className="start-button-text">Start Course</a> */}
													</div>
													<div className="course-box-icon">
														{/* <img src="./course-icon1.png" alt="icon"/> */}
														<img src={`${window.ASSET_URL}${row.img}`} loading="lazy" alt="icon"/>
													</div>
												</div>
											</div>
										</Col>
									))
								)
							}
						</Row>
					</div>
				</div>
				
			</section>
		</div>
	)
};

export default HomeCourseComponent;