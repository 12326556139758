import {Link} from "react-router-dom";
import TableLoader from "../../../../Widgets/TableLoader";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import BackArrow from "../../../../../assets/img/arrow-back.gif";


const FlavorIndexDetail = ({categoryDetails,catId})=>{
    const params = useParams();
    const url = '/Categories/'+params.Name+'/'+params.id+'/RawMaterialsDetails/'
    let [loader,setLoader] = useState(false)
    setTimeout(()=>{
        setLoader(true)
    },400)
    return(
        <div className="row">

            <div className="col-md-2"></div>

            <div className="col-md-8">
                <table className="table ">
                    <tbody>
                    {!categoryDetails && <TableLoader trNumber={10} tdNumber={3} />}
                    { categoryDetails && categoryDetails.map(
                        detail => {
                            if(!detail.heading){
                                return(
                                    <tr key={detail.id} style={{background:"rgb(192, 192, 192)",color:"black"}}>
                                        <td width="2%"></td>
                                        <td></td>
                                        <td><h3 >{detail.flavor_section}</h3></td>
                                    </tr>
                                )
                            }else {
                                return(
                                    <tr key={detail.id}>
                                        <td width="2%"><h4><label htmlFor="">{detail.fl_fr}</label></h4></td>
                                        <td style={{textAlign:"right",paddingRight:"0px"}}><h3>{detail.pre_heading}</h3></td>
                                        <td style={{paddingLeft:"0px"}}>
                                            <Link
                                                to={url+detail.id+'?index=true'}
                                                state={{ maintabid: `${params.id}`, ID: `${detail.id}`, rawMaterialName:`${detail.heading}` }}
                                                style={{textDecoration:"none"}}>
                                                <h3>{detail.heading}</h3>
                                            </Link>
                                            {detail.odor && <div>Odor: {detail.odor}</div>}
                                            {detail.flavor && <div>Flavor: {detail.flavor}</div>}
                                        </td>
                                    </tr>
                                )
                            }
                        }
                    )}
                    </tbody>
                </table>
            </div>
            <div className="col-md-2" style={{padding:"0px 40px"}}>
                <Link to={'/Categories/Flavor_Index/'+catId} className="btn btn-dark pull-right bg-white form-control  mb-1"
                      style={{backgroundImage:`url(${BackArrow})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'left',color:"black"}}>
                    <span style={{paddingLeft:"20px"}}>Back</span>
                </Link>
                {/*<Link to={'/Categories/Flavor_Index/'+catId} className="btn btn-dark pull-right"><i className="fa fa-backward"></i> Back</Link>*/}
            </div>
        </div>

)
}


export default FlavorIndexDetail