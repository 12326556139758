
export const _token = localStorage.getItem('user-token') // user token
const LoggedUser = ()=>{

    if (_token) {
       return  JSON.parse(localStorage.getItem('user'))
    }

    return null;
}


export const currentUser = LoggedUser();//current User
const LoggedUserRole = ()=>{
    if (currentUser) {
    }
    return null;
}

const userVerification = ()=>{
    if (currentUser && currentUser.email_verified_at) {
       return  true
    }
    else {
        return false;
    }

}

export const userRol = LoggedUserRole();// current user role
export const isVerified = userVerification();// current user verified or not

export const isLogged = (_token ? true:false);// current user verified or not

